import React, { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { ArrowBackButton } from "../utils/AllFunction";
import { UPLOAD_PRESCRIPTION__API } from "../Constants";
import {
  ArrowBack,
  PrescriptionImg,
  appHeartIcon,
  appPrescriptionImg,
} from "../images";
import { AppContext } from "../ContextApi";
import SuccessFull from "../Animation/SuccessFull";
import { Button, Modal } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import useQuery from "../CustomHooks/useQuery";
import PaymentLoader from "../Animation/PaymentLoader";

const UploadPrescription = () => {
  const [modalShow, setModalShow] = useState(false);
  const { count } = useContext(AppContext);
  const token = localStorage.getItem("ACCESS_TOKEN");
  const [successmodal, setSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isPaymentLoading, setPaymentLoading] = useState(false);
  const [fileData, setFileData] = useState(null);
  const id = useQuery("id")
  const prescriptionId = useQuery("prescriptionId")
  const navigate = useNavigate();

  const handleFileUpload = (event) => {
    const files = event.target.files;
    const filePreviews = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const previewUrl = file.type.startsWith("image/") ? URL.createObjectURL(file) : null;
      filePreviews.push({ file, previewUrl });
    }

    setUploadedFiles((prevFiles) => [...prevFiles, ...filePreviews]);
  };

  console.log(uploadedFiles)


  const handleFileDelete = (index) => {
    const newFiles = [...uploadedFiles];
    newFiles.splice(index, 1);
    setUploadedFiles(newFiles);
  };

  const handleSubmitFile = async () => {
    setPaymentLoading(true);

    if (uploadedFiles.length === 0) {
      setPaymentLoading(false);
      return;
    }

    const formdata = new FormData();
    const filePromises = uploadedFiles.map((uploadedFile, index) => {
      formdata.append(`file[${index}]`, uploadedFile.file);
      return convertFileToBase64(uploadedFile.file).then(base64File => ({
        name: uploadedFile.file.name,
        base64: base64File
      }));
    });

    try {
      const filesBase64 = await Promise.all(filePromises);
      if (prescriptionId !== null) {
        // localStorage.setItem('uploadedImages', JSON.stringify(uploadedFiles));
      }

      // Check if id is null
      if (id === null) {
        setSuccessModal(true);
        setSuccessMessage("Prescription uploaded Successfully");
        setModalShow(true);
        setTimeout(() => {
          setModalShow(false);
          setSuccessModal(false);
          if (prescriptionId == 0) {
            navigate("/placeorder", { state: uploadedFiles });
          } else {
            navigate("/placeorder/diagnostictest");
          }
        }, 3000);
        setPaymentLoading(false);
        return;
      }

      const fileRes = await fetch(UPLOAD_PRESCRIPTION__API, {
        headers: {
          Authorization: `${token}`,
        },
        method: "POST",
        body: formdata,
      });

      if (!fileRes.ok) {
        console.error("API error:", fileRes.status, fileRes.statusText);
        const fileData = await fileRes.json().catch(() => null);
        console.error("Error details:", fileData);
        throw new Error("Network response was not ok");
      }

      const uploadData = await fileRes.json();
      setFileData(uploadData);

      if (uploadData.status === 200) {
        setSuccessModal(true);
        setSuccessMessage(uploadData.message);
        setModalShow(true);
        setTimeout(() => {
          setModalShow(false);
          setSuccessModal(false);
          if (id == 0) {
            navigate("/buyMedicines");
          } else {
            navigate("/diagnostictest");
          }
        }, 3000);
      } else {
        alert("Unsuccessful submission");
      }
    } catch (error) {
      console.error("Error fetching home data:", error);
    } finally {
      setPaymentLoading(false);
    }
  };

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const openModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <div className="home-review-banner">
        <div className="prescription-main-box-cover">
          <div className="app-header-wraper">
            <div className="header-top-section">
              <div className="header-top-section-category-section">
                <h3>Upload Prescription</h3>
                <p>Please upload prescription for any help</p>
              </div>
              <div className="header-top-section-add-to-cart-section-hert">
                <img src={appHeartIcon} width="100%" alt="heart-icon" />
              </div>
            </div>
          </div>
          <div className="prescription-image-box">
            <img src={appPrescriptionImg} width="100%" alt="Prescriotion" />
          </div>
          <div className="prescription-upload">
            <input
              type="file"
              name="file-input"
              id="file-input"
              label="Upload Prescription"
              onChange={handleFileUpload}
              accept="image/*,application/pdf"  // <- Modified this line
              className="file-upload"
            />
            <label className="upload-file" htmlFor="file-input">
              <span>Upload Prescription</span>
            </label>
          </div>
          <div className="valid-prescription" onClick={openModal}>
            <p>What is Valid Prescription?</p>
          </div>
          <Modal centered show={isModalVisible} onHide={closeModal}>
            <Modal.Header className="valid-head" closeButton>
              <Modal.Title className="valid-presc">
                What is Valid Prescription ?
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="valid-body">
              <img src={PrescriptionImg} alt="Prescripition-image" />
            </Modal.Body>
            <Modal.Footer className="valid-foot">
              <p>
                Prescription should contain details of doctor and patient's clinic
                visit date, medicines will be given as per prescription, with
                supported file types like jpeg, jpg, png, and pdf
              </p>
            </Modal.Footer>
          </Modal>
          <div className="add-prescription-box">
            {uploadedFiles.map((uploadedFile, index) => (
              <div className="add-box-pre" key={index}>
                <div className="add-box">
                  {uploadedFile.previewUrl ? (
                    <img
                      src={uploadedFile.previewUrl}
                      width="100%"
                      alt={`Preview ${index + 1}`}
                    />
                  ) : (
                    <p>{uploadedFile.file.name}</p>  // <- Modified this part
                  )}
                </div>
                <div className="delete-box">
                  <button
                    className="delete-btn"
                    onClick={() => handleFileDelete(index)}
                  >
                    X
                  </button>
                </div>
              </div>
            ))}
          </div>
          <div className="submit-button">
            {uploadedFiles.length > 0 && (
              <button
                onClick={() => {
                  handleSubmitFile();
                }}
              >
                <p>Submit</p>
              </button>
            )}
          </div>
        </div>

        <Toaster
          position="top-center"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            className: "custom-toast",
            duration: 3000,
            style: {
              background: "#fff",
              color: "#363636",
              fontSize: "14px",
              width: "300px",
              top: "55px !important",
            },
          }}
        />
      </div>
      {isPaymentLoading && (
        <div className="payment-loading-page">
          <PaymentLoader />
        </div>
      )}
      {successmodal && (
        <SuccessFull show={modalShow} successMessage={successMessage} animationtype={successmodal} />
      )}

      {/* ... existing code ... */}
    </>
  );
};

export default UploadPrescription;
