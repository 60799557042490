// LocationSection.js
import React, { useState, useEffect } from 'react';
import Modals from '../Modals';
import { IoLocationSharp } from "react-icons/io5";
import { IoMdArrowDropdown } from "react-icons/io";
import {useNavigate } from 'react-router-dom';

const LocationSection = ({medicineData}) => {
  const [modalShow, setModalShow] = useState(false);
  const [cityIndexData, setCityIndexData] = useState(null);
  const [pincode, setPincode] = useState('');
  const [apiStatus, setApiStatus] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const localcityData = localStorage.getItem('pincodeData');
    const localPincode = localStorage.getItem('pincode');

    if (!localPincode) {
      setModalShow(true);
    }

    if (localcityData) {
      try {
        const cityFormData = JSON.parse(localcityData);

        setCityIndexData((prevCityIndexData) => {
          if (cityFormData.status === 200) {
            setApiStatus(200);
            const timeoutId = setTimeout(() => {
              if (modalShow && apiStatus === 200) {
                setModalShow(false);
                navigate('/buymedicines');
              }
            }, 180000); // 3 minutes timeout

            // Save the timeout ID to clear it when the component unmounts
            localStorage.setItem('timeoutId', timeoutId);

            return cityFormData;
          }

          return prevCityIndexData;
        });
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }
    } else {
      console.error('pincodeData is undefined in localStorage');
    }

    setPincode(localPincode || '');

    // Clear the timeout when the component is unmounted
    return () => {
      const timeoutId = localStorage.getItem('timeoutId');
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [navigate, modalShow, apiStatus]);

  const handleModalSuccess = () => {
    if (modalShow && apiStatus === 200) {
      setModalShow(false);
      navigate('/buymedicines');
    }
  };
  const handleClose=()=>{
    setModalShow(false);
    window.history.back();
  }

  return (
    <div className="header-top-section-category-section-location">
      <button variant="primary" onClick={() => setModalShow(true)}>
        <IoLocationSharp />
        <h5>{cityIndexData ? `${cityIndexData.city_name},${pincode}` : 'Loading...'} </h5>
        <IoMdArrowDropdown />
      </button>
      <Modals medicineData={medicineData} show={modalShow} onClose={handleClose} onHide={() => setModalShow(false)} onSuccess={handleModalSuccess} />
    </div>
  );
};

export default LocationSection;
