import React from 'react'
import { Toaster } from 'react-hot-toast'

const ToastBox = () => {
    return (
        <div>
            <Toaster
                position="top-center"
                reverseOrder={false}
                gutter={8}
                containerClassName=""
                containerStyle={{}}
                toastOptions={{
                    className: "",
                    duration: 2700,
                    style: {
                        background: "#fff",
                        color: "#363636",
                    },
                }}
            />
        </div>
    )
}

export default ToastBox