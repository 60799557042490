
import "../src/Styles/Web-app.css"
import Home from "./components/Home";
import Footer from "./components/Footer";
import { Routes, useNavigate, Navigate, useLocation, Route } from 'react-router-dom';
import { IoCaretBackCircleOutline } from "react-icons/io5";
import Profile from "./Pages/MyProfile/Profile";
import Medicine from "./Pages/MedicinePages/Medicine";
import ProductDescription from "./Pages/MedicinePages/ProductDescription";
import AddAddress from "./Pages/MedicinePages/AddAddress";
import AddressDetails from "./Pages/MedicinePages/AddressDetails";
import PlacedOrder from "./Pages/MedicinePages/PlacedOrder";
import UploadPrescription from "./Pages/UploadPrescription";
import Labtest from "./Pages/LabTestPages/Labtest";
import CommonBookTest from "./Pages/LabTestPages/CommonBookTest";
import CategoriesTest from "./Pages/LabTestPages/CategoriesTest";
import HealthPackages from "./Pages/LabTestPages/HomeHealthPackages";
import MyPackageDetails from "./Pages/MyProfile/Pages/MyPackageDetails";
import TermsCondition from "./Pages/MyProfile/Pages/Terms&Conditions";
import PrivacyPolicy from "./Pages/MyProfile/Pages/Privacypolicy";
import MyWalletUtilization from './Pages/MyProfile/Pages/MyWalletUtilization'
import DentalConsultation from "./Pages/DentalConsultation/DentalConsultation"
import Yoga from "./Pages/Wellness/Yoga";
import Fitness from "./Pages/Wellness/Fitness";
import Weight from "./Pages/Wellness/Weight";
import Nutrition from "./Pages/Wellness/Nutrition";
import LifeStyle from "./Pages/Wellness/LifeStyle";
import Login from "./components/Login";
import VideoBox from "./Pages/Videos/VideoBox";
import VideoDetailBox from "./Pages/Videos/VideoDetailBox";
import BlogDetail from "./Pages/Blog/BlogDetail";
import PackageDetailBox from "./Pages/PackageBox/PackageDetailBox";
import { Link } from "react-router-dom";
// import NutritionConsultation from "./Pages/OtherPages/NutritionConsultation";
import MedicalLoan from "./Pages/MedicalLoan";
import AmbulanceAssistance from "./Pages/OtherPages/AmbulanceAssistance";
import OpdHome from "./Pages/Opd/OpdHome";
import OpdDoctor from "./Pages/Opd/Doctor_opd";
import Doctordetails from "./Pages/Opd/DoctorDetail";
import Package_Description from "./Pages/LabTestPages/Package_Description";
import Test_Description from "./Pages/LabTestPages/Test_Description";
import TestbyCategories from "./Pages/LabTestPages/TestbyCategories";
import SuperTopUp from "./Pages/OtherPages/SuperTopUp";
import SuccessFull from "./Animation/SuccessFull";
import Loader from "./Animation/Loader";
import SuperWatch from "./Pages/OtherPages/SuperWatch";
// import MainCart from "./Pages/Cart/Cart";
import MainCart from "./Pages/Cart/MainCart"
import Patients from "./Pages/LabTestPages/pages/Patient";
import Address_Details from "./Pages/Cart/AddressDetails";
import DateSlotTime from "./Pages/Cart/DateSlotTime";
import FitnessPro from "./Pages/PackageBox/FitnessPro"
import LoadPackage from "./Pages/PackageBox/LoadPackage"
// import MedicineOrder from "./Pages/MedicinePages/MedicineOrder";
import SearchTestMed from "./Pages/SearchPage/SearchTestMed";
import HomeRadiology from "./Pages/Radiology/HomeRadiology";
import ReimburseForm from "./Pages/PackageBox/ReimbursementForm";
import YogaSession from "./Pages/PackageBox/YogaSession";
import Notification from "./Pages/Notification/Notification";
import Radiology from "./Pages/Radiology/Radiology";
import BookTest from "./Pages/Radiology/pages/BookTest";
import Categories from "./Pages/Radiology/pages/Categories";
import CTScan from "./Pages/Radiology/pages/CTScan";
import Centers from "./Pages/Radiology/pages/Centers";
import DateSlot from "./Pages/Radiology/pages/DateSlot";
import PatientDetail from "./Pages/Radiology/pages/PatientDetail";
import RadioCart from "./Pages/Radiology/pages/RadioCart";

import HomeTeleConsultation from "./Pages/NewTeleConsultation/Pages/HomeTeleConsultation";
import SpecialistTeleConsultation from "./Pages/NewTeleConsultation/Pages/SpecialistTeleConsultation";
import SymptomsTeleConsultation from "./Pages/NewTeleConsultation/Pages/SymptomsTeleConsultation";
import BookTeleConsultation from "./Pages/NewTeleConsultation/Pages/BookingTeleConsultation";
import NutritionConsultation from "./Pages/NewTeleConsultation/Pages/NutritionConsultation";
import { Suspense, useEffect } from "react";
import MedicineAddress from "./Pages/MedicinePages/Medicine_Address";
import NewOrderDetails from "./Pages/MyProfile/Pages/NewOrderDetails";
import TrackOrder from "./Pages/MyProfile/Pages/TrackOrder";
import BlogHome from "./Pages/Blog/BlogHome";
import NewOpdHome from "./Pages/Opd/NewOpdHome";
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import YogaMet from "./Pages/OtherPages/YogaMet";
import Explore from "./components/Explore";
import Medicine_Corner from "./Pages/Medicine/Pages/Medicine_Corner";
import Medicine_Home from "../src/Pages/Medicine/Pages/Medicine_Home";
import Medicine_Categories from "./Pages/Medicine/Pages/Medicine_Categories";
import Medicine_Prescription from "./Pages/Medicine/Pages/Medicine_Prescription";
import Medicine_Search_Result from "./Pages/Medicine/Pages/Medicine_Search_Result";
import Medicine_Description from "./Pages/Medicine/Pages/Medicine_Description";
import Medicine_Cart from "./Pages/Medicine/Pages/Medicine_Cart";
import Medicine_Doctor from "./Pages/Medicine/Pages/Medicine_Doctor";
import ReimbursmentDetails from "./Pages/MyProfile/Pages/ReimbursmentDetails";
import Medicine_Address from "./Pages/MedicinePages/Medicine_Address";
import Medicines_Address from "./Pages/Medicine/Pages/Medicines_Address";
import Address_list from "./Pages/Medicine/Pages/Address_list";
import Medicine_order from "./Pages/Medicine/Pages/Medicine_order";
import Place_Order from './Pages/LabTestPages/MainOrder'
import SignUp from "./Pages/Auth/Signup";
import OrderDetailsPage from "./Pages/MyProfile/orderPages/OrderDetailsPage";
import SingleOrderDetails from "./Pages/MyProfile/orderPages/SingleOrderDetails";
import ShipmentOrder from "./Pages/MyProfile/orderPages/ShipmentOrder";
import Dummy from "./Pages/LabTestPages/dummy"


function App() {
  var token = false;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const V2token = queryParams.get('token');
  const V2href = queryParams.get('href');

  if (V2token) {
    token = V2token;
    localStorage.setItem("APP_TOKEN", V2token);
    localStorage.setItem('V2HREF', V2href)
    window.history.pushState({}, '', window.location.pathname);
  } else {
    token = localStorage.getItem("APP_TOKEN");
  }




  const isLoaginPage = window.location.pathname === '/';
  const includeFooterPages = ['/home', '/home-teleconsultation', '/opd', '/dentalconsultation', 'medicalloan'];

  const shouldRenderFooter = includeFooterPages.includes(location.pathname);

  const handleHomeNavigate = (href, mainV2Href) => {
    if (mainV2Href) {
      window.location.href = mainV2Href;
    }
    else {
      window.location.href = href;
    }
  }

  const mainV2Href = localStorage.getItem('V2HREF', V2href)

  return (
    <>
      <div className="home-main-cover-wrapper">
        <div className="home-app-wrapper">

          <div className="home-app-main-cover">
            {
              (V2href || mainV2Href) && (
                <div className="back-to-home" onClick={() => handleHomeNavigate(V2href, mainV2Href)}>
                  <Link> <span><IoCaretBackCircleOutline /></span> Back</Link>
                </div>
              )
            }

            <Suspense fallback={<div>Loading...</div>}>
              <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/sign-up" element={<SignUp />} />
                <Route path="/home" element={token ? <Home /> : <Navigate to="/" />} />
                <Route path="/profile" element={token ? <Profile /> : <Navigate to="/" />} />
                <Route path="/buyMedicines" element={<Medicine token={token} />} />
                <Route path="/reimbursment-details" element={<ReimbursmentDetails token={token} />} />
                <Route path="/productdescription" element={token ? <ProductDescription /> : <Navigate to="/" />} />
                <Route path="/addaddress/:index?" element={token ? <AddAddress /> : <Navigate to="/" />} />
                <Route path="/medicine-address/:index?" element={token ? <MedicineAddress /> : <Navigate to="/" />} />
                <Route path="/addressdetail" element={token ? <AddressDetails /> : <Navigate to="/" />} />
                <Route path="/placeorder/:link?" element={token ? <PlacedOrder /> : <Navigate to="/" />} />
                {/* <Route path="/medicineorder" element={token ? <MedicineOrder /> : <Navigate to="/" />} /> */}
                <Route path="/cart" element={token ? <MainCart /> : <Navigate to="/" />} />
                <Route path="/UploadPrescription/:id?" element={token ? <UploadPrescription /> : <Navigate to="/" />} />
                <Route path="/diagnostictest" element={<Labtest token={token} />} />

                <Route path="/common-book-test" element={token ? <CommonBookTest /> : <Navigate to="/" />} />
                <Route path="/categories-test" element={token ? <CategoriesTest /> : <Navigate to="/" />} />
                <Route path="/patients" element={token ? <Patients /> : <Navigate to="/" />} />
                <Route path="/lab-addressdetails" element={token ? <Address_Details /> : <Navigate to="/" />} />
                <Route path="/test-by-categories/:category_id?" element={token ? <TestbyCategories /> : <Navigate to="/" />} />
                <Route path="/health-packages" element={token ? <HealthPackages /> : <Navigate to="/" />} />
                <Route path="/reimbursement" element={token ? <ReimburseForm token={token} /> : <Navigate to="/" />} />
                <Route path="/my-package-details" element={token ? <MyPackageDetails /> : <Navigate to="/" />} />
                {/* <Route path="/in-personconsultation" element={token ? <OpdHome /> : <Navigate to="/" />} /> */}
                <Route path="/in-personconsultation" element={token ? <NewOpdHome /> : <Navigate to="/" />} />
                <Route path="/doctor-opd" element={token ? <OpdDoctor /> : <Navigate to="/" />} />
                <Route path="/doctordetails/:doc_id?/:doctor_name?" element={token ? <Doctordetails /> : <Navigate to="/" />} />
                <Route path="/terms-conditions" element={token ? <TermsCondition /> : <Navigate to="/" />} />
                <Route path="/privacy-policy" element={token ? <PrivacyPolicy /> : <Navigate to="/" />} />
                <Route path="/my-wallet-utilization" element={token ? <MyWalletUtilization /> : <Navigate to="/" />} />
                <Route path="/my-order-details" element={token ? <NewOrderDetails token={token} /> : <Navigate to="/" />} />
                <Route path="/trackorder" element={token ? <TrackOrder token={token} /> : <Navigate to="/" />} />


                <Route path="/dentalconsultation" element={<DentalConsultation token={token} />} />

                <Route path="/package-description/:pack_id?" element={token ? <Package_Description /> : <Navigate to="/" />} />
                <Route path="/test-description/:test_id?" element={token ? <Test_Description /> : <Navigate to="/" />} />
                <Route path="/yoga" element={token ? <Yoga /> : <Navigate to="/" />} />
                <Route path="/yoga-met/:module_id/:package_id/:description" element={token ? <YogaMet /> : <Navigate to="/" />} />
                <Route path="/yoga-session" element={token ? <YogaSession /> : <Navigate to="/" />} />
                <Route path="/fitness" element={token ? <Fitness /> : <Navigate to="/" />} />
                <Route path="/fitnesspro" element={token ? <FitnessPro token={token} /> : <Navigate to="/" />} />
                <Route path="/weightloss" element={token ? <Weight /> : <Navigate to="/" />} />
                {/* <Route path="/nutrition" element={token ? <Nutrition /> : <Navigate to="/" />} /> */}
                <Route path="/lifestyle" element={token ? <LifeStyle /> : <Navigate to="/" />} />
                <Route path="/videobox/" element={token ? <VideoBox /> : <Navigate to="/" />} />
                <Route path="/medicalloan" element={token ? <MedicalLoan /> : <Navigate to="/" />} />
                <Route path="/superwatch/:module_id/:package_id/:description" element={token ? <SuperWatch /> : <Navigate to="/" />} />
                <Route path="/ambulanceassistance" element={token ? <AmbulanceAssistance /> : <Navigate to="/" />} />
                <Route path="/supertopup" element={token ? <SuperTopUp /> : <Navigate to="/" />} />
                {/* <Route path="/nutritionconsultation" element={token ? <NutritionConsultation /> : <Navigate to="/" />} /> */}
                <Route path="/videodetailbox/:id" element={token ? <VideoDetailBox /> : <Navigate to="/" />} />
                <Route path="/blogdetail/:id" element={token ? <BlogDetail /> : <Navigate to="/" />} />
                <Route path="/date-timeslot" element={token ? <DateSlotTime /> : <Navigate to="/" />} />
                <Route path="/search" element={token ? <SearchTestMed /> : <Navigate to="/" />} />
                <Route path="/radiologyscreening" element={token ? <HomeRadiology /> : <Navigate to="/" />} />
                <Route path="/packagedetailbox/:id/:user_package_id/:title/:valid_from/:valid_to/:desciption" element={token ? <PackageDetailBox /> : <Navigate to="/" />} />
                <Route path="/loadpackage" element={token ? <LoadPackage /> : <Navigate to="/" />} />
                <Route path="/success" element={token ? <SuccessFull /> : <Navigate to="/" />} />
                {/* <Route path="/location" element={<GoogleLocation />}/> */}
                <Route path="/loader" element={token ? <Loader /> : <Navigate to="/" />} />

                <Route path="/teleconsultation" element={<HomeTeleConsultation token={token} isV2token={V2token} />} />
                <Route path="/specialist-teleconsultation" element={<SpecialistTeleConsultation token={token} />} />
                <Route path="/symptoms-teleconsultation" element={<SymptomsTeleConsultation token={token} />} />
                <Route path="/book-teleconsultation/:id?" element={<BookTeleConsultation token={token} isV2token={V2token} />} />
                <Route path="/notification" element={<Notification token={token} />} />
                <Route path="/blog" element={<BlogHome token={token} />} />
                <Route path="/nutrition-consultation" element={<NutritionConsultation token={token} />} />
                <Route path="/radiology-box" element={<Radiology token={token} />} />
                <Route path="/medicines" element={<Medicine_Home token={token} />} />
                <Route path="/medicines-address" element={<Medicines_Address token={token} />} />
                <Route path="/health-corner" element={<Medicine_Corner token={token} />} />
                <Route path="/medicine-category" element={<Medicine_Categories token={token} />} />
                <Route path="/prescription" element={<Medicine_Prescription token={token} />} />
                <Route path="/search-medicine" element={<Medicine_Search_Result token={token} />} />
                <Route path="/medicine-description" element={<Medicine_Description token={token} />} />
                <Route path="/medicine-cart" element={<Medicine_Cart token={token} />} />
                <Route path="/address-list" element={<Address_list token={token} />} />
                <Route path="/order-details" element={<OrderDetailsPage token={token} />} />
                <Route path="/place-order" element={<Place_Order token={token} />} />
                <Route path="/call-doctor" element={<Medicine_Doctor token={token} />} />
                <Route path="/single-order-details" element={<SingleOrderDetails token={token} />} />
                <Route path="/shipment-details" element={<ShipmentOrder token={token} />} />
                <Route path="/explore" element={<Explore token={token} />} />


                {/* <Route path="/helpsupport" element={<Help/>}/> */}
              </Routes>
            </Suspense>
            {shouldRenderFooter && <Footer />}
          </div>
        </div>
      </div>
    </>

  );
}

export default App;
