import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  appAssistance,
  appConductTest,
  appCovidSupport,
  appDignosticsupport,
  appHome,
  appInsuranceClaimSupport,
  appKnowMore,
  appOtherSupport,
  appPathlogy,
  appProfile,
  appRadiologysupport,
  appSupport,
  appTalkDoctor,
  appWallet,
  appWhatsapp,
  hospitalsupport,
  teleconsultationsupport,
} from "../images";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import SuccessFull from "../Animation/SuccessFull";
import { fetchApi } from "../utils/AllFunction";
import { WHATSAPP_NUMBER } from "../Constants";
import HelpModal from "../Pages/MyProfile/Components/HelpModal";

const Footer = () => {
  const [showModal, setShowModal] = useState(false);


  const handleShow = () => {
    setShowModal(true);
  };

  const handleWhatsApplink = () => {
    const whatsappLink = `https://wa.me/${WHATSAPP_NUMBER}`;
    window.open(whatsappLink, "_blank");
  };

  return (
    <>
      <div class="footer-wrapper">
        <div class="footer-cover">
          <div class="home">
            <NavLink to="/home">
              <button>
                <div class="footer-image-box">
                  <img src={appHome} alt="Home" />
                </div>
                <p>Home</p>
              </button>
            </NavLink>
          </div>
          <div className="support">
            <button onClick={handleShow}>
              <div className="footer-image-box">
                <img src={appSupport} alt="support" />
              </div>
              <p>Help</p>
            </button>
          </div>
          <div class="whatsapp">
            <button onClick={handleWhatsApplink}>
              <div class="footer-image-box">
                <img src={appWhatsapp} alt="Whatsapp" />
              </div>
              <p>Whatsapp</p>
            </button>
          </div>
          <div class="profile">
            <NavLink to="/profile">
              <button>
                <div class="footer-image-box">
                  <img src={appProfile} alt="Profile" />
                </div>
                <p>Profile</p>
              </button>
            </NavLink>
          </div>
        </div>
      </div>
      <HelpModal showModal={showModal} setShowModal={setShowModal}/>
    </>
  );
};

export default Footer;
