import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import '../Medicine_Style/address.css';
import { validateNumberInput } from '../../../utils/AllFunction';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPincode } from '../../../Redux/actions/GetApiCall';
import { saveMedicine } from '../../../Redux/actions/PostApiCall';
import LoaderAnime from "../animation/prescription_loader";
import toast, { Toaster } from 'react-hot-toast';
import ToastBox from '../animation/ToastBox';
import { useNavigate } from 'react-router-dom';

const Address = ({ Data }) => {
    const navigate = useNavigate();
    const { register, watch, handleSubmit, formState: { errors }, setValue } = useForm({
        defaultValues: {
            type: Data?.type || '',
            name: Data?.name || '',
            mobile_no: Data?.mobile_no || '',
            email_id: Data?.email_id || '',
            address: Data?.address || '',
            landmark: Data?.landmark || '',
            pincode: Data?.pincode || '',
        }
    });

    const dispatch = useDispatch();
    const cityData = useSelector(state => state.medicine_pincode);
    const [showModal, setShowModal] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const saveAddress = useSelector(state => state.save_address);
    console.log(saveAddress)
    const onSubmit = async (data) => {
        const formData = {
            ...data,
            type: parseInt(data.type, 10),
            city: Data?.city || cityData?.data?.city,
            state: Data?.state || cityData?.data?.state,
            ...(Data?.id && { address_id: Data.id })
        };

        await dispatch(saveMedicine(formData));
    };

    useEffect(() => {
        if (saveAddress?.status === "success") {
            const tabkeyData = {
                tabkey: "medicines"
            }
            setTimeout(() => {
                navigate("/address-list", { state: tabkeyData })
            }, 1500);
        }
    }, [saveAddress, navigate]);

    const pincodeValue = watch("pincode");

    useEffect(() => {
        if (pincodeValue?.length === 6) {
            dispatch(fetchPincode(pincodeValue));
        }
    }, [pincodeValue, dispatch]);

    useEffect(() => {
        if (saveAddress?.loader === true) {
            setShowModal(true);
        } else {
            setShowModal(false);
            if (saveAddress?.data) {
                if (saveAddress?.status === "success") {
                    toast.success(saveAddress?.data?.message);
                }
                else if (saveAddress?.status === "failure") {
                    toast.error(saveAddress?.message);
                }
            }
        }
    }, [saveAddress]);

    useEffect(() => {
        if (cityData?.data?.city === "" || cityData?.data?.state === "" || cityData?.data?.success === false) {
            setIsButtonDisabled(true);
        } else {
            setIsButtonDisabled(false);
        }
    }, [cityData, setValue]);

    return (
        <div className='address-component-wrapper-boxs'>
            {saveAddress && <LoaderAnime showModal={showModal} />}
            <div className="address-type-como">
                <p>Address Type</p>
                <form className="my-form" onSubmit={handleSubmit(onSubmit)}>
                    <div className="radio-type-form-box">
                        <div>
                            <input
                                id="radio-1"
                                type="radio"
                                name="type"
                                value="1"
                                {...register("type", { required: "Please select an address type" })}
                                defaultChecked={Data?.type === 1}
                            />
                            <label htmlFor="radio-1">Home</label>
                        </div>
                        <div>
                            <input
                                id="radio-2"
                                type="radio"
                                name="type"
                                value="2"
                                {...register("type", { required: "Please select an address type" })}
                                defaultChecked={Data?.type === 2}
                            />
                            <label htmlFor="radio-2">Office</label>
                        </div>
                        <div>
                            <input
                                id="radio-3"
                                type="radio"
                                name="type"
                                value="3"
                                {...register("type", { required: "Please select an address type" })}
                                defaultChecked={Data?.type === 3}
                            />
                            <label htmlFor="radio-3">Other</label>
                        </div>
                    </div>
                    {errors.type && <p className="error-message">{errors.type.message}</p>}

                    <div className="fomr-repo-box-data-cove">
                        <label>Name</label>
                        <input
                            placeholder="Enter Name"
                            {...register("name", { required: "Name is required" })}
                        />
                        {errors.name && <p className="error-message">{errors.name.message}</p>}

                        <label>Mobile Number</label>
                        <input
                            placeholder="Enter Mobile Number"
                            {...register("mobile_no", {
                                required: "Mobile number is required",
                                pattern: {
                                    value: /^[0-9]{10}$/,
                                    message: "Invalid mobile number"
                                }
                            })}
                            maxLength={10}
                            onInput={validateNumberInput}
                        />
                        {errors.mobile_no && <p className="error-message">{errors.mobile_no.message}</p>}

                        <label>Email</label>
                        <input
                            placeholder="Enter Email Address"
                            {...register("email_id", {
                                required: "Email is required",
                                pattern: {
                                    value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                                    message: "Invalid email address"
                                }
                            })}
                        />
                        {errors.email_id && <p className="error-message">{errors.email_id.message}</p>}

                        <label>Address</label>
                        <input
                            placeholder="Enter Address"
                            {...register("address", { required: "Address is required" })}
                        />
                        {errors.address && <p className="error-message">{errors.address.message}</p>}

                        <label>Landmark</label>
                        <input
                            placeholder="Enter Landmark"
                            {...register("landmark", { required: "Landmark is required" })}
                        />
                        {errors.landmark && <p className="error-message">{errors.landmark.message}</p>}

                        <label>Pincode</label>
                        <div className="inputcontainer">
                            <input
                                placeholder="Enter Pincode"
                                {...register("pincode", {
                                    required: "Pincode is required",
                                    pattern: {
                                        value: /^[0-9]{6}$/,
                                        message: "Invalid pincode"
                                    },
                                })}
                                disabled={cityData?.loader === true}
                                maxLength={6}
                                onInput={validateNumberInput}
                            />
                            {cityData?.loader === true && (
                                <div className="icon-container">
                                    <i className="loader"></i>
                                </div>
                            )}
                        </div>
                        {cityData?.loader === false && pincodeValue && pincodeValue.length === 6 && (
                            <p className={cityData?.data?.success ? 'green' : 'red'}>
                                {cityData?.data?.message}
                            </p>
                        )}

                        {errors.pincode && <p className="error-message">{errors.pincode.message}</p>}

                        <div className="state-repo-box">
                            <div className="city">
                                <label>City</label>
                                <input
                                    disabled
                                    value={cityData?.data?.city || (cityData?.data?.city === "" ? "" : Data?.city)}
                                    placeholder="Enter City"
                                />
                            </div>

                            <div className="state">
                                <label>State</label>
                                <input
                                    disabled
                                    value={cityData?.data?.state || (cityData?.data?.state === "" ? "" : Data?.state)}
                                    placeholder="Enter State"
                                />
                            </div>
                        </div>

                    </div>

                    <button type="submit" disabled={isButtonDisabled}>SAVE ADDRESS</button>
                </form>
            </div>

            <ToastBox />
        </div>
    );
}

export default Address;
