import React, { useEffect, useState } from 'react';
import { IoLocationSharp } from "react-icons/io5";
import { BiSolidEdit } from "react-icons/bi";
import RadiologyModal from '../RadiologyModal';
import { useNavigate } from 'react-router';

const RadiologySection = () => {
  const [modalShow, setModalShow] = useState(false);
  const [selectedLocationData, setSelectedLocationData] = useState(null);
  const navigate=useNavigate()

  useEffect(() => {
    const radiologyCityData = localStorage.getItem('selectedRadiologyData');


    if (radiologyCityData) {
      try {
        const cityFormData = JSON.parse(radiologyCityData);

        setSelectedLocationData((prevCityIndexData) => {
          if (cityFormData) {
            const timeoutId = setTimeout(() => {
              if (modalShow) {
                setModalShow(false);
                navigate('/radiology-box');
              }
            }, 180000); // 3 minutes timeout

            // Save the timeout ID to clear it when the component unmounts
            localStorage.setItem('timeoutId', timeoutId);

            return cityFormData;
          }

          return prevCityIndexData;
        });
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }
    } else {
      console.error('pincodeData is undefined in localStorage');
    }


    // Clear the timeout when the component is unmounted
    return () => {
      const timeoutId = localStorage.getItem('timeoutId');
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [navigate, modalShow]);

  return (
    <div className="header-top-section-category-section-location">
      <button variant="primary" onClick={() => setModalShow(true)}>
        <IoLocationSharp />
        <h5>{selectedLocationData ? `${selectedLocationData.selectedCity}, ${selectedLocationData.selectedState}` : 'Loading...'} </h5>
        <BiSolidEdit />
      </button>
      <RadiologyModal show={modalShow} onHide={() => setModalShow(false)}/>
    </div>
  );
};

export default RadiologySection;
