import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner'; // You can use this or any loading component
import '../style/cancelorder.css';
import { useForm } from 'react-hook-form';
import { cancel_order_image } from '../../Medicine/Images';
import { useDispatch, useSelector } from 'react-redux';
import { CancelOrder } from '../../../Redux/actions/PostApiCall';
import { useNavigate } from 'react-router';

const CancelOrderModal = ({ show, setShow, OrderId }) => {
    const handleClose = () => setShow(false);
    const dispatch = useDispatch();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [selectedType, setSelectedType] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [loading, setLoading] = useState(false);
    const [orderSuccess, setOrderSuccess] = useState(false);
    const navigate = useNavigate();

    const orderCancelData = useSelector(state => state.cancel_order_medicine);

    useEffect(() => {
        // Check if the status is loading or success
        if (orderCancelData?.status === 'loading') {
            setLoading(true);
        } else if (orderCancelData?.data?.success === true) {
            setLoading(false);
            setOrderSuccess(true);
        } else {
            setLoading(false);
        }
    }, [orderCancelData]);

    const handleTypeChange = (e) => {
        setSelectedType(e.target.value);
    };

    const handleCanelOrder = async () => {
        setLoading(true); // Start loading when cancel order action is dispatched
        const data = {
            medicine_order_id: OrderId,
            cancellation_reason: selectedType,
            source: 0
        };
        await dispatch(CancelOrder(data));
    };

    const handleCancelOrder = () => {
        setShowConfirmation(true);
    };

    const handleOk = () => {
        navigate("/order-details");
        window.location.reload();
    }

    return (
        <div>
            <Modal
                show={show}
                onHide={handleClose}
                centered
                backdrop="static"
                keyboard={false}
            >
                {!showConfirmation ? (
                    <>
                        <Modal.Header closeButton className='head-canel-repo'>
                            <Modal.Title className='reason-title'>Reasons for cancellation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className='repo-class-cancel'>
                            <div className="cancel-order-form-box">
                                <div>
                                    <input
                                        id="radio-1"
                                        type="radio"
                                        name="type"
                                        value="Order is delayed"
                                        onChange={handleTypeChange}
                                    />
                                    <label htmlFor="radio-1">Order is delayed</label>
                                </div>
                                <div>
                                    <input
                                        id="radio-2"
                                        type="radio"
                                        name="type"
                                        value="I have already purchased medicines"
                                        onChange={handleTypeChange}
                                    />
                                    <label htmlFor="radio-2">I have already purchased medicines</label>
                                </div>
                                <div>
                                    <input
                                        id="radio-3"
                                        type="radio"
                                        name="type"
                                        value="You are not delivering full order"
                                        onChange={handleTypeChange}
                                    />
                                    <label htmlFor="radio-3">You are not delivering full order</label>
                                </div>
                                <div>
                                    <input
                                        id="radio-4"
                                        type="radio"
                                        name="type"
                                        value="I was just trying out"
                                        onChange={handleTypeChange}
                                    />
                                    <label htmlFor="radio-4">I was just trying out</label>
                                </div>
                                <div>
                                    <input
                                        id="radio-5"
                                        type="radio"
                                        name="type"
                                        value="I am unhappy with estimated delivery date"
                                        onChange={handleTypeChange}
                                    />
                                    <label htmlFor="radio-5">I am unhappy with estimated delivery date</label>
                                </div>
                                <div>
                                    <input
                                        id="radio-6"
                                        type="radio"
                                        name="type"
                                        value="6"
                                        onChange={handleTypeChange}
                                    />
                                    <label htmlFor="radio-6">Other</label>
                                </div>

                                {selectedType === '6' && (
                                    <div className="other-input">
                                        <label htmlFor="otherReason">Please enter reason:</label>
                                        <input
                                            id="otherReason"
                                            type="text"
                                        />
                                    </div>
                                )}
                            </div>
                        </Modal.Body>
                        <Modal.Footer className='foot-cancel-repo'>
                            <Button variant="danger" onClick={handleCancelOrder} disabled={selectedType === ""}>
                                Cancel Order
                            </Button>
                        </Modal.Footer>
                    </>
                ) : (
                    <Modal.Body className='repo-class-cancel'>
                        {loading ? (
                            <div className="loading-spinner">
                                <Spinner animation="border" role="status">
                                </Spinner>
                                <span className="sr-only">Please Wait...</span>
                            </div>
                        ) : orderSuccess ? (
                            <div className="confirmation-message">
                                <div className="cancel-order-image">
                                    <img src={cancel_order_image} alt="Confirmation Icon" className="confirmation-image" />
                                </div>
                                <p>{orderCancelData?.data?.message}</p>
                                <Button className='no-modal-data' onClick={handleOk}>OK</Button>
                            </div>
                        ) : (
                            <div className="confirmation-message">
                                <div className="cancel-order-image">
                                    <img src={cancel_order_image} alt="Confirmation Icon" className="confirmation-image" />
                                </div>
                                <p>Are you sure you want to cancel the order?</p>
                                <div className="confirmation-buttons">
                                    <Button className='yes-modal-data' onClick={handleCanelOrder}>Yes</Button>
                                    <Button className='no-modal-data' onClick={() => setShowConfirmation(false)}>No</Button>
                                </div>
                            </div>
                        )}
                    </Modal.Body>
                )}
            </Modal>
        </div>
    );
};

export default CancelOrderModal;
