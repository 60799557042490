import React, { useContext, useEffect } from 'react'
import { AppContext } from '../../ContextApi';
import { useNavigate, useParams } from 'react-router';
import { headerLogo } from '../../images';

const BlogDetail = () => {
    const { blogdetaildata, setBlogDetailData } = useContext(AppContext);
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        if (!blogdetaildata || !blogdetaildata[id]) {
            navigate('/home');
        }
    }, [id, navigate, blogdetaildata]);

    const Blog = blogdetaildata && blogdetaildata[id];

    if (!Blog) {
        return <p>Loading...</p>;
    }
    const IMAGE_HEALTH_URL = "https://medibhai.com/uploads/health_feed/"
    return (
        <>
            <div className="home-review-banner">
                <div className="app-header-wraper video-box-repo">

                    <div className="header-logo">
                        <img src={headerLogo} width="100%" alt="header-logo" />
                    </div>
                </div>
                <div className="blog-section-wrapper">
                    <div className="blog-section-cover">
                        <div className="blog-text-header">
                            <p>{Blog.subject}</p>
                        </div>
                        <div className="blog-doctor-header">
                            <p>{Blog.doctor_name}</p>
                        </div>
                        <div className="blog-img-box">
                            <img
                                src={Blog?.feature_image ? `${IMAGE_HEALTH_URL}${Blog.feature_image}` : headerLogo}
                                width="100%"
                                alt="health-care"
                                onError={(e) => {
                                    e.target.onerror = null; // Prevents infinite loop if fallback also fails
                                    e.target.src = headerLogo;
                                    e.target.classList.add('fallback-image'); // Add a specific class for fallback image
                                }}
                                className={!Blog?.feature_image ? 'fallback-image' : ''}
                            />
                        </div>
                        <div className="blog-description-page" dangerouslySetInnerHTML={{ __html: Blog.ckeditor_description }}>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BlogDetail