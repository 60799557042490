import { createApiAction } from "./apiActions";
import {
  SEARCH_DIAGNOSTIC_TEST_API,
  HEALTH_PACKAGE_API,
  CATEGORY_TEST_API,
  DIAGNOSTICS_ORDER_DETAILS_API,
  DIAGNOSTIC_CART_DETAILS_API,
  DIAGNOSTIC_TEST_API,
  PATIENT_LIST_API,
  GET_TIME_SLOTS_API,
} from "../../Constants";

const API_BASE_URL = "https://wellness.medibhai.co.in/api";
const pincode = localStorage.getItem("pincode");

const fetchApi = (endpoint, actionType) => {
  const AppToken = localStorage.getItem("APP_TOKEN");
  return createApiAction(actionType, () =>
    fetch(`${API_BASE_URL}/${endpoint}`, {
      method: "GET",
      headers: {
        Authorization: AppToken,
      },
    })
  );
};

export const fetchMedicine = () =>
  fetchApi("medicine/medicine-category-details", "FETCH_MEDICINE");

export const fetchorderhistory = () =>
  fetchApi("medicine/order-history", "FETCH_HISTORY");

export const fetchTrackOrder = (order_id, meg_id) =>
  fetchApi(
    `medicine/order-details/?medicine_order_id=${order_id}&onemg_order_id=${meg_id}`,
    "FETCH_TRACK_ORDER"
  );

export const fetchMedicinelist = (medicineName) => {
  const encodedMedicineName = encodeURIComponent(medicineName);
  return fetchApi(
    `medicine/medicine-list?pincode=${pincode}&medicine_name=${encodedMedicineName}`,
    "FETCH_MEDICINE_LIST"
  );
};

export const fetchPincode = (Citypincode) =>
  fetchApi(`medicine/city/serviceable?pincode=${Citypincode}`, "FETCH_PINCODE");

export const fetchMedicineAddress = () =>
  fetchApi("medicine/user-address-list", "FETCH_MEDICINE_ADDRESS");

export const fetchSingleMedicinelist = (id, type) =>
  fetchApi(
    `medicine/medicine-details?sku_id=${id}&type=${type}&pincode=${pincode}`,
    "FETCH_SINGLE_MEDICINE_LIST"
  );

export const fetchMedicineRecent = () =>
  fetchApi(
    `medicine/previous-ordered-list?pincode=${pincode}`,
    "FETCH_MEDICINE_RECENT_LIST"
  );

export const fetchMedicineCartList = (package_id, wallet_id) =>
  fetchApi(
    `medicine/medicine-cart-details?pincode=${pincode}&package_id=${
      package_id || ""
    }&wallet_id=${wallet_id || ""}`,
    "FETCH_MEDICINE_CART_LIST"
  );

export const fetchPrescriptionList = () =>
  fetchApi("medicine/uploaded-prescriptions-list", "FETCH_PRESCRIPTION_LIST");

export const fetchOfferList = () =>
  fetchApi("medicine/banners-list", "FETCH_OFFER_LIST");

export const fetchMedicineOrderList = () =>
  fetchApi("medicine/place-order-page-details", "FETCH_MEDICINE_ORDER_LIST");

export const fetchDiagnosticTestList = (testQuery) =>
  fetchApi(`${SEARCH_DIAGNOSTIC_TEST_API}?query=${testQuery}`, "SEARCH_DIAGNOSTIC_TEST");

export const fetchCategoriesTestList = () =>
  fetchApi(`${CATEGORY_TEST_API}`, "GET_CATEGORIES_TEST");

export const fetchDiagnosticCartDetails = () =>
  fetchApi(`${DIAGNOSTIC_CART_DETAILS_API}`, "DIAGNOSTIC_CART_DETAILS");

export const getDiagnosticTestList = () =>
  fetchApi(`${DIAGNOSTIC_TEST_API}`, "DIAGNOSTIC_TEST_LIST");

export const getTestDescription = (id) =>
  fetchApi(`${DIAGNOSTIC_TEST_API}/${id}`, "TEST_DESCRIPTION");

export const getpackageDescription = (id) =>
  fetchApi(`${HEALTH_PACKAGE_API}/${id}`, "PACKAGE_DESCRIPTION");

export const getDiagnosticPackageList = () =>
  fetchApi(`${HEALTH_PACKAGE_API}`, "DIAGNOSTIC_PACKAGE_LIST");

export const fetchPatients = () =>
  fetchApi(`${PATIENT_LIST_API}`, "PATIENT_MEMBERS_LIST");

export const fetchTimeSlot = () =>
  fetchApi(`${GET_TIME_SLOTS_API}`, "GET_TIME_SLOTS");

export const fetchPackageList = () =>
  fetchApi("package/my-package-list", "FETCH_PACKAGE_LIST");
