import React, { useEffect, useState } from 'react';
import Medicine_Header from '../Components/Medicine_Header';
import '../Medicine_Style/address-list.css';
import { CiEdit } from "react-icons/ci";
import { VscTrash } from "react-icons/vsc";
import { useDispatch, useSelector } from 'react-redux';
import { fetchMedicineAddress, fetchMedicineCartList, fetchDiagnosticCartDetails } from '../../../Redux/actions/GetApiCall';
import LoaderAnime from '../animation/prescription_loader';
import { deleteMedicineAddress } from '../../../Redux/actions/PostApiCall';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import ToastBox from '../animation/ToastBox';
import Medicine_footer from '../Components/Medicine_footer';
import useLocationState from '../hooks/useLocationState';

const AddressList = () => {
    const Title = "Address Details";
    const dispatch = useDispatch();
    const tabKey = useLocationState();
    const addressList = useSelector(state => state.medicine_address);
    const deleteStatus = useSelector(state => state.delete_address);
    const cartListData = useSelector(state => state.medicine_cart_list.data);
    const cartDetailsResponse = useSelector(state => state.diagnostic_cart_details);
    const navigate = useNavigate();
    const key = "medicines"

    
    const [selectedAddressId, setSelectedAddressId] = useState(null);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        dispatch(fetchMedicineAddress());
        dispatch(fetchMedicineCartList());
        dispatch(fetchDiagnosticCartDetails());
    }, [dispatch]);


    useEffect(() => {
        if (addressList?.data?.selected_address) {
            setSelectedAddressId(addressList?.data?.selected_address)
        }
    }, [addressList])

    useEffect(() => {
        if (addressList?.loader === true || deleteStatus?.loader === true) {
            setShowModal(true);
        } else {
            setShowModal(false);
        }

        if (deleteStatus?.status === "success" && addressList?.status === "success") {
            toast.success(deleteStatus?.message);
        } else if (deleteStatus?.status === "failure" && addressList?.status === "success") {
            toast.error(deleteStatus?.message);
        }
    }, [addressList, deleteStatus]);

    useEffect(() => {
        if (addressList?.data?.data && addressList?.data?.data?.length === 0 && addressList?.status === "success") {
            navigate('/medicines-address');
        }
    }, [addressList]);


    const handleAddressDelete = async (id) => {
        const addressId = {
            address_id: id
        };
        await dispatch(deleteMedicineAddress(addressId));
        await dispatch(fetchMedicineAddress());
    };

    const handleCheckboxChange = (id) => {
        // If the current selected checkbox is clicked again, keep it checked
        setSelectedAddressId(id);
    };

    const handleEdit = (data) => {
        navigate("/medicines-address", { state: data });
    };

    const handleAddAddress = () => {
        navigate("/medicines-address");
    };

    return (
        <div className="home-review-banner">
            <Medicine_Header Title={Title} />
            {<LoaderAnime showModal={showModal} />}
            <div className="add-ress-lis-wrap">
                <div className="address-list-wrapper-cover">
                    <div className="list-cover-address-box">
                        <div className="address-cover-box-cov">
                            {/* Card Content */}
                            {addressList && addressList?.data?.data?.map((item) => (
                                <div className="address-card mb-3" key={item?.id}>
                                    <div className="address-header">
                                        <div className="address-checkbox">
                                            <div className="checkbox-wrapper-46">
                                                <input
                                                    type="checkbox"
                                                    id={item?.id}
                                                    className="inp-cbx"
                                                    checked={selectedAddressId === item?.id}  // Check if this checkbox is selected
                                                    onChange={() => handleCheckboxChange(item?.id)} // Update the selected address
                                                />
                                                <label htmlFor={item?.id} className="cbx-repo">
                                                    <span>
                                                        <svg viewBox="0 0 12 10" height="10px" width="12px">
                                                            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                                        </svg>
                                                    </span>
                                                </label>
                                            </div>

                                            <label className='type-add' htmlFor="homeAddress">{item?.address_type}</label>
                                        </div>
                                        <div className="address-actions">
                                            <button className="edit-btn" onClick={() => handleEdit(item)}>
                                                <CiEdit /> Edit
                                            </button>
                                        </div>
                                    </div>
                                    <div className="address-content">
                                        <p>Name : {item?.name}</p>
                                        <p>Mobile Number : {item?.mobile_no}</p>
                                        <p>Email Address : {item?.email_id}</p>
                                        <p>Address : {item?.address}</p>
                                        <p>Pincode : {item?.pincode}, City : {item?.city}</p>
                                        <p>State : {item?.state}</p>
                                    </div>
                                    <div className="address-delete">
                                        <button className="delete-btn-repo" onClick={() => handleAddressDelete(item?.id)}>
                                            <VscTrash />
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="add-address-bnt-repo">
                    <button onClick={handleAddAddress}>ADD ANOTHER ADDRESS</button>
                </div>
            </div>

            <Medicine_footer tabkey={key || tabKey} cartData={cartListData} labCartData={cartDetailsResponse?.data?.response} AddressId={selectedAddressId} />
            <ToastBox />
        </div>
    );
};

export default AddressList;
