import React from 'react'
import '../Medicine_Style/home.css'
import { headerLogo } from '../../../images';
import { useNavigate } from "react-router-dom";

const Medicine_Product = ({ medicineData }) => {
    const navigate = useNavigate();

    const handleCategory = (textData) => {
        const CategoryData = [medicineData?.category, { label: "Category", value: textData }];
        navigate('/medicine-category', { state: { data: CategoryData } });
    }

    return (
        <div className='product-wrapper-cover-box'>
            <div className="row product-wrapper-repo-box">
                {medicineData && medicineData.category && medicineData.category.map((item, index) => (
                    <div className="col-lg-3 col-md-3 col-3 mb-3" key={index}>
                        <div className="product-box-cover" onClick={() => handleCategory(item?.value)}>
                            <div className="image-box-repo" style={{ backgroundColor: `#${item?.color_code}` }}>
                                <img src={item?.logo ? item?.logo : headerLogo}
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = headerLogo;
                                    }}
                                    alt="product-icon" />
                            </div>

                            <div className="product-text-head">
                                <p className='ellipsis-text'>{item?.label}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Medicine_Product