import React, { useEffect, useState, useRef } from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import {
  appLeftBanner,
  appLoginRightBanner,
  appPhoneIcon,
  appSignInIcon,
} from "../images";
import OTPInput, { ResendOTP } from "otp-input-react";
import { Toaster, toast } from "react-hot-toast";
import { generate_otp, verify_login_otp } from "../Redux/actions/PostApiCall";
import { useDispatch, useSelector } from "react-redux";
import { validateNumberInput } from "../utils/AllFunction";
import ReCAPTCHA from "react-google-recaptcha";
import { DEFAULT_CAPTCHA_KEY, NEW_WEB_URL } from "../Constants";
import { HiArrowSmRight } from "react-icons/hi";

const OtpForm = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [toggleForm, setToggleForm] = useState(null);
  const dispatch = useDispatch();
  const recaptchaRef = useRef(null);
  const [verifyloading, setVerifyLoading] = useState(false)

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      template: "login",
    },
  });

  const mobile_number = watch("mobile_number");
  const captchaToken = watch("g-recaptcha-response");

  const generate_otp_response = useSelector(
    (state) => state?.generate_otp_response
  );

  const handleResend = () => {
    setToggleForm(null);
    setValue('g-recaptcha-response', null)
  };

  const onSubmit = (data) => {
    dispatch(generate_otp(data));
  };

  const handleEditClick = () => {
    setToggleForm(null);
    setValue('g-recaptcha-response', null)
  };

  const onCaptchaChange = (value) => {
    setValue("g-recaptcha-response", value);
  };

  const verifyOtp = (packageFormData) => {
    setVerifyLoading(true);
    fetch(`${NEW_WEB_URL}/api/verify-web-otp`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(packageFormData),
    })
      .then(response => {
        const authorizationToken = response.headers.get("Authorization");
        localStorage.setItem('APP_TOKEN', authorizationToken);
        
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.success) {
          setVerifyLoading(false);
          toast.success(data?.message);
        }
        setTimeout(() => {
          navigate('/home');
          setToggleForm(null);
        }, 800)
      })
      .catch(error => {
        console.error('Error verifying package OTP:', error);
        setVerifyLoading(false);
        // Handle the error
      });
  }
  const handleVerifyOtpClick = async () => {
    const verifyData = {
      template: 'login',
      otp: otp,
      mobile_number: mobile_number
    }
    await verifyOtp(verifyData);
    if(recaptchaRef?.current){
      recaptchaRef.current.reset();
    }
  };

  useEffect(() => {
    if (generate_otp_response?.data?.success) {
      setToggleForm(true);
    } else if (generate_otp_response?.status === "failure") {
      toast.error(generate_otp_response?.error);
      if(recaptchaRef?.current){
        recaptchaRef.current.reset();
      }
    }
  }, [generate_otp_response]);

  return (
    <div className="home-review-banner">
      <div className="login-form-left-banner">
        <img src={appLoginRightBanner} alt="" />
      </div>
      <div className="Login-form">
        {toggleForm ? (
          <div className="otp-form-section">
            <div className="login-form-icon">
              <img src={appPhoneIcon} alt="" />
              <p>OTP VERIFICATION</p>
            </div>
            <div className="otep-detail-text">
              <p>
                Please enter verification code (OTP) sent <br /> to +91{" "}
                {mobile_number} (
                <span>
                  <button
                    className="d-inline"
                    onClick={() => handleEditClick(mobile_number)}
                  >
                    Edit
                  </button>
                </span>
                )
              </p>
            </div>
            <div className="otp-section-boxs">
              <div className="Otp-box-from">
                <OTPInput
                  value={otp}
                  onChange={setOtp}
                  autoFocus
                  OTPLength={6}
                  otpType="number"
                  disabled={false}
                  className="otp-input-repo"
                />
              </div>
              <div className="resend-otp">
                <ResendOTP
                  className="get-otp-data"
                  onResendClick={() => {
                    handleResend();
                  }}
                />
              </div>
              <br />
              <button
                type="button"
                className="verify-otp-repo-btn"
                onClick={handleVerifyOtpClick}
                disabled={otp.length < 6}
              >
                {verifyloading ? "Verifying..." : "VERIFY"}
              </button>
            </div>
          </div>
        ) : (
          <div className="login-form-section-repo">
            <div className="login-form-icon">
              <img src={appSignInIcon} alt="" />
              <p>SIGN IN</p>
            </div>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group
                controlId="validationCustom01"
                className="Login-form-repo"
              >
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control
                  {...register("mobile_number", {
                    required: "Mobile number is required",
                    maxLength: {
                      value: 10,
                      message: "Maximum 10 digits allowed",
                    },
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Only numbers are allowed",
                    },
                  })}
                  onInput={validateNumberInput}
                  type="text"
                  maxLength={10}
                  placeholder="Enter Mobile Number"
                />
                {errors.mobile_number && (
                  <p className="error-message">
                    {errors.mobile_number.message}
                  </p>
                )}
              </Form.Group>
              <input type="hidden" {...register("template")} />
              <div className="check-box-repo">
                <Form.Check aria-label="option 1" />
                <p>
                  I have read and agreed the{" "}
                  <NavLink to="/terms-conditions">Terms & Conditions</NavLink>{" "}
                  and <NavLink to="/privacy-policy">Privacy Policy</NavLink>
                </p>
              </div>
              <div className="recaptch-cover-wrapper mt-2 mb-2">
                <ReCAPTCHA
                  sitekey={DEFAULT_CAPTCHA_KEY}
                  onChange={onCaptchaChange}
                  ref={recaptchaRef}
                />
                <input
                  type="hidden"
                  {...register("g-recaptcha-response", {
                    required: "reCAPTCHA is required",
                  })}
                />
                {errors["g-recaptcha-response"] && (
                  <span className="auth-error-msg">
                    {errors["g-recaptcha-response"].message}
                  </span>
                )}
              </div>
              <button
                className="get-repo-btn"
                disabled={!captchaToken}
                type="submit"
              >
                {generate_otp_response.loader ? "Please Wait..." : "Get OTP"}
              </button>
            </Form>

            <Link to={'/sign-up'} className="form-for-sign-up">
              <button>Sign-up with promo code <HiArrowSmRight /></button>
            </Link>
          </div>
        )}
      </div>
      <div className="login-form-right-banner">
        <img src={appLeftBanner} alt="" />
      </div>
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          className: "custom-toast",
          duration: 2000,
          style: {
            background: "#fff",
            color: "#363636",
            fontSize: "14px",
            width: "300px",
            top: "55px !important",
          },
        }}
      />
    </div>
  );
};

export default OtpForm;
