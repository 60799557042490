// src/hooks/useFetchMedicine.js
import { useQuery } from "@tanstack/react-query";

const pincode = localStorage.getItem("pincode");
const AppToken = localStorage.getItem("APP_TOKEN"); // Assuming the token is stored in localStorage

// Fetch medicine categories with token in headers
const fetchMedicine = async () => {
  const response = await fetch(
    "https://wellness.medibhai.co.in/api/medicine/medicine-category-details",
    {
      method: "GET",
      headers: {
        Authorization: AppToken, // Include token in Authorization header
        "Content-Type": "application/json", // Set Content-Type header
      },
    }
  );

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};

// Fetch medicine list with token in headers
const fetchMedicineList = async (medicineName) => {
  const response = await fetch(
    `https://wellness.medibhai.co.in/api/medicine/medicine-list?pincode=${pincode}&medicine_name=${medicineName}`,
    {
      method: "GET",
      headers: {
        Authorization: AppToken, // Include token in Authorization header
        "Content-Type": "application/json", // Set Content-Type header
      },
    }
  );

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};

// Hook to fetch medicine categories
const useFetchMedicine = () => {
  return useQuery({
    queryKey: ["fetchMedicine"],
    queryFn: fetchMedicine,
    staleTime: 86400000, // 24 hours
    cacheTime: 86400000, // 24 hours
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
};

// Hook to fetch medicine list by name
const useFetchMedicineList = (medicineName) => {
  return useQuery({
    queryKey: ["fetchMedicineList", medicineName],
    queryFn: () => fetchMedicineList(medicineName),
    staleTime: 86400000, // 24 hours
    cacheTime: 86400000, // 24 hours
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    enabled: !!medicineName, // Only run the query if medicineName is provided
  });
};

export { useFetchMedicine, useFetchMedicineList };
