import { NavLink, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { handleNavigateSearch } from "../../utils/AllFunction";
import Loader from "../../Animation/Loader";
import NoDataFoundComp from "../../components/NoDataFound";
import { useFetchTestCategories } from "../../QueryClient/DiagnosticQuery";
import AppHeader from "../Medicine/Components/Medicine_Header";
import { IoIosSearch } from "react-icons/io";
import { fetchDiagnosticCartDetails , fetchCategoriesTestList } from "../../Redux/actions/GetApiCall";
import { useDispatch, useSelector } from "react-redux";
import AppFooter from "../Medicine/Components/Medicine_footer";

const CategoriesPage = () => {

  const cartDetailsResponse = useSelector(
    (state) => state.diagnostic_cart_details
  );
  const categories_test_list_response = useSelector(state =>state.categories_test_list_response);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tabKey = "diagnostics"

  useEffect(() => {
    dispatch(fetchDiagnosticCartDetails());
    dispatch(fetchCategoriesTestList())
  }, [dispatch]);

  return (
    <>
      <div className="home-review-banner">
        <div className="lab-book-categories">
          <AppHeader Title="Select Test by Categories" />
          <div className="app-searh-section">
            <div className="inputWithIcon">
              <input
                type="text"
                placeholder="Search"
                onClick={() => handleNavigateSearch(navigate)}
              />
              <button>
                <IoIosSearch />
              </button>
            </div>
          </div>
          <div className="Test-categories-content-wrapper-box">
            <div className="container">
              <div className="row d-flex justify-content-center">
                {categories_test_list_response?.loader ? (
                  <Loader />
                ) : categories_test_list_response?.data?.data?.length > 0 ? (
                  categories_test_list_response?.data?.data.map((test) => (
                    <div
                      className="col-lg-5 col-md-5 col-5 book-categories-repo"
                      key={test.cat_id}
                    >
                      <NavLink
                        to={`/test-by-categories/${test.id}`}
                      >
                        <div className="lab-book-categories-box">
                          <img
                            src={`${test.cart_icon}`}
                            width="100%"
                            alt="review"
                          />
                          <p>{test?.name}</p>
                        </div>
                      </NavLink>
                    </div>
                  ))
                ) : (
                  <NoDataFoundComp />
                )}
              </div>
            </div>
          </div>
          {cartDetailsResponse &&
            (cartDetailsResponse?.data?.response?.tests?.length > 0 ||
              cartDetailsResponse?.data?.response?.tests?.packages > 0) && (
              <AppFooter
                labCartData={cartDetailsResponse?.data.response}
                tabkey={tabKey}
              />
            )}
        </div>
      </div>
    </>
  );
};

export default CategoriesPage;
