import React, { useState, useEffect } from 'react';
import CartLoader from '../animation/CartLoader';
import { no_preview, px_Image } from '../Images';
import { FaStar } from 'react-icons/fa6';
import { useDispatch, useSelector } from 'react-redux';
import { medicineCartAdd, medicineCartDelete } from '../../../Redux/actions/PostApiCall';
import { fetchMedicineCartList, fetchMedicinelist } from '../../../Redux/actions/GetApiCall';
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import useCartFunction from '../hooks/useCartFunction';

const Medicine_Card = ({ mediData, corner, inputPara, setLoaderStatus, setCartListData }) => {
    const navigate = useNavigate();
    const ListStatus = useSelector(state => state.medicine_list.status);
    const cartList = useSelector(state => state.medicine_cart_list.data);

    const { availability, cartStatus, cart, CartData, DeleteStatus, loadingItems, handleCartAdd, handleIncrement, handleDecrement, handleDelete } = useCartFunction(inputPara,setLoaderStatus,setCartListData);

    const hanldeDescription = (item) => {
        const data = {
            Title: "Product description",
            data: item
        };
        navigate('/medicine-description', { state: data });
    };



    return (
        <>
            {mediData && mediData?.data?.map((item, index) => {
                // Find the cart item for this product
                const cartItem = cart.find(cartItem => cartItem.sku_id === item.sku_id);

                return (
                    <div
                        className={corner === "data" ? "col-lg-6 col-md-6 col-6 repo-corner-col" : corner === "category" ? "category-repo-corner-col" : "recent-product-data-repo-cover"}
                        key={index}
                        onClick={() => hanldeDescription(item)}
                    >
                        <div key={item?.id} className={corner === "category" ? "category-repo-data-box" : "corner-data-box"}>
                            {item?.prescription_required === 1 && (
                                <div className="px-cover-repo">
                                    <img src={px_Image} alt="px-image" />
                                </div>
                            )}
                            <div className="image-cover-recent">
                                <img
                                    src={item?.banner_url ? item?.banner_url : no_preview}
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = no_preview;
                                    }}
                                    alt="product-icon"
                                />
                            </div>
                            <p className='recent-ellipsis-text'>{item?.name}</p>
                            <p className='pack-box-text'>{item?.label}</p>
                            <div className="price-repo-text">
                                <p>{item?.prices?.discounted_price !== null ? item?.prices?.discounted_price : item?.prices?.mrp}</p>
                                <span>{item?.prices?.discounted_price !== null && item?.prices?.mrp}</span>
                            </div>
                            <div className="offer-recent-text-box">
                                <p>{item?.prices?.discount}</p>
                                {item?.average_rating !== '' && (
                                    <span><FaStar />{item?.average_rating}</span>
                                )}
                            </div>
                            <div className="line-repo"></div>
                            <div className="qty-repo-box">
                                <p>Min. Qty : {item?.min_quantity}</p>
                            </div>
                            <div className="add-product-box">
                                {(loadingItems.has(item.sku_id) && cartStatus === 'loading') || (loadingItems.has(item.sku_id) && DeleteStatus === "loading" && ListStatus === "loading") ? (
                                    <CartLoader />
                                ) : (
                                    <div className="add-product-box">
                                        {(loadingItems.has(item.sku_id) && cartStatus === 'loading') || (loadingItems.has(item.sku_id) && DeleteStatus === "loading") ? (
                                            <CartLoader />
                                        ) : (
                                            <div className="add-cart-repo-boxs">
                                                {availability[item.sku_id] === 0 || item?.is_available === 0 || (item?.min_quantity === 0 && item?.max_quantity === 0) ? (
                                                    <div className="out-of-stock">
                                                        <p>Out of Stock</p>
                                                    </div>
                                                ) : (cartItem || (item?.in_cart === 1 && item?.is_available === 1 && item?.quantity !== 0)) ? (
                                                    <div className="increase-cart-repo-boxs">
                                                        <button
                                                            className={`incre-btn-reop ${cartItem && cartItem.quantity <= item.min_quantity ? 'border-red' : ''}`}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                if (cartItem && cartItem.quantity <= item.min_quantity) {
                                                                    handleDelete(item.sku_id);
                                                                } else {
                                                                    handleDecrement(item.sku_id);
                                                                }
                                                            }}
                                                        >
                                                            <p>-</p>
                                                        </button>

                                                        {CartData?.data?.some(count => count.sku_id === item.sku_id) ? (
                                                            CartData.data.filter(count => count.sku_id === item.sku_id).map((count, idx) => (
                                                                <span key={idx}>
                                                                    {count.quantity}
                                                                </span>
                                                            ))
                                                        ) : (
                                                            <span>
                                                                {item?.quantity}
                                                            </span>
                                                        )}
                                                        <button
                                                            className='incre-btn-reop'
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                if (cartItem && cartItem.quantity < item.max_quantity) {
                                                                    handleIncrement(item.sku_id);
                                                                } else {
                                                                    toast.error("Max Reached");
                                                                }
                                                            }}
                                                        >
                                                            <p>+</p>
                                                        </button>
                                                    </div>
                                                ) : (
                                                    <button
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleCartAdd(item);
                                                        }}
                                                    >
                                                        <p>ADD</p>
                                                    </button>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                );
            })}
            <Toaster
                position="top-center"
                reverseOrder={false}
                gutter={8}
                containerClassName=""
                containerStyle={{}}
                toastOptions={{
                    className: "",
                    duration: 2700,
                    style: {
                        background: "#fff",
                        color: "#363636",
                    },
                }}
            />
        </>
    );
};

export default Medicine_Card;
