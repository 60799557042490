import React from 'react'
import './loader-style.css'

const loader_anime = () => {

  return (
    <div className='input-load'>
      <div class="dot-spinner">
        <div class="dot-spinner__dot"></div>
        <div class="dot-spinner__dot"></div>
        <div class="dot-spinner__dot"></div>
        <div class="dot-spinner__dot"></div>
        <div class="dot-spinner__dot"></div>
        <div class="dot-spinner__dot"></div>
        <div class="dot-spinner__dot"></div>
        <div class="dot-spinner__dot"></div>
      </div>
      <p>Please Wait...</p>
    </div>
  )
}

export default loader_anime