import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { Nav, Row, Col, Tab } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import Loader from "../../Animation/Loader";
import WhyBookwithUs from "./components/WhyBookwithUs";
import RecommendedSection from "./components/RecommendedSection";
import DescriptionDetailBox from "./components/DescriptionDetailBox";
import { getpackageDescription , getDiagnosticPackageList , fetchDiagnosticCartDetails} from "../../Redux/actions/GetApiCall";
import { useDispatch , useSelector} from "react-redux";
import AppHeader from "../Medicine/Components/Medicine_Header"
import Appfooter from "../Medicine/Components/Medicine_footer"
import PackDescriptionButton from "../LabTestPages/components/PackDescriptionButtons"


import {
  ArrowBack,
  appFastingTime,
  appRecommended,
  appAge,
  appReports,
  appCart
} from "../../images";
import NoDataFoundComp from "../../components/NoDataFound";

const Package_Description = () => {

  const dispatch = useDispatch();
  const key = "diagnostics"
  const { pack_id } = useParams();
  const testDetailsData = useSelector((state) =>state?.getpackageDescription_response);
  const diagnostic_test_list_response = useSelector((state) =>state?.diagnostic_package_list_response);
  const cartDetailsResponse = useSelector(
    (state) => state.diagnostic_cart_details
  );

  useEffect(() =>{
    dispatch(getpackageDescription(pack_id));
    dispatch(getDiagnosticPackageList());
    dispatch(fetchDiagnosticCartDetails());
  },[pack_id]);

  return (
    <>
      <div className="home-review-banner">
        <AppHeader Title="Package Description" labCartCount={cartDetailsResponse?.data?.response?.cart_count}/>
        {testDetailsData?.loader ? (
          <Loader />
        ) : (
          <div className="package-details-main-cover-wrapper">
            <DescriptionDetailBox detailsData={testDetailsData} />
            <div className="description-tabs">
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row className="">
                  <Col lg={12}>
                    <Nav
                      variant="pills"
                      className="d-flex justify-content-center mb-2"
                    >
                      <Nav.Item>
                        <Nav.Link eventKey="first" className="description-tab">
                          Test Description
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second" className="description-tab">
                          Included Tests
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col lg={12}>
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        <>
                          {testDetailsData?.data?.data?.pack_description && (
                            <div className="test-description-content-box">
                              <p>{testDetailsData?.data?.data?.pack_description}</p>
                            </div>
                          )}
                          {testDetailsData?.data?.data[0]?.tests?.length > 0 && (
                              <div className="test-desc-tabs">
                                <div className="container">
                                  <div className="row">
                                    <div className="col-lg-6 desc-detail-box-repo">
                                      <div className="desc-cover-box">
                                        <div className="desc-image-box">
                                          <img
                                            src={appReports}
                                            width="100%"
                                            alt="review"
                                          />
                                        </div>
                                        <div className="desc-detail-box">
                                          <p>E-Reports</p>
                                          <p>
                                            {testDetailsData?.data?.data[0]?.reporting_time}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-6 desc-detail-box-repo">
                                      <div className="desc-cover-box">
                                        <div className="desc-image-box">
                                          <img
                                            src={appFastingTime}
                                            width="100%"
                                            alt="review"
                                          />
                                        </div>
                                        <div className="desc-detail-box">
                                          <p>Fasting Time</p>
                                          <p>
                                            {
                                              testDetailsData?.data?.data[0]?.fasting
                                            }
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-6 desc-detail-box-repo">
                                      <div className="desc-cover-box">
                                        <div className="desc-image-box">
                                          <img
                                            src={appRecommended}
                                            width="100%"
                                            alt="review"
                                          />
                                        </div>
                                        <div className="desc-detail-box">
                                          <p>Recommended</p>
                                          <p>
                                            for - {testDetailsData?.data?.data[0]?.gender}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-6 desc-detail-box-repo">
                                      <div className="desc-cover-box">
                                        <div className="desc-image-box">
                                          <img
                                            src={appAge}
                                            width="100%"
                                            alt="review"
                                          />
                                        </div>
                                        <div className="desc-detail-box">
                                          <p>Age</p>
                                          <p>{testDetailsData?.data?.data[0]?.age}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                          )}
                        </>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <div className="test-accordion-box">
                          <Accordion defaultActiveKey="0">
                            {testDetailsData?.data?.data[0].tests?.length > 0 ? (
                              testDetailsData?.data?.data[0].tests.map((elemdata, index) => {
                                return (
                                  <Accordion.Item key={index} eventKey={index}>
                                    <Accordion.Header>
                                      <h6>{elemdata?.name}</h6>
                                      <span className="test-count">
                                        {elemdata?.parameter_count}
                                      </span>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <div className="test-main-body">
                                        <ol>
                                          {elemdata?.parameters?.length >
                                            0 &&
                                            elemdata?.parameters.map(
                                              (data, index) => (
                                                <li key={index}>
                                                  {data?.name}
                                                </li>
                                              )
                                            )}
                                        </ol>
                                      </div>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                );
                              })
                            ) : (
                                <NoDataFoundComp />
                            )}
                          </Accordion>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
              <PackDescriptionButton packdescriptionId={pack_id} test={testDetailsData?.data?.data[0]} />
              <WhyBookwithUs/>
              <RecommendedSection testData={diagnostic_test_list_response?.data?.data} title="Packages"/>
            </div>
            
           { cartDetailsResponse &&
            (cartDetailsResponse?.data?.response?.tests?.length > 0 ||
              cartDetailsResponse?.data?.response?.packages > 0) && (
              <Appfooter
                labCartData={cartDetailsResponse?.data?.response}
                tabkey={key}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Package_Description;
