import React, { useContext, useEffect, useState } from 'react'
import { ArrowBackButton } from '../../utils/AllFunction'
import { ArrowBack, appBannerImg, appPlayButton, appVideoImage } from '../../images'
import { NavLink } from 'react-router-dom'
import { AppContext } from '../../ContextApi'
import { HOME_INDEX_API  , REQUESTOPTIONS} from '../../Constants'

const VideoBox = () => {
    const [videobox, setVideoBox] = useState([]);
    const [homeindexdata, setHomeIndexData] = useState(null);
    const { videodetaildata, setVideoDetailData } = useContext(AppContext)
    const token = localStorage.getItem("ACCESS_TOKEN");

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (!token) {
                    console.error("Token is missing");
                    return;
                }
                const [homeIndexRes] = await Promise.all([
                    fetch(HOME_INDEX_API, REQUESTOPTIONS)
                ]);

                if (!homeIndexRes.ok) {
                    console.error('API error:', homeIndexRes.status, homeIndexRes.statusText);
                    const errorData = await homeIndexRes.json().catch(() => null);
                    console.error('Error details:', errorData);
                    throw new Error('Network response was not ok');
                }

                const [indexData] = await Promise.all([
                    homeIndexRes.json()
                ]);

                setHomeIndexData(indexData);
                setVideoBox(indexData?.videos);
                setVideoDetailData(indexData?.videos?.videos);

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [token]);
    return (
        <>
            <div className="home-review-banner">
                <div className="app-header-wraper video-box-repo">
                    <div className="header-top-section">
                        <div className="header-top-section-category-section">
                            <h3>Health Talk</h3>
                        </div>
                        <div className="header-top-section-add-to-cart-section">
                        </div>
                    </div>
                </div>

                <div className="video-box-wrapper">
                    {videobox.videos && videobox.videos.map((videoData, ind) => (
                        <div className="video-box-section">
                            <NavLink to={`/videodetailbox/${ind}`}>
                                <div className="video-box-image">
                                    <div className="video-image-repo" style={{ backgroundImage: `url(${videoData.icon})` }}>
                                        {/* <img src={videoData.icon} alt="" /> */}
                                    </div>
                                    <div className="video-btns-repo">
                                        <img src={appPlayButton} alt="" />
                                        <p>{videoData.video_time}</p>
                                    </div>

                                </div>
                                <div className="video-header">
                                    <p>{videoData.title}</p>
                                </div>
                                <hr />
                            </NavLink>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default VideoBox