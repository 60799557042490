import React, { useState, useEffect } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { useForm, Controller } from "react-hook-form";
import { validateNumberInput } from "../../utils/AllFunction";
import { Form, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  REQUESTOPTIONS,
  HOME_INDEX_API,
  DOCTOR_NOT_FOUND_API,
} from "../../Constants";
import Select from "react-select";
import SuccessFull from "../../Animation/SuccessFull";
import No_Doctor_Found from "../../Animation/No_Doctor_Found";

const DoctorNotFoundForm = () => {
  const {
    handleSubmit,
    control,
    register,
    setValue,
    formState: { errors },
  } = useForm();

  const [specialistData, setSpecialistData] = useState(null);
  const [optionsWithAttribute, setOptionsWithAttribute] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [successmodal, setSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [modalShow , setModalShow] = useState(false)

  const navigate = useNavigate();

  useEffect(() => {
    const OpdspecialList = async () => {
      try {
        const response = await fetch(`${DOCTOR_NOT_FOUND_API}`, REQUESTOPTIONS);
        const res = await response.json();
        if (res.status) {
          const mappedOptions =
            res.department.departments?.map((state) => ({
              value: state.name,
              label: state.name,
              id: state.id,
            })) || [];
          res.department.departments?.map((state) => {
            console.log(state.id);
          });
          setOptionsWithAttribute(mappedOptions);
          setSpecialistData(res?.department?.departments);
        }
      } catch (error) {
        console.log(error);
      }
    };

    OpdspecialList();
  }, []);

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const openModal = () => {
    setShow(true);
  };

  const onSubmit = (data) => {
      const formData = new FormData();
      formData.append("name", data.doctorName);
      formData.append("department_name", selectedOption.id);
      formData.append("location", data.location);
      formData.append("pincode", data.pincode);
      formData.append("mobile_no", data.phoneNumber);
      formData.append("type", "doctor");
      formData.append("name", data.doctorName);

      const requestOptionsCopy = { ...REQUESTOPTIONS };
      requestOptionsCopy.method = "POST";
      requestOptionsCopy.body = formData;
      requestOptionsCopy.processData = false;
      requestOptionsCopy.contentType = false;

      async function submit_opd_Booking() {
        try {
          const response = await fetch(DOCTOR_NOT_FOUND_API,requestOptionsCopy
          );
          const res = await response.json();
          if (res.status === 200) {
              setSuccessMessage(res.message)
              setSuccessModal(true)
              setModalShow(true)
              
              setTimeout(() =>{
                navigate("/in-personconsultation")
                setSuccessModal(false)
                setShow(false)
              },2000)
          }
        } catch (error) {
          // Handle network errors or other exceptions
          console.error(error);
        }
      }

      submit_opd_Booking();
    };

    
    const customStyles = {
    control: (provided) => ({
      ...provided,
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
      borderRadius: "8px",
      border: "0.5px solid #70d3ff",
      padding: "0px 3px",
      fontSize: "13px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#36a2eb" : "white",
      color: state.isSelected ? "white" : "black",
      borderRadius: "5px",
      zIndex: 999,
      fontSize: "13px",
    }),
  };

  return (
    <>
      <div className="doctor-not-found-main-cover-wrapper">
        <div className="doctor-not-found-title-box">
          <p>Could n't find your doctor.Please fill up the information here </p>
          <IoIosArrowDown onClick={() => openModal()} />
        </div>

        <No_Doctor_Found/>
        
        <div className="doctor-modal-not-found-main-cover-wrapper">
          <Modal
            show={show}
            onHide={handleClose}
            className="book-now-modal-main-wrapper"
          >
            <Modal.Header closeButton className="book-now-header">
              <Modal.Title>
                <h6>Enter Person Details</h6>
                <p className="book-now-title-description">
                  Dentalconsultation Will be Conduct for this person
                </p>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="book-now-modal-body">
              <div className="book-now-form-main-cover">
                <div className="book-now-form">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-3 book-now-input-box">
                      <label>Doctor</label>
                      <input
                        type="text"
                        placeholder="Enter Doctor Name"
                        {...register("doctorName", {
                          required: "Please enter your Doctor name",
                        })}
                      />
                      <span className="text-danger validation-error">
                        {errors.doctorName && errors.doctorName.message}
                      </span>
                    </div>
                    <div className="mb-3 book-now-input-box">
                      <label>Select Department</label>
                      <Select
                        className="mb-3"
                        options={optionsWithAttribute}
                        isSearchable={true}
                        placeholder="Select specialist"
                        styles={customStyles}
                        value={selectedOption}
                        onChange={(selectedOption) =>
                          setSelectedOption(selectedOption)
                        }
                      />
                      <span className="text-danger validation-error">
                        {errors.patientAge && errors.patientAge.message}
                      </span>
                    </div>
                    <div className="mb-3 book-now-input-box">
                      <label>Location</label>
                      <input
                        type="text"
                        placeholder="Enter Location"
                        {...register("location", {
                          required: "Please enter your Location",
                          pattern: {
                            message: "Please enter a valid age",
                          },
                        })}
                        // defaultValue={patientAge}
                        // onChange={(e) => {
                        //   setPatientAge(e.target.value);
                        // }}
                      />
                      <span className="text-danger validation-error">
                        {errors.location && errors.location.message}
                      </span>
                    </div>
                    <Form.Group
                      className="mb-3 book-now-input-box"
                      controlId="exampleForm.ControlInput1"
                    >
                      <label>Pincode</label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Pincode"
                        {...register("pincode", {
                          required: "Please enter your Pincode",
                        })}
                        // onChange={(e) => setPatientMobile(e.target.value)}
                        maxLength={7}
                        onInput={(e) => validateNumberInput(e)}
                      />
                      <span className="text-danger validation-error">
                        {errors.location && errors.location.message}
                      </span>
                    </Form.Group>
                    <Form.Group
                      className="mb-3 book-now-input-box"
                      controlId="exampleForm.ControlInput1"
                    >
                      <label>Call Back Number</label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Mobile Number"
                        {...register("phoneNumber", {
                          required: "Please enter your Mobile number",
                          pattern: {
                            value: /^[0-9]{10}$/, // Regular expression for a 10-digit phone number
                            message: "Please enter your Mobile number",
                          },
                        })}
                        // onInput={(e) =>validateNumberInput(e)}
                      />
                      <span className="text-danger validation-error">
                        {errors.phoneNumber && errors.phoneNumber.message}
                      </span>
                    </Form.Group>
                    <div className="submit-btn-box d-flex justify-content-center">
                      <button type="submit">Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>


        {successmodal && (
          <SuccessFull show={modalShow} successMessage={successMessage} />
        )}
      </div>
    </>
  );
};

export default DoctorNotFoundForm;
