import React, { useEffect, useState } from 'react'
import { headerLogo, NoWallet, RemiburmentIamge } from '../../../images'
import { REIMBURMENT_API, REQUESTOPTIONS } from '../../../Constants'

const ReimbursmentDetails = () => {

    const [detailsData, setDetailsData] = useState([])

    const fetchReimbursment = async () => {
        try {
            const ResData = await fetch(`${REIMBURMENT_API}`, REQUESTOPTIONS)
            const Data = await ResData.json();
            if (Data?.status === true) {
                setDetailsData(Data?.data)
            }
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        console.log(detailsData)
    }, [detailsData])

    useEffect(() => {
        fetchReimbursment();
    }, [])

    return (
        <div>
            <div className="home-review-banner">
                <div className="home-app-header">

                    <div className="header-logo">
                        <img src={headerLogo} width="100%" alt="header-logo" />
                        <div className="user-name">
                            <p>Reimbursement Status</p>
                        </div>
                    </div>

                </div>

                <>
                    <div className="my-wallet-utilization-main-wrapper-cover reimburse-data-repo">
                        <>
                            <>
                                <div className="wallet-categories-section">
                                    <div className="wallet-categories-main-box-wrapper">
                                        {detailsData?.length !== 0 && (
                                            <div className="trans-text-box">
                                                <h5>Transistion : </h5>
                                            </div>
                                        )}

                                        {detailsData.length === 0 ? (
                                            <div className="no-data-found-reim">
                                                <img src={RemiburmentIamge} alt="Reimbursement-icon" />
                                                <p>No Reimbursement Found</p>
                                            </div>
                                        ) : (
                                            detailsData?.map((item) => (
                                                <div className="wallet-categories-box-wrapper mb-3" key={item?.id}>
                                                    <div className="wallet-categories-content-box">
                                                        <div className="pack-accord-btn">
                                                            <div className="wallet-categories-content-box-cover">
                                                                <p>
                                                                    Category: <span className="reim-text">{item?.lead_type}</span>
                                                                </p>
                                                                <p>
                                                                    Claim Date: <span>{item?.order_date_format}</span>
                                                                </p>
                                                                <p>
                                                                    Claim Amount: <span className="-reim-point">{item?.amount}</span>
                                                                </p>
                                                                <div className="points-text-border"></div>
                                                                {item?.settled_at_format !== null && (
                                                                    <p>
                                                                        Date of Settlement: <b>{item?.settled_at_format}</b>
                                                                    </p>
                                                                )}
                                                                {item?.settled_amount !== 0 && (
                                                                    <p>
                                                                        Settled Amount: <b className='-reim-point'>{item?.settled_amount}</b>
                                                                    </p>
                                                                )}
                                                                <p>
                                                                    Status: <b style={{ color: item?.color_code }}>{item?.status_name}</b>
                                                                </p>
                                                                {item?.remark !== "" && (
                                                                    <p>
                                                                        Remark: <span>{item?.remark}</span>
                                                                    </p>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        )}


                                    </div>
                                </div>

                            </>
                        </>
                    </div>
                </>
            </div>
        </div>
    )
}

export default ReimbursmentDetails