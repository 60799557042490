import React, { useEffect, useState } from 'react'
import { Tab, Row, Col, Nav } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { NavLink, useNavigate } from "react-router-dom";
import { Navigation } from 'swiper/modules';
import '../style/orderDetails.css'
import { no_preview } from '../../Medicine/Images';
import Order_Card from './Order_Card';
import { useDispatch, useSelector } from 'react-redux';
import { fetchorderhistory } from '../../../Redux/actions/GetApiCall';
import MediLoader from '../../Medicine/animation/MediLoader';

const OrderDetailsPage = () => {
    // const navigate = useNavigate();
    const dispatch = useDispatch();
    const orderHistoryData = useSelector(state => state.order_history);
    console.log(orderHistoryData)
    console.log(orderHistoryData?.data?.data)
    const [key, setKey] = useState("first");

    useEffect(() => {
        dispatch(fetchorderhistory());
    }, [])

    const height = "75vh"

    return (
        <div className="home-review-banner">
            <div className="home-app-header order-details-repo-data">

                <div className="header-logo ">
                    <div className="user-name text-center text-repoo">
                        <p>My Order Details</p>
                    </div>
                </div>
            </div>

            <div className="tab-main-cover-wrapper">
                <Tab.Container id="left-tabs-example-repo-cont" defaultActiveKey={key}>
                    <Row>
                        <Col lg={12} className="mb-2 tab-cover order-details-tab">
                            <Swiper
                                spaceBetween={10}
                                slidesPerView={2.5}
                                // navigation={true}
                                pagination={{ clickable: true }}
                                modules={[Navigation]}
                                className="order-tab-swiper"
                            >
                                <SwiperSlide>
                                    <Nav variant="pills" className="flex-column">
                                        <Nav.Item>
                                            <Nav.Link
                                                eventKey="first"
                                                onSelect={() => setKey("first")}
                                            >
                                                Medicine Order
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Nav variant="pills" className="flex-column">
                                        <Nav.Item>
                                            <Nav.Link
                                                eventKey="second"
                                                onSelect={() => setKey("second")}
                                            >
                                                Lab Tests
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Nav variant="pills" className="flex-column">
                                        <Nav.Item>
                                            <Nav.Link
                                                eventKey="third"
                                                onSelect={() => setKey("third")}
                                            >
                                                Teleconsultation
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </SwiperSlide>

                            </Swiper>
                        </Col>

                        {/* <div className='search-data-input-box'>
                            <input type="text" placeholder='Search your orders' />
                        </div> */}

                        <Col lg={12}>
                            <>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <div className="medicine-cover-data-wrapper-boxs-cover">
                                            {orderHistoryData && orderHistoryData?.status==="loading" ? (
                                                <MediLoader height={height}/>
                                            ):(
                                            <div className="medicine-order-details-cover">
                                                <Order_Card OrderData={orderHistoryData} />
                                            </div>
                                            )}
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">

                                    </Tab.Pane>
                                    <Tab.Pane eventKey="third">

                                    </Tab.Pane>
                                </Tab.Content>
                            </>
                        </Col>
                    </Row>
                </Tab.Container>
            </div>
        </div>
    )
}

export default OrderDetailsPage