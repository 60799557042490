import React, { useEffect } from "react";
import { patientEdit, patientDelete, appPackageIcon } from "../../../images";
import { deletePatient } from "../../../Redux/actions/PostApiCall";
import { fetchPatients } from "../../../Redux/actions/GetApiCall";
import { useDispatch, useSelector } from "react-redux";
import { Toaster, toast } from "react-hot-toast";

const PatientCardDetails = ({
  setPatientCheckedData,
  cartDetailsList,
  patientsList,
  handleEditPatient,
  patientCheckedData,
}) => {
  const patientResponse = useSelector((state) => state.delete_patient_members);
  const dispatch = useDispatch();

  const handleDeletepatient = async (patientData) => {
    await dispatch(deletePatient(patientData));
    await dispatch(fetchPatients());
  };

  useEffect(() => {
    if (patientResponse?.status === "success") {
      toast.success(patientResponse?.data?.message);
    } else if (patientResponse?.status === "failure") {
      toast.error(patientResponse?.data?.message);
    }
  }, [patientResponse]);

  const handlePatientCheckbox = (e, patientData) => {
    if (e.target.checked) {
      // Checkbox is checked
      setPatientCheckedData((prev) => {
        // Check if the patient already exists in the data
        const existingPatientIndex = prev.data.findIndex(
          (pat_data) => pat_data.member_id === patientData?.id
        );
  
        if (existingPatientIndex !== -1) {
          // If the patient already exists, update their test_id and package_id arrays
          const updatedData = [...prev.data]; // Create a shallow copy of the data array
          const existingPatient = updatedData[existingPatientIndex];
  
          // Update or initialize test_id and package_id arrays as needed
          existingPatient.test_id = existingPatient.test_id || [];
          existingPatient.package_id = existingPatient.package_id || [];
  
          return { data: updatedData };
        } else {
          // If the patient does not exist, add a new entry
          return {
            data: [
              ...prev.data,
              {
                member_id: patientData?.id,
                test_id: [],
                package_id: [],
              },
            ],
          };
        }
      });
    } else {
      // Checkbox is unchecked
      setPatientCheckedData((prev) => {
        // Remove the patient from the data
        return {
          data: prev.data.filter(
            (pat_data) => pat_data.member_id !== patientData?.id
          ),
        };
      });
    }
  };
  
  const handleMemberCheckbox = (e, cartData, patientData, type) => {
    if (e.target.checked) {
      setPatientCheckedData((prev) => {
        const existingPatientData = prev.data.find(
          (patient) => patient.member_id === patientData?.id
        );
        if (existingPatientData) {
          return {
            data: prev.data.map((patient) => {
              if (patient.member_id === patientData?.id) {
                return {
                  ...patient,
                  [type === "tests" ? "test_id" : "package_id"]: [
                    ...patient[type === "tests" ? "test_id" : "package_id"],
                    cartData.id,
                  ],
                };
              }
              return patient;
            }),
          };
        } else {
          // Update the existing patient data if it's not present
          const updatedData = prev.data.map((patient) => {
            if (patient.member_id === patientData?.id) {
              return {
                ...patient,
                [type === "tests" ? "test_id" : "package_id"]:
                  type === "tests" ? [cartData.id] : [cartData.id],
              };
            }
            return patient;
          });
          return { data: updatedData };
        }
      });
    } else {
      setPatientCheckedData((prev) => {
        return {
          data: prev.data.map((patient) => {
            if (patient.member_id === patientData?.id) {
              return {
                ...patient,
                [type === "tests" ? "test_id" : "package_id"]: patient[
                  type === "tests" ? "test_id" : "package_id"
                ].filter((id) => id !== cartData.id),
              };
            }
            return patient;
          }),
        };
      });
    }
  };

  useEffect(() => {
    // Extract test and package IDs from cartDetails
    const cartTestIds = new Set(cartDetailsList?.data?.response?.tests.map((test) => test.id));
    const cartPackageIds = new Set(cartDetailsList?.data?.response?.packages.map((pkg) => pkg.id));
    
    const updatedPatientData = [...(patientCheckedData?.data || [])];

    if(patientsList){
        patientsList?.data?.response?.forEach((patientData) => {
          patientData?.test_id?.forEach((pat_data) => {
            if (pat_data) {
              const isTestIdInCart = cartTestIds.has(pat_data);
          
              if (isTestIdInCart) {
                // Check if patient already exists in updatedPatientData
                const existingPatientIndex = updatedPatientData.findIndex(
                  (existingData) => existingData.member_id === patientData.id
                );
          
                if (existingPatientIndex !== -1) {
                  // Patient already exists, check if test_id is already in the array
                  const testIdExists = updatedPatientData[existingPatientIndex].test_id.includes(pat_data);
          
                  if (!testIdExists) {
                    // Only push if test_id is not already in the array
                    updatedPatientData[existingPatientIndex].test_id.push(pat_data);
                  }
                } else {
                  // Patient does not exist, add a new entry
                  updatedPatientData.push({
                    member_id: patientData.id,
                    test_id: [pat_data], // Initialize test_id array with current test_id
                    package_id: [],
                  });
                }
              }
            }
          });
          
          patientData?.lab_package_id?.forEach((pat_data) => {
            if (pat_data) {
              const isPackageIdInCart = cartPackageIds.has(pat_data);
          
              if (isPackageIdInCart) {
                // Check if patient already exists in updatedPatientData
                const existingPatientIndex = updatedPatientData.findIndex(
                  (existingData) => existingData.member_id === patientData.id
                );
          
                if (existingPatientIndex !== -1) {
                  // Patient already exists, check if package_id is already in the array
                  const packageIdExists = updatedPatientData[existingPatientIndex].package_id.includes(pat_data);
          
                  if (!packageIdExists) {
                    // Only push if package_id is not already in the array
                    updatedPatientData[existingPatientIndex].package_id.push(pat_data);
                  }
                } else {
                  // Patient does not exist, add a new entry
                  updatedPatientData.push({
                    member_id: patientData.id,
                    test_id: [],
                    package_id: [pat_data], // Initialize package_id array with current package_id
                  });
                }
              }
            }
          });
          
        });
    }
    // Set the updated patient data
    setPatientCheckedData({ data: updatedPatientData });
  }, [patientsList, cartDetailsList]);
  
  
  

  return (
    <div className="patient-card-details-box">

      {(cartDetailsList?.data?.response?.tests?.length > 0 || cartDetailsList?.data?.response?.packages?.length > 0 ) && patientsList?.data?.response?.length > 0 &&
        patientsList?.data?.response?.map((patientData) => {

          const isMemberSelected = patientCheckedData?.data.some(
                  (data) => data.member_id === patientData?.id
                );

                console.log(isMemberSelected)

          {/* const isTestChecked = isMemberSelected && patientCheckedData?.data
          .find((data) => data.member_id === patientData?.id)
          ?.test_id.some((t_data) => patientData?.test_id.includes(t_data)); 

          const isPackageChecked = isMemberSelected && patientCheckedData?.data
            .find((data) => data.member_id === patientData?.id)
            ?.package_id.some((p_data) => patientData?.lab_package_id.includes(p_data)); */}

                
          return (
            <div className="patient-detail-box">
              <div className="patient-main-detail">
                <div className="patient-detail-content">
                  <p>{patientData?.name}</p>
                  <p>
                    {patientData?.gender}, {patientData?.age},{" "}
                    {patientData?.relation}
                  </p>
                </div>
                <div className="patient-buttons-box">
                  <button onClick={() => handleEditPatient(patientData)}>
                    <img src={patientEdit} width="100%" alt="review" />
                  </button>
                  <button
                    onClick={() =>
                      handleDeletepatient({ member_id: patientData?.id })
                    }
                  >
                    <img src={patientDelete} width="100%" alt="review" />
                  </button>
                  <input
                    type="checkbox"
                    className="main-checkbox form-check-input"
                    onChange={(e) => handlePatientCheckbox(e, patientData)}
                    checked={isMemberSelected}
                  />
                </div>
              </div>
              <hr />
              {cartDetailsList?.data?.response?.tests?.map((cartData) => {
                // Check if the member is selected
                const isMemberSelected = patientCheckedData?.data.some(
                  (data) => data.member_id === patientData?.id
                );

                // Check if the package is associated with the selected member
                const isTestChecked =
                  isMemberSelected &&
                  patientCheckedData?.data
                    .find((data) => data.member_id === patientData?.id)
                    ?.test_id.some((t_data) => t_data === cartData.id);

                return (
                  <div className="patient-member-detail-box d-flex align-items-center justify-content-between">
                    <div className="patient-member-image">
                      <img src={appPackageIcon} width="100%" alt="review" />
                      <p>{cartData?.name}</p>
                    </div>
                    <div className="patient-check">
                      <input
                        checked={isTestChecked}
                        type="checkbox"
                        disabled={!isMemberSelected}
                        className="form-check-input small-check"
                        onChange={(e) =>
                          handleMemberCheckbox(
                            e,
                            cartData,
                            patientData,
                            "tests"
                          )
                        }
                      />
                    </div>
                  </div>
                );
              })}
              {cartDetailsList?.data?.response?.packages?.map((cartData) => {
                // Check if the member is selected
                const isMemberSelected = patientCheckedData?.data.some(
                  (data) => data.member_id === patientData?.id
                );

                // Check if the package is associated with the selected member
                const isPackageChecked =
                  isMemberSelected &&
                  patientCheckedData?.data
                    .find((data) => data.member_id === patientData?.id)
                    ?.package_id.some((t_data) => t_data === cartData.id);

                return (
                  <div className="patient-member-detail-box d-flex align-items-center justify-content-between">
                    <div className="patient-member-image">
                      <img src={appPackageIcon} width="100%" alt="review" />
                      <p>{cartData?.name}</p>
                    </div>
                    <div className="patient-check">
                      <input
                        checked={isPackageChecked}
                        type="checkbox"
                        disabled={!isMemberSelected}
                        className="form-check-input small-check"
                        onChange={(e) =>
                          handleMemberCheckbox(
                            e,
                            cartData,
                            patientData,
                            "packages"
                          )
                        }
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}

      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: "",
          duration: 3000,
          style: {
            background: "#fff",
            color: "#363636",
          },
        }}
      />
    </div>
  );
};

export default PatientCardDetails;
