import { useQuery } from "@tanstack/react-query";
import { DIAGNOSTIC_TEST_API , CATEGORY_TEST_API , NEW_WEB_URL,  HEALTH_PACKAGE_API , DIAGNOSTIC_CART_DETAILS_API} from "../Constants";

const fetchDiagnosticTest = async () => {
  try {
    const response = await fetch(`${DIAGNOSTIC_TEST_API}`);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    return { message: error.message };
  }
};

const fetchTestCategories = async () => {
  try {
    const response = await fetch(`${NEW_WEB_URL}/api/${CATEGORY_TEST_API}`);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    return { message: error.message };
  }
};

const fetchDiagnosticPackage = async () => {
  try {
    const response = await fetch(`${HEALTH_PACKAGE_API}`);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    return { message: error.message };
  }
};

const fetchDiagnosticDetails = async () => {
  try {
    const response = await fetch(`${DIAGNOSTIC_CART_DETAILS_API}`);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    return { message: error.message };
  }
};

const fetchPackageDescription = async (package_id) => {
  try {
    console.log(package_id)
    const response = await fetch(`${HEALTH_PACKAGE_API}/${package_id}`);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    return { message: error.message };
  }
};

const fetchTestDescription = async (test_id) => {
  try {
    const response = await fetch(`${NEW_WEB_URL}/api/${DIAGNOSTIC_TEST_API}/${test_id}`);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    return { message: error.message };
  }
};

const useFetchDiagnosticTest = () => {
  return useQuery({
    queryKey: ['fetchdiagnosticTest'],
    queryFn: fetchDiagnosticTest,
    staleTime: 86400000,
    cacheTime: 86400000,
  });
};

const useFetchPackageDescription = (package_id) =>{
  return useQuery({
    queryKey : ['packagedescription' , package_id],
    queryFn : () => fetchPackageDescription(package_id),
    staleTime : 86400000,
    cacheTime : 86400000,
    enabled: !!package_id,
  })
}

const useFetchTestDescription = (test_id) => {
  return useQuery({
    queryKey: ['testdescription', test_id],
    queryFn: () => fetchTestDescription(test_id),
    staleTime: 86400000, // 1 day
    cacheTime: 86400000, // 1 day
    enabled: !!test_id,
  });
};


const useFetchTestCategories = () =>{
  return useQuery({
      queryKey : ['fetchTestCategories'],
      queryFn : fetchTestCategories,
      staleTime : 86400000,
      cacheTime : 86400000
  })
}

const useFetchDiagnosticPackage = () =>{
  return useQuery({
      queryKey : ['testcategories'],
      queryFn : fetchDiagnosticPackage,
      staleTime : 86400000,
      cacheTime : 86400000
  })
}


const useFetchDiagnosticCartDetails = () =>{
  return useQuery({
      queryKey : ['diagnosticCartDetails'],
      queryFn : fetchDiagnosticDetails,
      staleTime : 86400000,
      cacheTime : 86400000
  })
}

export {useFetchDiagnosticTest,useFetchDiagnosticCartDetails , useFetchTestCategories , useFetchDiagnosticPackage ,useFetchPackageDescription , useFetchTestDescription}