import createApiReducer from "../ApiReducer/ApiReducer";


// GET METHODS APIS
export const medicineReducer = createApiReducer('FETCH_MEDICINE');
export const medicineListReducer = createApiReducer('FETCH_MEDICINE_LIST');
export const medicineRecentListReducer = createApiReducer('FETCH_MEDICINE_RECENT_LIST');
export const prescriptionListReducer = createApiReducer('FETCH_PRESCRIPTION_LIST');
export const medicineCartDetailsReducer = createApiReducer('FETCH_MEDICINE_CART_LIST');
export const singleMedicineReducer = createApiReducer('FETCH_SINGLE_MEDICINE_LIST');
export const offerListReducer = createApiReducer('FETCH_OFFER_LIST');
export const packageListReducer = createApiReducer('FETCH_PACKAGE_LIST');
export const medicineAddressReducer = createApiReducer('FETCH_MEDICINE_ADDRESS');
export const pincodeReducer = createApiReducer('FETCH_PINCODE');
export const orderlistReducer=createApiReducer('FETCH_MEDICINE_ORDER_LIST');
export const orderHistory=createApiReducer('FETCH_HISTORY');
export const trackOrderReducer=createApiReducer('FETCH_TRACK_ORDER');




// POST METHODS APIS
export const uploadPrescriptionReducer = createApiReducer('UPLOAD_PRESCRIPTION');
export const deletePrescriptionReducer = createApiReducer('DELETE_PRESCRIPTION');
export const medicineCartData = createApiReducer('MEDICINE_CART_ADD');
export const medicineCartDelete = createApiReducer('MEDICINE_CART_DELETE');
export const orderMedicineReducer = createApiReducer('ORDER_MEDICINE');
export const captureOrderMedicineReducer = createApiReducer('CAPTURE_ORDER_MEDICINE');
export const cancelOrderMedicineReducer = createApiReducer('CANCEL_ORDER_MEDICINE');
export const ReOrderMedicineReducer = createApiReducer('REORDER_ORDER_MEDICINE');

// export const packageDescriptionReducer = createApiReducer('FETCH_PACKAGE_DESCRIPTION')
export const callDoctor = createApiReducer('CALL_DOCTOR');
export const saveAddress = createApiReducer('SAVE_MEDICINE_ADDRESS');
export const deleteAddress = createApiReducer('DELETE_MEDICINE_ADDRESS');
export const selectedAddress = createApiReducer('SELECTED_MEDICINE_ADDRESS');

// DIAGNOSTICS -------------------------
export const searchDiagnosticTest = createApiReducer('SEARCH_DIAGNOSTIC_TEST');
export const diagnosticCartDetailsReducer = createApiReducer('DIAGNOSTIC_CART_DETAILS')
export const diagnosticCartAddReducer = createApiReducer('DIAGNOSTIC_CART_ADD');
export const diagnosticRemoveCartReducer = createApiReducer('DIAGNOSTIC_REMOVE_CART');
export const patientmemberlistReducer = createApiReducer('PATIENT_MEMBERS_LIST');
export const addPatientMembersReducers = createApiReducer('ADD_PATIENT_MEMBERS');
export const deletePatientReducer = createApiReducer('DELETE_PATIENT_MEMBERS');
export const savePatientMembersReducers = createApiReducer('SAVE_PATIENT_MEMBERS');
export const getTimeslotReducers = createApiReducer('GET_TIME_SLOTS');
export const getDiagnosticTestListReducers = createApiReducer('DIAGNOSTIC_TEST_LIST');
export const getPackageListReducers = createApiReducer('DIAGNOSTIC_PACKAGE_LIST');
export const diagnosticOrderDetailsReducers = createApiReducer('DIAGNOSTIC_PLACE_ORDER_DETAILS');
export const saveSlotTimeReducers = createApiReducer('SAVE_TIME_SLOT');
export const orderDiagnosticReducers = createApiReducer('ORDER_DIAGNOSTIC');
export const selectedDiganosticAddressReducers = createApiReducer('SELECTED_DIAGNOSTIC_ADDRESS');
export const captureDiagnosticPaymentDetailsReducers = createApiReducer('CAPTURE_DIAGNOSTIC_PAYMENT_DETAILS');
export const categoriesListReducers = createApiReducer('GET_CATEGORIES_TEST');
export const test_descriptions_reducers = createApiReducer('TEST_DESCRIPTION');
export const getpackageDescription_reducers = createApiReducer('PACKAGE_DESCRIPTION');


export const package_registration_reducers = createApiReducer('AUTH_PACKAGE_REGISTRATION');
export const package_verify_reducers = createApiReducer('VERIFY_PACKAGE_OTP');
export const capture_user_package_reducers = createApiReducer('CAPTURE_USER_PACKAGE');
export const generate_otp_reducers = createApiReducer('GENERATE_OTP');
export const verify_otp_reducers = createApiReducer('VERIFY_LOGIN_OTP');
