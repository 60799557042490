import React from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { ArrowBackButton } from '../../utils/AllFunction'
import { ArrowBack, appLocationAmbulance, appSuperWatch, headerLogo } from '../../images'
import { useEffect } from 'react'
import { useState, useContext } from 'react';
import Form from 'react-bootstrap/Form';
import { MdArrowRightAlt } from "react-icons/md";
import Loader from '../../Animation/Loader'
import SuccessFull from '../../Animation/SuccessFull'
import { AppContext } from "../../ContextApi";
import { HOME_DASHBOARD_SCREEN_API, REQUESTOPTIONS, SUPER_WATCH_API } from '../../Constants'
import ConfirmModal from "../MyProfile/Components/ConfirmModal";

const SuperWatch = () => {
    const [location, setLocation] = useState({
        latitude: null,
        longitude: null,
        pincode: null,
        state: null,
        city: null,
        fullAddress: null,
        shortAddress: null,
    });
    const token = localStorage.getItem("ACCESS_TOKEN");
    const [homedata, setHomeData] = useState(null);
    const [userinfo, setUserInfo] = useState([]);
    const [successmodal, setSuccessModal] = useState(false);
    const [modalShow, setModalShow] = React.useState(false);
    const [loadingHomeData, setLoadingHomeData] = useState(true);
    const { module_id, description, package_id } = useParams();
    const [showCurrentLocation, setShowCurrentLocation] = useState(true);
    const [successMessage, setSuccessMessage] = useState('');
    const [showaddress, setShowAddress] = useState(true);
    const navigate = useNavigate()

    const { handleClose, formShow, handleShow } = useContext(AppContext)

    const [alternateAddress, setAlternateAddress] = useState({
        address1: '',
        address2: '',
        landmark: '',
        pincode: '',
        state: '',
        city: '',
    });

    // console.log(module_id, description, package_id)

    // It Is For User Info
    useEffect(() => {
        const fetchData = async () => {
            try {
                const homeDataRes = await fetch(HOME_DASHBOARD_SCREEN_API, REQUESTOPTIONS);

                if (!homeDataRes.ok) {
                    console.error('API error:', homeDataRes.status, homeDataRes.statusText);
                    const errorData = await homeDataRes.json().catch(() => null);
                    console.error('Error details:', errorData);
                    throw new Error('Network response was not ok');
                }

                const homeData = await homeDataRes.json();
                setHomeData(homeData);
                setUserInfo(homeData?.user_info?.mobile);
                localStorage.setItem('userInfo', JSON.stringify(homeData?.user_info));
            } catch (error) {
                console.error("Error fetching home data:", error);
            } finally {
                setLoadingHomeData(false);
            }
        }
        fetchData();
    }, [])

    // It Is for LocationAPi
    useEffect(() => {
        const getLocation = async () => {
            try {
                if (navigator.geolocation) {
                    const position = await new Promise((resolve, reject) => {
                        navigator.geolocation.getCurrentPosition(
                            (position) => {
                                resolve(position);
                            },
                            reject,
                            {
                                enableHighAccuracy: true,
                            }
                        );
                    });

                    const { latitude, longitude } = position.coords;

                    const geocodingApiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyBz8qUh5g91GyggiZf6eNqOC6jKMErllxA`;

                    const response = await fetch(geocodingApiUrl);
                    const data = await response.json();
                    // console.log(data)

                    if (data.results && data.results.length > 0) {
                        const addressComponents = data.results[0].address_components;
                        const pincode = addressComponents.find((component) => component.types.includes('postal_code')).long_name;
                        const state = addressComponents.find((component) => component.types.includes('administrative_area_level_1')).long_name;

                        const city = addressComponents.find((component) => component.types.includes('locality')).long_name;
                        const fullAddress = data.results[0].formatted_address;
                        const shortAddress = `${addressComponents[0].short_name}, ${addressComponents[1].short_name},${addressComponents[4].short_name},${addressComponents[6].long_name},${addressComponents[9].long_name},${addressComponents[10].long_name}`;

                        setLocation({
                            latitude,
                            longitude,
                            pincode,
                            state,
                            city,
                            fullAddress,
                            shortAddress,
                        });
                    } else {
                        console.error('Invalid response structure from Google Maps Geocoding API:', data);
                    }
                } else {
                    console.error('Geolocation is not supported by this browser.');
                }
            } catch (error) {
                console.error('Error getting location:', error.message);
            } finally {
                setLoadingHomeData(false);
            }
        };
        getLocation();
    }, []);

    const onSubmit = async () => {
        // e.preventDefault();

        const checkbox = document.getElementById('watchlocation');
        if (!checkbox.checked) {
            alert('please check ')
            return
        }

        const formData = new FormData();
        formData.append("vendor_name", "package");
        formData.append("txt_pickup_address_1", location.shortAddress);
        formData.append("txt_pickup_pincode", location.pincode);
        formData.append("txt_pickup_city", location.city);
        formData.append("txt_pickup_state", location.state);
        formData.append("lat", location.latitude);
        formData.append("long", location.longitude);
        formData.append("mobile_no", userinfo);
        formData.append("user_package_id", package_id);
        formData.append("module_id", module_id);


        try {
            const watchData = await fetch(`${SUPER_WATCH_API}`, {
                method: "POST",
                headers: {
                    Authorization: `${token}`,
                },
                body: formData,
            });

            const responseData = await watchData.json();

            if (!watchData.ok) {
                console.error('API error:', watchData.status, watchData.statusText);
                const errorData = await watchData.json().catch(() => null);
                console.error('Error details:', errorData);
                throw new Error('Network response was not ok');
            }

            if (responseData.status === 200) {
                setSuccessModal(true);
                setSuccessMessage(responseData.message)
                setModalShow(true);
                setTimeout(() => {
                    setModalShow(false);
                    setSuccessModal(false);
                    navigate('/home')
                }, 3000)
            } else {
                alert("Unsuccessful submission");
            }

        } catch (error) {
            console.error(error);
        }
    }
    const onSubmitAlternate = async () => {
        // e.preventDefault();

        const formData = new FormData();
        formData.append("vendor_name", "package");
        formData.append("txt_pickup_address_1", alternateAddress.address1);
        formData.append("txt_pickup_address_2", alternateAddress.address2);
        formData.append("txt_pickup_landmark", alternateAddress.landmark);
        formData.append("txt_pickup_pincode", alternateAddress.pincode);
        formData.append("txt_pickup_city", alternateAddress.city);
        formData.append("txt_pickup_state", alternateAddress.state);
        formData.append("lat", location.latitude);
        formData.append("long", location.longitude);
        formData.append("mobile_no", userinfo);
        formData.append("user_package_id", package_id);
        formData.append("module_id", module_id);


        try {
            const watchData = await fetch(`${SUPER_WATCH_API}`, {
                method: "POST",
                headers: {
                    Authorization: `${token}`,
                },
                body: formData,
            });

            const responseData = await watchData.json();

            if (!watchData.ok) {
                console.error('API error:', watchData.status, watchData.statusText);
                const errorData = await watchData.json().catch(() => null);
                console.error('Error details:', errorData);
                throw new Error('Network response was not ok');
            }

            if (responseData.status === 200) {
                setSuccessModal(true);
                setSuccessMessage(responseData.message)
                setModalShow(true);
                setTimeout(() => {
                    setModalShow(false);
                    setSuccessModal(false);
                    navigate('/home')
                }, 3000)
            } else {
                alert("Unsuccessful submission");
            }

        } catch (error) {
            console.error(error);
        }
    }
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setAlternateAddress((prev) => ({ ...prev, [name]: value }));
    };

    const toggleSection = () => {
        setShowCurrentLocation(!showCurrentLocation);
        setShowAddress(false);
    }

    return (
        <div className='home-review-banner'>
            <div className="home-app-header nutrition-repo-box">

                <div className="header-logo">
                    <img src={headerLogo} width="100%" alt="header-logo" />
                    <div className="user-name">
                        <p>Super Watch</p>
                    </div>
                </div>
            </div>

            {loadingHomeData && loadingHomeData ? (
                <p><Loader /></p>
            ) : (
                <>
                    <div class="ambulance-cover-section">
                        <div class="ambulance-header-image watch-data">
                            <img src={appSuperWatch} width="100%" alt="review" />
                            <p>Smart Watch</p>
                        </div>
                        <div className="watch-description">
                            <p>{description}</p>
                        </div>
                    </div>
                    <div className="current-location-section">
                        {showaddress && (
                            <div className="current-location-box-section">
                                <div className="head-location-section head-text-watch">
                                    <p>Use my current location</p>
                                </div>
                                <div className="current-location-box">
                                    <div className="location-image-box">
                                        <img src={appLocationAmbulance} alt="" /><p>Current Location</p>
                                    </div>
                                    <div className="location-box">
                                        {location.shortAddress && location.shortAddress ? (
                                            <p>{location.shortAddress},{location.pincode}</p>
                                        ) : (
                                            <p>{location.fullAddress}</p>
                                        )}
                                    </div>
                                </div>
                                <div className="same-address-location">
                                    <Form.Check
                                        inline
                                        label="Add above address as delivery address"
                                        name="location"
                                        type="checkbox"
                                        id="watchlocation"
                                    />
                                </div>
                                <div className="super-watch-submit-btn">
                                    <button onClick={handleShow}>Submit</button>
                                </div>
                                <p className="or-text">OR</p>
                                <div className="form-section-button">
                                    <button onClick={toggleSection}>
                                        <p>Add Address Here</p>
                                        <MdArrowRightAlt />
                                    </button>
                                </div>
                                {successmodal && (
                                    <SuccessFull
                                        show={modalShow}
                                        successMessage={successMessage}
                                        animationtype={successmodal}
                                    />
                                )}
                            </div>

                        )}
                        <div className="super-watch-form-section">
                            {!showCurrentLocation && (
                                <div className="form-section-box">
                                    <div className="ambulance-form-box">
                                        <label>Address Line 1</label>
                                        <input type="text" name="address1" onChange={handleInputChange} placeholder="Enter Your Address Line 1" />
                                        <label>Address Line 2 (Optional)</label>
                                        <input type="text" name="address2" onChange={handleInputChange} placeholder="Enter Your Address Line 2" />
                                        <label>Landmark (Optional)</label>
                                        <input type="text" name="landmark" onChange={handleInputChange} placeholder="Enter Landmark" />
                                        <label>Pincode</label>
                                        <input type="text" name="pincode" onChange={handleInputChange} placeholder="Enter Pincode" />
                                        <label>State</label>
                                        <input type="text" name="state" onChange={handleInputChange} placeholder="Enter State" />
                                        <label>City</label>
                                        <input type="text" name="city" onChange={handleInputChange} placeholder="Enter City" />
                                        <button id="submitButton" onClick={() => {
                                            onSubmitAlternate();
                                            setModalShow(true);
                                        }}>
                                            Submit
                                        </button>
                                    </div>
                                    {successmodal && (
                                        <SuccessFull
                                            show={modalShow}
                                            successMessage={successMessage}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                    <ConfirmModal handleClose={handleClose} formShow={formShow} onSubmit={onSubmit} />
                </>
            )
            }
        </div>
    )
}

export default SuperWatch