import { React, useState, useEffect, useContext, useRef } from "react";
import { ArrowBack, headerLogo, dummyDoctorIcon } from "../../images";
import {
  ArrowBackButton,
  formatYY_MM_DD,
  getDay,
} from "../../utils/AllFunction";
import { NavLink, json, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "../../Animation/Loader";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Link , useNavigate } from "react-router-dom";
import toast , { Toaster } from "react-hot-toast";
import useRazorpay from "react-razorpay";
import {
  DOCTOR_VISIT_API,
  DOCTOR_PROFILE_API,
  DOCTOR_TIMESLOT_API,
  DOCTOR_DATES_API,
  DOCTOR_PREVIEW,DOCTOR_PLACE_ORDER_API
} from "../../Constants";

import PaymentModal from "../../Animation/PaymentModal"

const Doctordetails = () => {
  const { doc_id, doctor_name } = useParams();
  const [DoctorsdetailsData, setDoctordetailsData] = useState([]);
  const [weeklyTimeData, setWeeklyTimeData] = useState([]);
  const [hospitalDetails, setHospitalDetails] = useState([]);
  const [isDataLoading, setDataLoading] = useState(true);
  const [profile_path, setProfilepath] = useState("");
  const [isErrorData, setisErrorData] = useState(false);
  const [achivementsData, setachivementsData] = useState([]);
  const [experienceData, setExperienceData] = useState([]);
  const [similarDoctorsData, setSimilarDoctorsData] = useState([]);
  const [othersData, setOthersData] = useState([]);
  const token = localStorage.getItem("ACCESS_TOKEN");
  const [showFullText, setShowFullText] = useState(false);
  const [show, setShow] = useState(false);
  const [isPaymentModalOpen, setPaymentModal] = useState(false);
  const [paymentMsg, setpaymentMsg] = useState("");
  const [paymentAnimation, setPaymentAnimation] = useState(false);
  const [isPaymentLoading, setPaymentLoading] = useState(false);
  const [stateOptions, setStateOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [userInfo, setUserInfo] = useState(
    JSON.parse(localStorage.getItem("userInfo")) || []
  );
  const [timeSlotOption, setTimeSlotOptions] = useState([]);
  const [timeSlotData, setTimeSlotData] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const toggleFullText = () => {
    setShowFullText(!showFullText);
  };

  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `${token}`,
    },
  };

  useEffect(() => {
    const formData = new FormData();
    const doc_Data = new FormData();

    formData.append("doctor_id", doc_id);
    formData.append("doctor_name", doctor_name);

    const requestOptionsCopy = { ...requestOptions };
    requestOptionsCopy.method = "POST";
    requestOptionsCopy.body = formData;
    requestOptionsCopy.processData = false;
    requestOptionsCopy.contentType = false;

    window.scrollTo({ top: 0, behavior: "smooth" });

    async function GetDoctordetails() {
      setDataLoading(true);
      try {
        const response = await fetch(DOCTOR_PROFILE_API, requestOptionsCopy);
        const res = await response.json();
        if (res.status) {
          setProfilepath(res?.profile_path);
          setDoctordetailsData(res?.doctor_info);
          setachivementsData(res?.achievements);
          setExperienceData(res?.experience);
          setSimilarDoctorsData(res?.near_by);
          setDataLoading(false);
        } else {
          alert("UnsuccessFull");
          setDataLoading(false);
        }
      } catch (error) {
        console.error(error);
        setisErrorData(true);
      }
    }

    async function GetDoctorWeeklyTime() {
      setDataLoading(true);
      try {
        const response = await fetch(DOCTOR_VISIT_API, requestOptionsCopy);
        const res = await response.json();
        if (res.status) {
          // console.log(res);
          setHospitalDetails(res?.visit_details[0]);
          setOthersData(res?.visit_details_res.visit_hos[0]);
          setWeeklyTimeData(res?.days_time);
          setDataLoading(false);
        } else {
          alert("UnsuccessFull");
          setDataLoading(false);
        }
      } catch (error) {
        console.error(error);
        setisErrorData(true);
      }
    }

    async function TimeSlotDetails() {
      doc_Data.append("doctor_id", DoctorsdetailsData.doc_hospotal_id);
      doc_Data.append("hospital_id", DoctorsdetailsData.id);

      const datesOption = { ...requestOptionsCopy };
      datesOption.body = doc_Data;
      try {
        const response = await fetch(DOCTOR_DATES_API, datesOption);

        const res = await response.json();
        if (res.status) {
          setTimeSlotData(res.details);
          setTimeSlotOptions(
            res?.details?.length > 0 ? res?.details?.date : []
          );
        } else {
          // console.log(res);
        }
      } catch (error) {
        console.error(error);
      }
    }

    GetDoctordetails();
    GetDoctorWeeklyTime();
    TimeSlotDetails();
  }, [doc_id]);


  return (
    <>
      <div className="home-review-banner">
        <div className="home-app-header d-flex justify-content-start align-items-center doctor-detail-header">
          <div className="header-logo mx-3">
            <p className="mb-0">{DoctorsdetailsData?.doctor_name}</p>
          </div>
        </div>

        <div className="dental-consultation-main-wrapper-cover">
          {isDataLoading ? (
            <Loader />
          ) : (
            <>
              <div className="doctor-opd-detail-main-wrapper-cover">
                <div className="doctor-detail-card-box">
                  <div className="doctor-img-box">
                    <img
                      src={`${profile_path}${DoctorsdetailsData?.profile}`}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = dummyDoctorIcon;
                      }}
                    ></img>
                  </div>
                  <div className="doctor-detail-box d-flex justify-content-center align-items-center flex-column">
                    <h6>{DoctorsdetailsData?.doctor_name}</h6>
                    <p>{DoctorsdetailsData?.education}</p>
                    <p>
                      Department <span>{DoctorsdetailsData?.department}</span>
                    </p>
                  </div>
                  <div
                    className={`doctor-description-box ${
                      showFullText ? "expanded" : ""
                    }`}
                  >
                    <p>
                      {showFullText
                        ? DoctorsdetailsData?.about_doctor
                        : DoctorsdetailsData?.about_doctor?.slice(0, 200)}
                    </p>
                    {DoctorsdetailsData?.about_doctor?.length > 200 && (
                      <span className="read-more" onClick={toggleFullText}>
                      {showFullText ? "Read Less" : "Read More"}
                    </span>
                    )}
                    
                  </div>
                </div>

                <div className="doctor-visit-time-box-cover">
                  <div className="doctor-visit-detail-box">
                    <h6>{hospitalDetails?.hospital_name}</h6>
                    <p className="hospital-address-box">
                      {hospitalDetails?.full_address}
                    </p>
                    <p>
                      OPD Fee : <span>₹ {hospitalDetails?.opd_cost}</span>
                    </p>

                    <div className="doctor-visit-weekly-time-table-box">
                      <h6>Weekly TimeTable</h6>
                      {weeklyTimeData.length > 0 ? (
                        weeklyTimeData.map((timeData, ind) => (
                          <div className="week-time-box" key={ind}>
                            <div className="row d-flex justify-content-between">
                              <div className="col-4">
                                <div className="week-day">
                                  <p>{timeData?.days}</p>
                                </div>
                              </div>
                              <div className="col-8">
                                <div className="week-time text-end">
                                  <p>{timeData?.time_slot}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <h6>No Time Slots Available</h6>
                      )}
                    </div>

                    <div className="book-btn-box mb-5 mt-2 d-flex justify-content-between">
                      <button>
                        <Link to={`${othersData?.map_link}`}
                        target="_blank"
                        >View on Map</Link>
                      </button>
                      <button onClick={handleShow}>Book Now</button>
                    </div>
                  </div>
                </div>

                <div className="doctor-about-main-cover">
                  <div className="doctor-about-box">
                    <h6>Know more about me</h6>

                    <ul>
                      {DoctorsdetailsData?.practesing_since !== "" && (
                        <li>
                          <h6>Practicing Since</h6>
                          <p>{DoctorsdetailsData?.practesing_since}</p>
                        </li>
                      )}
                      {DoctorsdetailsData?.designation !== "" && (
                        <li>
                          <h6>Designation</h6>
                          <p>{DoctorsdetailsData?.designation}</p>
                        </li>
                      )}
                      {DoctorsdetailsData?.department !== "" && (
                        <li>
                          <h6>Department</h6>
                          <p>{DoctorsdetailsData?.department}</p>
                        </li>
                      )}
                      {DoctorsdetailsData?.specializations !== "" && (
                        <li>
                          <h6>Specialization</h6>
                          <p>{DoctorsdetailsData?.specializations}</p>
                        </li>
                      )}
                      {experienceData.length > 0 && (
                        <li>
                          <h6>Experience</h6>
                          {experienceData.map((data) => {
                            return <p>{data.experience}</p>;
                          })}
                        </li>
                      )}
                      {achivementsData.length > 0 && (
                        <li>
                          <h6>Achievement/honors</h6>
                          {achivementsData.map((data) => {
                            return <p>{data.achievement}</p>;
                          })}
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
                {similarDoctorsData.length > 0 && (
                  <div className="similar-doctor-main-cover">
                    <div className="similar-doctor-main-box">
                      <div className="similar-doctors-list-box-cover">
                        <h6 className="mb-3">Similar Doctors</h6>
                        {similarDoctorsData.length > 0 &&
                          similarDoctorsData
                            .slice(0, 5)
                            .map((doctor_similar) => {
                              return (
                                <NavLink
                                  to={`/doctordetails/${doctor_similar?.id}`}
                                >
                                  <div className="similar-doctor-box d-flex mb-2">
                                    <div className="doctor-img-box similar-doc-img-box">
                                      <img
                                        src={`${profile_path}${doctor_similar?.profile}`}
                                        onError={({ currentTarget }) => {
                                          currentTarget.onerror = null;
                                          currentTarget.src = dummyDoctorIcon;
                                        }}
                                      ></img>
                                    </div>
                                    <div
                                      className="similar-doctor-name-box mt-2"
                                      key={doctor_similar.id}
                                    >
                                      <h6 className="">
                                        {doctor_similar?.doctor_name}
                                      </h6>
                                      <p>{doctor_similar?.designation}</p>
                                    </div>
                                  </div>
                                </NavLink>
                              );
                            })}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </div>

        <Toaster
          position="top-center"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            className: "custom-toast",
            duration: 3000,
            style: {
              background: "#fff",
              color: "#363636",
              fontSize: "14px",
              width: "300px",
              top: "55px !important",
            },
          }}
        />

        <div className="book-now-modal-main-cover-wrapper">
          <BookNowModal
            show={show}
            handleClose={handleClose}
            setShow={setShow}
            stateOptions={stateOptions}
            requestOptions={requestOptions}
            userInfo={userInfo}
            selectedOption={selectedOption}
            DoctorsdetailsData={DoctorsdetailsData}
            timeSlotOption={timeSlotOption}
            timeSlotData={timeSlotData}
            setTimeSlotOptions={setTimeSlotOptions}
            hospitalDetails={hospitalDetails}
            DOCTOR_PLACE_ORDER_API={DOCTOR_PLACE_ORDER_API}
            token={token}
            doc_id={doc_id}
            useRazorpay={useRazorpay}
            useNavigate={useNavigate}
            setPaymentModal={setPaymentModal}
            setpaymentMsg={setpaymentMsg}
            setPaymentAnimation={setPaymentAnimation}
            setPaymentLoading={setPaymentLoading}
            
          />

            {paymentAnimation && (
                <PaymentModal
                  show={isPaymentModalOpen}
                  successMessage={paymentMsg}
                  paymentAnimation={paymentAnimation}
                />
            )}
        </div>
      </div>
    </>
  );
};

export default Doctordetails;

function BookNowModal({
  show,
  setShow,
  selectedTeleConsultationDetails,
  requestOptions,
  userInfo,
  selectedOption,
  DoctorsdetailsData,
  timeSlotOption,
  timeSlotData,
  setTimeSlotOptions,
  hospitalDetails,
  DOCTOR_PLACE_ORDER_API,
  token, doc_id ,useRazorpay ,
  setPaymentModal,
  setpaymentMsg,
  setPaymentAnimation, 
  setPaymentLoading
}) {
  const handleClose = () => setShow(false);

  const navigate = useNavigate();

  const [patientName, setPatientName] = useState(userInfo.fullname);
  const [patientMobile, setPatientMobile] = useState(userInfo.mobile);
  const [patientEmail, setPatientEmail] = useState(userInfo.email);
  const [isDataLoading, setDataLoading] = useState(true);
  const [dentalBookingDate, sethandleDentalBookingDate] = useState(null);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [insertId , setInsertId] = useState(null)
  


  const handleTimeSlotChange = (event) => {
    setSelectedTimeSlot(event.target.value);
  };

  const [Razorpay] = useRazorpay();


  async function bookDoctorPlaceOrder(doctorId, hospitalId, fullname, mobilenumber, emailid, price, slotdate, slottime, insertId ) {
    const apiBody = {
        doctor_id: doctorId,
        hospital_id: hospitalId,
        fullname: fullname,
        mobileno: mobilenumber,
        email_id: emailid,
        price: price,
        slot_date: slotdate,
        slot_time: slottime,
        insert_id: insertId
    };

    try {
        const response = await fetch(DOCTOR_PLACE_ORDER_API, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':   token
            },
            body: JSON.stringify(apiBody)
        });
        if(response.status == 200){
          toast.success(response.message);
        }

    } catch (error) {
        console.log(error)
    }
}

const handlePlaceOrder = async (razorpay_details) => {
  const handlePayment = async (razorpay_details) => {
    // Define a custom handler function
    const customSuccessHandler = function (response) {
      MakeOnlinePayment(
        razorpay_details.call_back_url,
        response.razorpay_payment_id,
        razorpay_details.insert_id
      );
    };

    razorpay_details.handler = customSuccessHandler;
    // razorpay_details.amount = 100;

    const rzp1 = new Razorpay(razorpay_details);

    rzp1.on("payment.failed", function (response) {
      // Handle failed payment
      alert(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
    });

    rzp1.open();
  };

  await handlePayment(razorpay_details); // Wait for the payment process to complete

  const payment_data = {
    insert_id: "",
    transaction_id: "",
  };

  const paymentOptions = { ...requestOptions };
  paymentOptions.method = "POST";
  paymentOptions.body = JSON.stringify(payment_data);
  paymentOptions.contentType = false;
  paymentOptions.processData = false;

  const MakeOnlinePayment = async (
    callBackUrl,
    transaction_id,
    insert_id
  ) => {
    setPaymentLoading(true);
    const url = new URL(callBackUrl);

    const pay_Transaction_data = {
      insert_id: insert_id,
      transaction_id: transaction_id
    };

    const updatedRequestOption = {
      ...paymentOptions,
        headers: {
          'Content-Type': 'application/json',
          'Authorization':   token
      },
      body: JSON.stringify(pay_Transaction_data),
    };
  
    try {
      // const apiUrl = `${window.location.origin}${url.pathname}`;
      const response = await fetch(url.href, updatedRequestOption);

      const res = await response.json();
      if (res.status) {
        setPaymentAnimation(true);
        setpaymentMsg(res.message);
        setPaymentLoading(false);
        setPaymentModal(true);

        toast.success(res.message)
        // console.log(res)
      } else {
        setPaymentAnimation(false);
        setpaymentMsg(res.message);
        // toast.error(res.message)
      }
    } catch (error) {
      console.error(error);
    }
  };
};


  const onSubmit = () => {
    const formData = new FormData();

    formData.append("doctor_id", doc_id);
    formData.append("hospital_id", DoctorsdetailsData.doc_hospotal_id);
    formData.append("email_id", patientEmail);
    formData.append("fullname", patientName);
    formData.append("mobileno", patientMobile);
    formData.append("price", +hospitalDetails?.opd_cost.replace(/\/-/g, ''));
    formData.append("slot_date", dentalBookingDate);
    formData.append("slot_time", selectedTimeSlot);

    const requestOptionsCopy = { ...requestOptions };
    requestOptionsCopy.method = "POST";
    requestOptionsCopy.body = formData;
    requestOptionsCopy.processData = false;
    requestOptionsCopy.contentType = false;

    async function doctor_previewOrder() {
      try {
        const response = await fetch(DOCTOR_PREVIEW, requestOptionsCopy);
        const res = await response.json();
        if (res.status === 200) {
          setInsertId(res?.results?.insert_id)
          if(res?.wallet?.show_button == "partial" || res?.wallet?.show_button == "online"){
            handlePlaceOrder(res?.results?.razorpay_details);
          }
        } else {
            bookDoctorPlaceOrder(
                doc_id,
                DoctorsdetailsData.doc_hospotal_id,
                patientName,
                patientMobile,
                patientEmail,
                +hospitalDetails?.opd_cost.replace(/\/-/g, ''),
                dentalBookingDate,
                selectedTimeSlot,
                insertId
              );
            }
      } catch (error) {
        // Handle network errors or other exceptions
        console.error(error);
      }
    }
    
    doctor_previewOrder();
  };

  const handleDentalBookingDate = (e) => {
    sethandleDentalBookingDate(e.target.value);

    const formData = new FormData();

    formData.append("doctor_id", DoctorsdetailsData.id);
    formData.append("hospital_id", DoctorsdetailsData.doc_hospotal_id);
    formData.append("day", getDay(e.target.value));
    formData.append("date", formatYY_MM_DD(e.target.value));

    const requestOptionsCopy = { ...requestOptions };
    requestOptionsCopy.method = "POST";
    requestOptionsCopy.body = formData;
    requestOptionsCopy.processData = false;
    requestOptionsCopy.contentType = false;

    async function getDoctorTimeSlot() {
      try {
        const response = await fetch(DOCTOR_TIMESLOT_API, requestOptionsCopy);
        const res = await response.json();
        if (res.status === 200) {
          if (
            res.details.monring.length > 0 ||
            res.details.afternoon.length > 0 ||
            res.details.evening.length > 0
          ) {
            setTimeSlotOptions([
              ...res.details.monring,
              ...res.details.afternoon,
              ...res.details.evening,
            ]);
          }
        }
      } catch (error) {
        // Handle network errors or other exceptions
        console.error(error);
      }
    }

    getDoctorTimeSlot();
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className="book-now-modal-main-wrapper"
      >
        <Modal.Header closeButton className="book-now-header">
          <Modal.Title>
            <h6>Enter Person Details</h6>
            {/* <p className="book-now-title-description">
              Dentalconsultation Will be Conduct for this person
            </p> */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="book-now-modal-body">
          <div className="book-now-form-main-cover">
            <div className="book-now-form">
              <Form.Group
                className="mb-3 book-now-input-box"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Control
                  type="text"
                  placeholder="Patient Name"
                  value={patientName}
                  onChange={(e) => setPatientName(e.target.value)}
                />
              </Form.Group>
              <Form.Group
                className="mb-3 book-now-input-box"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Control
                  type="text"
                  placeholder="Mobile Number"
                  value={patientMobile}
                  onChange={(e) => setPatientMobile(e.target.value)}
                />
              </Form.Group>
              <Form.Group
                className="mb-3 book-now-input-box"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Control
                  type="text"
                  placeholder="Email"
                  value={patientEmail}
                  onChange={(e) => setPatientEmail(e.target.value)}
                />
              </Form.Group>
              <div className="patient-input-form">
                <select
                  name="time"
                  id="time"
                  className="relation"
                  onChange={handleDentalBookingDate}
                >
                  <option value="">Select Date</option>
                  {timeSlotData?.length > 0 &&
                    timeSlotData?.map((timeSlot, index) => (
                      <option key={index} value={timeSlot.date}>
                        {timeSlot.date}
                      </option>
                    ))}
                </select>
              </div>
              {dentalBookingDate === null ? (
                <h6></h6>
              ) : (
                <div className="patient-input-form">
                  <select
                    name="time"
                    id="time"
                    className="relation"
                    onChange={handleTimeSlotChange}
                  >
                    <option value="">Select Time</option>
                    {/* {console.log(timeSlotOption)} */}
                    {timeSlotOption?.length > 0 &&
                      timeSlotOption.map((timeSlot, index) => (
                        <option key={index} value={timeSlot.slot_time}>
                          {timeSlot.slot_time}
                        </option>
                      ))}
                  </select>
                </div>
              )}

              <p className="wallet_summary-point"></p>
              <div className="submit-btn-box d-flex justify-content-center">
                <button type="submit" onClick={onSubmit}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
