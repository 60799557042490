import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useLocation, useNavigate } from "react-router-dom";
import SuccessFull from "../Animation/SuccessFull";
import ErrorFull from "../Animation/ErrorFull";
import { FIND_PINCODE_API } from "../Constants";
import toast, { Toaster } from "react-hot-toast";

const Modals = (props) => {
  const [pincode, setPincode] = useState("");
  const token = localStorage.getItem("ACCESS_TOKEN");
  const [successmodal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [modalShow, setModalShow] = React.useState(false);
  const navigate = useNavigate();

  const handleSearch = async () => {
    const formdata = new FormData();
    formdata.append("pincode", pincode);
    try {
      const pincodeData = await fetch(FIND_PINCODE_API, {
        headers: {
          Authorization: `${token}`,
        },
        method: "POST",
        body: formdata,
      });

      if (!pincodeData.ok) {
        console.error("API error:", pincodeData.status, pincodeData.statusText);
        const errorData = await pincodeData.json().catch(() => null);
        console.error("Error details:", errorData);
        throw new Error("Network response was not ok");
      }

      const CityData = await pincodeData.json();
      localStorage.setItem("cityData", CityData?.city_name)
      if (CityData.status === 200) {
        localStorage.setItem("pincode", pincode);
        localStorage.setItem("pincodeData", JSON.stringify(CityData, pincode));
      }

      if (CityData.status === 200) {
        props.onHide();
        setSuccessModal(true);
        setSuccessMessage(CityData.message);
        setModalShow(true);
        setTimeout(() => {
          setModalShow(false);
          setSuccessModal(false);
          navigate("/buymedicines");
        }, 3000);
      } else {
        setErrorModal(true);
        setSuccessMessage(CityData.message);
        setModalShow(true);
        setTimeout(() => {
          setModalShow(false);
          setErrorModal(false);
          navigate("/");
        }, 3000);
      }
    } catch (error) {
      console.error("Error fetching home data:", error);
    } finally {
      // setLoadingHomeData(false);
    }
  };

  const handleClose = () => {
    const pincodeData = localStorage.getItem("pincodeData");
    if (!pincodeData) {
      window.history.go(-2);
    }
  };
  return (
    <>
      <Modal {...props} centered backdrop="static"
        keyboard={false}>
        <Modal.Header closeButton onClick={() => handleClose()}>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="modal-heading-text"
          >
            Enter Pincode
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="input-box-content">
            <input
              type="text"
              maxLength={6}
              placeholder="Enter Pincode"
              value={pincode}
              onChange={(e) => setPincode(e.target.value)}
            />
            <button onClick={handleSearch}>Search</button>
          </div>
        </Modal.Body>
      </Modal>
      {successmodal && (
        <SuccessFull show={modalShow} successMessage={successMessage} animationtype={successmodal} />
      )}
      {errorModal && (
        <ErrorFull show={modalShow} successMessage={successMessage} />
      )}

      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          className: "custom-toast",
          duration: 3000,
          style: {
            background: "#fff",
            color: "#363636",
            fontSize: "14px",
            width: "300px",
            top: "55px !important",
          },
        }}
      />
    </>
  );
};

export default Modals;
