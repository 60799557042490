import React from "react";
import "swiper/swiper-bundle.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import TestCard from "./TestCard"
import NoDataFoundComp from "../../../components/NoDataFound";

const RecommendedSection = ({ testData , title }) => {
  return (
    <>
      <div className="pack-recommended-wrapper">
        <div className="pack-recommended-heading">
          <hr />
          <p>Recommended {title}</p>
          <hr />
        </div>
        <div className="health-package-box-cover mb-4">
          <Swiper
            spaceBetween={20}
            slidesPerView={1.9}
            loop="true"
            modules={[Autoplay]}
            pagination={{ clickable: true }}
            // autoplay={{ delay: 2500, disableoninteraction: false, }}
          >
            <div className="swiper-wrapper">
              {testData?.length > 0 ? (
                testData?.slice(0, 6).map((elem) => (
                  <SwiperSlide key={elem.id}>
                    <div className="col-12">
                      <TestCard test={elem} title={title} />
                    </div>
                  </SwiperSlide>
                ))
              ) : (
                <NoDataFoundComp />
              )}
            </div>
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default RecommendedSection;
