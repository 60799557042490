import React from 'react'
import Lottie from "lottie-react";
import Loading from "../AnimationJson/addaddress.json";

const AddAddress = () => {
  return (
    <>
    <Lottie animationData={Loading} className='loader-data-animation'/>
    </>
  )
}

export default AddAddress