import React from "react";
import { appPackageIcon } from "../../../images";

const DescriptionDetailBox = ({detailsData}) => {
  
  return (
    <>
      <div className="pack-detail-cover-box">
        <div className="pack-deatil-img-box">
          <img src={appPackageIcon} width="100%" alt="review" />
          <h6>{detailsData?.data?.data[0]?.name}</h6>
        </div>
        <hr />
        <div className="pack-detail-content-box">
          <h6>MB Price ₹ {detailsData?.data?.data[0]?.medibhai_price}</h6>
          <p>₹ {detailsData?.data?.data[0]?.mrp}</p>
          <span>{detailsData?.data?.data[0]?.discount_rate}%OFF</span>
        </div>
      </div>
    </>
  );
};

export default DescriptionDetailBox;
