import React, { useEffect, useState } from 'react';
import '../style/shipment.css'
import { useDispatch, useSelector } from 'react-redux';
import useLocationState from '../../Medicine/hooks/useLocationState';
import { fetchTrackOrder } from '../../../Redux/actions/GetApiCall';
import Shipment_Card from './shipment_Card';
import { no_preview, pdf_icon, savings_image } from '../../Medicine/Images';
import Need_Help from '../../Medicine/Components/Need_Help';
import CancelOrderModal from './CancelOrderModal';
import toast from 'react-hot-toast';
import { ReorderApi } from '../../../Redux/actions/PostApiCall';
import { useNavigate } from 'react-router-dom';
import LoaderAnime from '../../Medicine/animation/prescription_loader';
import ToastBox from '../../Medicine/animation/ToastBox';

const ShipmentOrder = () => {
    const dispatch = useDispatch();
    const data = useLocationState();
    const TrackData = useSelector(state => state.track_order);
    const reorderData = useSelector(state => state.reorder_medicine)
    const TrackSummaryData = TrackData?.data?.data?.[0]
    console.log(TrackData?.data?.data?.[0]?.medicines)
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false)

    const handleShow = () => setShow(true);

    useEffect(() => {
        if (data?.medicine_order_id !== undefined && data?.onemg_order_id !== undefined) {
            dispatch(fetchTrackOrder(data?.medicine_order_id, data?.onemg_order_id))
        }
    }, [])

    const handleReorder = async (item) => {
        console.log(item)
        setShowModal(true); // Show the modal initially

        const data = {
            medicine_order_id: item
        };

        try {
            await dispatch(ReorderApi(data));

            // Wait for the state to update before navigating
            setShowModal(false); // Close the modal

            if (reorderData?.data?.success === true) {
                // Use a timeout to ensure the modal closes before navigating
                setTimeout(() => {
                    const tabkeyUrl = "medicines";
                    const tabkeyData = { tabkey: tabkeyUrl };
                    navigate('/cart', { state: tabkeyData });
                }, 300); // Adjust the delay if necessary

            } else if (reorderData?.data?.success === false) {
                toast.error(reorderData?.data?.message);
            }
        } catch (error) {
            toast.error('An error occurred while processing the reorder.');
        } finally {
            setShowModal(false)
        }
    };


    return (
        <div className="home-review-banner">
            {showModal && <LoaderAnime showModal={showModal} />}
            <div className="home-app-header ship-data">

                <div className="header-logo ">
                    <div className="user-name text-center">
                        <p>My Order Details</p>
                    </div>
                </div>
            </div>


            <div className="shipment-order-wrapper">
                <div className="order-shipment-data">
                    <h6>Order Details</h6>
                    <p>Ordered on 20th Oct 2023</p>
                    <p>Order ID</p>
                    <p>Order Total</p>
                </div>
                <div className="order-shipment-data">
                    <h6>Shipment Details</h6>

                    <div className="tracking-container">
                        {TrackData?.data?.data?.[0]?.track_journey?.map((item, index) => (
                            <div className="tracking-step" key={index}>
                                <div className={`step-icon ${item.isCompleted ? 'completed' : ''}`}>
                                    <img src={item?.logo} alt={item?.name} />
                                </div>
                                <p className={`step-name ${item.active === 1 ? 'completed-text' : ''}`}>{item?.name}</p>
                                {index < TrackData?.data?.data?.[0]?.track_journey?.length - 1 && <div className="step-connector"></div>}
                            </div>
                        ))}
                    </div>
                </div>

                <div className="item-summary-data-box-repo">
                    <h6>Item Summary</h6>
                    <Shipment_Card MedicineData={TrackData?.data?.data?.[0]?.medicines} />

                    <div className="payment-repo-cover-box shipment-order-summary">
                        <div className="payment-text-repo">
                            <p>Payment Summary</p>
                        </div>

                        <div className="payment-summary-box-repo">
                            {TrackSummaryData?.vas_charges?.details?.map((item, index) => (
                                <div className="price-payment-sum-repo repo-total" key={index}>
                                    <p>{item.display_text}</p>
                                    <p>₹ {item.amount?.toLocaleString('en-IN')}</p>
                                </div>
                            ))}
                            <div className="price-payment-sum-repo">
                                <p className="total-repo-class">Total Payable</p>
                                <p className="total-repo-class">₹ {TrackSummaryData?.payment_amount?.toLocaleString('en-IN')}</p>
                            </div>
                        </div>
                        <div className="payment-mode-data repo-mode-text">
                            <div className="payment-text-repo">
                                <p>Payment Mode</p>
                            </div>
                            <div className="payment-data-cover">
                                {TrackSummaryData?.upi_deducted?.map((item, index) => (
                                    <div className="price-payment-sum-repo repo-total" key={index}>
                                        <p>{item.display_text}</p>
                                        <p>₹ {item.amount?.toLocaleString('en-IN')}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    {TrackSummaryData?.total_saving > 0 && (
                        <div className="totoal-savings-data-repo">
                            <div className="price-payment-sum-repo saving-data-p">
                                <p><img src={savings_image} alt="" /> Total Savings</p>
                                <p>₹ {TrackSummaryData?.total_saving?.toLocaleString('en-IN')}</p>
                            </div>
                        </div>
                    )}

                    <div className="shipment-order-cover-info">
                        <div className="order-info-head">
                            <p>Order Information</p>
                        </div>

                        <div className="user-data-repo-box">
                            <p>Name: {TrackSummaryData?.address?.name}</p>
                            <p>Mobile Number: {TrackSummaryData?.address?.mobile_no}</p>
                            <p>Address: {TrackSummaryData?.address?.address}</p>
                            <p>Pincode: {TrackSummaryData?.address?.pincode} , City: {TrackSummaryData?.address?.city}</p>
                            <p>State: {TrackSummaryData?.address?.state}</p>

                            {TrackSummaryData?.prescription?.length > 0 && (
                                <>
                                    <hr />

                                    <div className="presciption-head-repo-text">
                                        <div className="order-info-head prscrption">
                                            <p>Prescription</p>
                                        </div>

                                        <div className="data-presc-wrapper-box">
                                            {TrackSummaryData?.prescription?.map((item, index) => (
                                                <div className="prescipion-image-box-repo-data" key={index}>
                                                    {item?.file_url?.endsWith(".pdf") ? (
                                                        <a href={item.file_url} target="_blank" rel="noopener noreferrer">
                                                            <img
                                                                src={pdf_icon} // Use a placeholder image for PDF files
                                                                alt="PDF File"
                                                            />
                                                        </a>
                                                    ) : (
                                                        <a href={item.file_url} target="_blank" rel="noopener noreferrer">
                                                            <img
                                                                src={item.file_url}
                                                                alt="Prescription Image"
                                                            />
                                                        </a>
                                                    )}
                                                </div>
                                            ))}
                                        </div>

                                    </div>

                                </>
                            )}
                        </div>
                    </div>

                    <Need_Help />

                </div>
            </div>

            {TrackSummaryData?.is_cancelable === 1 && (
                <div className="cancel-order-repo-wrap">
                    <button onClick={handleShow}>CANCEL ORDER</button>
                </div>
            )}

            {TrackSummaryData?.is_reorderable === 1 && (
                <div className="reorder-btn-data-repo sticky-bottom">
                    <button onClick={() => handleReorder(TrackSummaryData?.medicine_order_id)}>REORDER</button>
                </div>
            )}


            <CancelOrderModal show={show} setShow={setShow} OrderId={TrackSummaryData?.medicine_order_id} />
            <ToastBox />
        </div>
    )
}

export default ShipmentOrder