import React from 'react'
import Order_Card from './Order_Card';
import '../style/orderDetails.css'

const SingleOrderDetails = () => {
    return (
        <div className="home-review-banner">
            <div className="home-app-header order-details-repo-data">

                <div className="header-logo ">
                    <div className="user-name text-center">
                        <p>My Order Details</p>
                    </div>
                </div>
            </div>
            <div className="medicine-order-details-cover">
                <div className="heading-text-date">
                    <h6>Today</h6>
                </div>
                <Order_Card />
            </div>
        </div>
    )
}

export default SingleOrderDetails