import React, { useState } from 'react'
import '../style/orderDetails.css'
import { no_preview } from '../../Medicine/Images'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { ReorderApi } from '../../../Redux/actions/PostApiCall';
import LoaderAnime from '../../Medicine/animation/prescription_loader';
import toast from 'react-hot-toast'
import ToastBox from '../../Medicine/animation/ToastBox'

const Order_Card = ({ OrderData }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const reorderData = useSelector(state => state.reorder_medicine)
    console.log(reorderData?.data?.success);
    const [showModal, setShowModal] = useState(false)
    console.log(OrderData)

    const handleShipment = (item) => {
        const DataId = {
            medicine_order_id: item?.medicine_order_id,
            onemg_order_id: item?.onemg_order_id
        }
        navigate("/shipment-details", { state: DataId })
    }

    const handleReorder = async (item) => {
        setShowModal(true); // Show the modal initially

        const data = {
            medicine_order_id: item
        };

        try {
            await dispatch(ReorderApi(data));

            // Wait for the state to update before navigating
            setShowModal(false); // Close the modal

            if (reorderData?.data?.success === true) {
                // Use a timeout to ensure the modal closes before navigating
                setTimeout(() => {
                    const tabkeyUrl = "medicines";
                    const tabkeyData = { tabkey: tabkeyUrl };
                    navigate('/cart', { state: tabkeyData });
                }, 300); // Adjust the delay if necessary

            } else if (reorderData?.data?.success === false) {
                toast.error(reorderData?.data?.message);
            }
        } catch (error) {
            toast.error('An error occurred while processing the reorder.');
        }finally{
            setShowModal(false)
        }
    };


    return (
        <>

            {showModal && <LoaderAnime showModal={showModal} />}
            {OrderData?.data?.data?.map((item) => (
                <div className="order-cover-repo-wrap-box-con" onClick={() => handleShipment(item)} key={item?.medicine_order_id}>
                    <div className="order-box-coverdetails">
                        <div className="order-date-details">
                            <p>Ordered on {item?.ordered_at}</p>
                            <span
                                style={{
                                    backgroundColor: OrderData?.data?.status_color_code.find(codestatus => codestatus?.status === item?.order_status)?.color_code || "White",
                                }}
                            >
                                {item?.order_status}
                            </span>

                        </div>
                        <hr className='orderhr' />
                        <div className="orderDetails-product-box">
                            <div className="left-box-cover-details">
                                <div className="orderdetails-image-box">
                                    <img src={item?.medicines?.[0]?.banner_url ? item?.medicines?.[0]?.banner_url : no_preview}
                                        onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = no_preview;
                                        }} alt="order-image" />
                                </div>
                                <div className="details-box-repo-text">
                                    {item?.medicines?.[0] && (
                                        <>
                                            <h5 className='ellipsis-text'>Name: {item?.medicines?.[0]?.name}</h5>
                                            <p>Quantity: {item?.medicines?.[0]?.quantity}</p>
                                        </>
                                    )}
                                    <p>Order ID: {item?.onemg_order_id}</p>
                                </div>
                            </div>
                            <div className="right-box-cover-details">
                                {item?.is_reorderable === 1 && (
                                    <button
                                        className='reorder-btn-history'
                                        onClick={(e) => {
                                            e.stopPropagation(); // Prevents the click event from bubbling up
                                            handleReorder(item?.medicine_order_id); // Calls the handleReorder function with the order ID
                                        }}
                                    >
                                        Reorder
                                    </button>

                                )}
                                <button>View Details</button>
                            </div>
                        </div>
                    </div>
                    {item?.medicine_count > 0 && (
                        <div className="product-length-box">
                            <p>+ {item?.medicine_count} More Products</p>
                        </div>
                    )}
                </div >
            ))}

            <ToastBox/>
        </>
    )
}

export default Order_Card