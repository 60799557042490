import React, { useEffect, useState } from 'react';
import Medicine_Header from '../Components/Medicine_Header';
import Cart_Card from '../Components/Cart_Card';
import { useDispatch, useSelector } from 'react-redux';
import Medicine_footer from '../Components/Medicine_footer';
import { Need_Help_Image, package_banner_image } from '../Images';
import '../Medicine_Style/order-style.css';
import useLocationState from '../hooks/useLocationState';
import { fetchMedicineCartList, fetchMedicineOrderList, fetchPackageList } from '../../../Redux/actions/GetApiCall';
import { appDeliverLocation } from '../../../images';
import { useNavigate } from 'react-router-dom';
import { captureOrderMedicine, orderMedicine } from '../../../Redux/actions/PostApiCall';
import useRazorpay from 'react-razorpay';
import LoaderAnime from "../animation/prescription_loader";
import Medicine_Success from '../animation/Medicine_Success';
import { set } from 'lodash';
import toast from 'react-hot-toast';

const Medicine_order = ({tabkey, setCartData, setWalletId, setSelectedPackage, walletId, selectedPackage, setShowModal, showModal }) => {
    // const Title = "Placed Order";
    const [Razorpay] = useRazorpay();
    const dispatch = useDispatch();
    const naviagte = useNavigate();
    const [cartListData, setCartListData] = useState([]);
    const [cartUpdatedData, setCartUpdatedData] = useState([]);
    const { data: MedicineData, loader: cartLoader } = useSelector(state => state.medicine_cart_list);
    const { data: MedicineOrderData } = useSelector(state => state.medicine_order_list);
    const orderedData = useSelector(state => state.order_medicine.data);
    const orderResponse = useSelector(state => state.order_medicine)
    const packagesData = useSelector(state => state.package_list);
    const [selectedData, setSelectedData] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const AppToken = localStorage.getItem("APP_TOKEN");
    const [paymentClose, setPaymentClose] = useState(false);
    console.log(orderResponse)

    useEffect(() => {
        dispatch(fetchPackageList())
        dispatch(fetchMedicineOrderList())
    }, [dispatch])

    useEffect(() => {
        if (cartListData) {
            setCartData(cartListData)
        }
    }, [cartListData, setCartData])

    useEffect(() => {
        if (cartListData) {
            setCartUpdatedData(cartListData);
        } else if (MedicineData) {
            setCartUpdatedData(MedicineData);
        }
    }, [MedicineData, cartListData]);

    const handleSelectPackage = (e) => {
        const selectedItem = JSON.parse(e.target.value);
        setSelectedPackage(selectedItem?.id);
        setSelectedData(selectedItem)
    }

    const handleWalletChange = (e) => {
        const isCheckedNow = e.target.checked;
        setIsChecked(isCheckedNow);
        if (isCheckedNow) {
            setWalletId(selectedData?.id);
        } else {
            setWalletId("");
        }
    };

    useEffect(() => {
        if (orderedData?.success === true && orderedData?.out_of_stock?.length === 0 && orderedData?.is_payable === 0) {
            setSuccessModal(true);
            setTimeout(() => {
                setSuccessModal(false)
                naviagte('/order-details');
            }, 3000);
        }
        else if (orderedData?.success === false && orderedData?.out_of_stock?.length === 0) {
            setSuccessModal(true);
            setTimeout(() => {
                setSuccessModal(false)
                naviagte('/medicines');
            }, 3000);
        }
        else if (orderedData?.success === true && orderedData?.out_of_stock?.length > 0) {
            toast.error(orderedData?.message);
            setTimeout(() => {
                naviagte('/cart');
            }, 2000);
        }
        else {
            setSuccessModal(false)
        }
    }, [orderedData, naviagte]);

    useEffect(() => {
        if (orderedData?.success === true && orderedData?.is_payable === 1 && orderedData?.out_of_stock?.length === 0 && orderResponse?.status === "success") {
            const optionsData = orderedData?.razorpay_details;

            const options = {
                key: optionsData?.key,
                amount: optionsData?.amount,
                currency: optionsData?.currency,
                name: optionsData?.name,
                description: optionsData?.description,
                order_id: optionsData?.razorpay_order_id,
                handler: async (response) => {
                    console.log("Payment successful", response);
                    if (response) {
                        const data = {
                            medicine_order_id: MedicineOrderData?.data?.medicine_order_id,
                            transaction_id: response?.razorpay_payment_id
                        }
                        setShowModal(true)
                        try {
                            const cartUpdateData = await fetch("https://wellness.medibhai.co.in/api/medicine/capture-payment-details", {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: AppToken,
                                },
                                body: JSON.stringify(data),
                            });

                            console.log(cartUpdateData)

                            const CartpaymentData = await cartUpdateData.json();
                            console.log(CartpaymentData)
                            if (CartpaymentData.success === true) {
                                setSuccessModal(true);
                                setTimeout(() => {
                                    setSuccessModal(false)
                                    naviagte('/order-details');
                                    // window.location.reload();
                                }, 3000);
                            }
                            else if (cartUpdateData.ok === false) {
                                setSuccessModal(true);
                                setTimeout(() => {
                                    setSuccessModal(false)
                                    naviagte('/medicines');
                                    // window.location.reload();
                                }, 3000);
                            }
                        } catch (error) {
                            console.log(error);
                        } finally {
                            setShowModal(false)
                        }
                        // await dispatch(captureOrderMedicine(data))
                    }
                },
                prefill: {
                    name: optionsData?.prefill?.name,
                    email: optionsData?.prefill?.email,
                    contact: optionsData?.prefill?.contact,
                },
                theme: {
                    color: "#3399cc",
                },
                modal: {
                    ondismiss: function () {
                        setPaymentClose(true)
                        setSuccessModal(true);
                        setTimeout(() => {
                            setSuccessModal(false);
                            naviagte('/medicines');
                            // window.location.reload();
                            setPaymentClose(false);
                        }, 3000);
                    }
                }

                // callback_url:optionsData?.callback_url
            };

            const rzp1 = new Razorpay(options);
            rzp1.open();
        }
    }, [orderedData]);


    useEffect(() => {
        if (MedicineData?.available_stock?.length === 0 && tabkey === "medicines") {
            naviagte('/medicines')
        }
    }, [MedicineData])


    const handleAddressChange = () => {
        naviagte('/address-list')
    }

    useEffect(() => {
        if (selectedPackage !== "" || walletId !== "") {
            dispatch(fetchMedicineCartList(selectedPackage, walletId))
        }
    }, [selectedPackage, walletId])
    return (
        <>
            {/* <Medicine_Header Title={Title} cartData={cartListData} /> */}

            {showModal && <LoaderAnime showModal={showModal} />}

            <Cart_Card setCartListData={setCartListData} MedicineData={MedicineData} cartListData={cartListData} cartLoader={cartLoader} />

            {/* <div
                className="offer-banner-packages"
                style={{
                    backgroundImage: `url(${package_banner_image})`,
                    width: '100%', // or specific width like '500px'
                    height: '14%', // or any height you want
                    backgroundSize: 'cover', // This ensures the image covers the entire div
                    backgroundRepeat: 'no-repeat', // This prevents the image from repeating
                    borderRadius: '20px',
                    overflow: 'hidden'
                }}
            >
                <div className="discount-text">
                    <p>Flat 12%</p>
                </div>
                <div className="package-discount-name">
                    <p>Sliver</p>
                </div>
            </div> */}

            <div className="packages-cover-wrapper">
                <div className="options-wrapper">
                    <select value={selectedData ? JSON.stringify(selectedData) : ''} onChange={handleSelectPackage}>
                        <option value="" disabled>Select Package</option> {/* Default selected and disabled */}
                        {packagesData?.data?.active_package?.map((item) => (
                            <option key={item.id} value={JSON.stringify(item)}>
                                {item.name}
                            </option>
                        ))}
                    </select>

                </div>
            </div>

            {selectedData !== "" && (
                <div className="wallet-repo-wrapper">
                    <div className="check-data-wrapper">
                        <div className="checkbox-wrapper">
                            <input
                                id="terms-checkbox-37"
                                name="checkbox"
                                type="checkbox"
                                checked={isChecked} // Bind to the checkbox state
                                onChange={handleWalletChange} // Handle change event directly
                            />
                            <label className="terms-label" htmlFor="terms-checkbox-37">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 200 200" className="checkbox-svg">
                                    <mask fill="white" id="path-1-inside-1_476_5-37">
                                        <rect height="200" width="200"></rect>
                                    </mask>
                                    <rect mask="url(#path-1-inside-1_476_5-37)" strokeWidth="40" className="checkbox-box" height="200" width="200"></rect>
                                    <path strokeWidth="15" d="M52 111.018L76.9867 136L149 64" className="checkbox-tick"></path>
                                </svg>
                            </label>
                        </div>
                        <div className="wrapper-text-content-box">
                            <p>Medibhai Wallet</p>
                            <span>Remaining Balance : ₹ {selectedData?.wallet_details?.medicine?.remaining}</span>
                        </div>
                    </div>
                    <div className="used-data-wrapper">
                        <p>You Used</p>
                        <p>₹ {selectedData?.wallet_details?.medicine?.utilized}</p>
                    </div>
                </div>
            )}

            <div className="payment-repo-cover-box">
                <div className="payment-text-repo">
                    <p>Payment Summary</p>
                </div>

                <div className="payment-summary-box-repo">
                    {cartUpdatedData?.vas_charges?.details?.map((item, index) => (
                        <div className="price-payment-sum-repo" key={index}>
                            <p>{item.display_text}</p>
                            <p>₹ {item.amount?.toLocaleString('en-IN')}</p>
                        </div>
                    ))}
                    {cartUpdatedData?.wallet_deducted?.map((item, index) => (
                        <div className="price-payment-sum-repo" key={index}>
                            <p>MB Wallet</p>
                            <p>- ₹ {item.amount?.toLocaleString('en-IN')}</p>
                        </div>
                    ))}
                </div>
                <div className="fin-text-repo">
                    <div className="price-payment-sum-repo total-pay-text">
                        <p>Total Payable</p>
                        <p>₹ {cartUpdatedData?.payable_amount?.toLocaleString('en-IN')}</p>
                    </div>
                    <div className="price-payment-sum-repo total-save-text">
                        <p>Total Savings</p>
                        <p>₹ {cartUpdatedData?.total_saving?.toLocaleString('en-IN')}</p>
                    </div>
                </div>
            </div>

            <div className="need-help-repo-textt">
                <div className="image-data-repo">
                    <img src={Need_Help_Image} alt="help-image" />
                </div>
                <div className="text-help-box">
                    <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. </p>
                    <button>Click Here</button>
                </div>
            </div>

            <div className="address-wrapper-box-order">
                <div className="address-content-box-save">
                    <img
                        src={appDeliverLocation}
                        width="100%"
                        alt="location"
                    />

                    <div className="address-content-repo-text">
                        <p>Deliver to {MedicineOrderData?.data?.address?.address_type} <br /> {MedicineOrderData?.data?.address?.address},{MedicineOrderData?.data?.address?.landmark},<br />{MedicineOrderData?.data?.address?.city},{MedicineOrderData?.data?.address?.state}-{MedicineOrderData?.data?.address?.pincode}</p>
                    </div>
                </div>

                <div className="chnage-address-btn">
                    <button onClick={handleAddressChange}>Change</button>
                </div>
            </div>

            {orderedData && <Medicine_Success showModal={successModal} message={paymentClose ? "Payment Cancelled" : orderedData?.message} status={paymentClose ? false : orderedData?.success} />}
        </>
    )
}

export default Medicine_order;