import React from "react";
import { useNavigate } from "react-router-dom";
import { selectedMedicineAddress, selectedDiagnosticAddress } from "../../../Redux/actions/PostApiCall";
import { useDispatch, useSelector } from "react-redux";
import useLocationState from "../hooks/useLocationState";

const Medicine_footer = ({ presMedi, addressMedi, cartData, tabkey, labCartData, AddressId, mediCart, handleOrderMedicine, handleDiagnosticOrder }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tabKeyUrl = useLocationState();
  const medicartData = tabKeyUrl?.cartdata || cartData;
  // const mediCartData=useSelector(state=>state.medicine_cart_list);
  // console.log(mediCartData)
  const WebLink = window.location.pathname;
  const activeTabKey = tabKeyUrl == "diagnostics" ? "diagnostics" : tabKeyUrl?.tabkey || tabkey;
  // Determine which data to use based on tabkey
  const dataToUse = activeTabKey === "medicines" ? medicartData : labCartData ;

  // Determine the total amount and item count based on activeTabKey
  const totalAmount =
    activeTabKey === "medicines"
      ? dataToUse?.payable_amount
      :  dataToUse?.calculation?.find((item) => item?.key === "Total Payable Price")?.value;


  const totalItems =
    activeTabKey === "medicines"
      ? dataToUse?.total_items || ( dataToUse?.total_item )
      :  dataToUse?.cart_count;

  const handleCartLink = async () => {
    const Title = "Upload Prescription";
    const AddressTitle = "medicines"
    const cartLink = window.location.pathname;

    if (AddressId !== undefined) {
      const addressData = {
        address_id: AddressId,
      };
      if (activeTabKey === "medicines") {
        await dispatch(selectedMedicineAddress(addressData));
        navigate("/place-order", { state: AddressTitle });
      } else {
        await dispatch(selectedDiagnosticAddress(addressData));
        navigate("/date-timeslot", { state: AddressTitle });
      }
    }

    else if (
      activeTabKey === "medicines" &&
      dataToUse?.prescription_required === 1 && presMedi === 3
    ) {
      navigate("/prescription", { state: Title });

    }
    else if (AddressId !== undefined && activeTabKey === "medicines") {
      navigate("/place-order", { state: AddressTitle });

    }
    else if (
      activeTabKey === "medicines" &&
      dataToUse?.prescription_required === 0 && mediCart === 1) {
      navigate("/address-list", { state: AddressTitle });
    }
    else if (cartLink === "/date-timeslot" && activeTabKey === "diagnostics") {
      navigate("/place-order");

    } else if (
      activeTabKey === "medicines" &&
      dataToUse?.prescription_required === 0 && addressMedi === 2
    ) {
      navigate("/medicines-address", { state: AddressTitle });
    } else if (cartLink === "/cart" && activeTabKey === "diagnostics") {
      navigate("/patients");
    }
    else {
      navigate("/cart", { state: activeTabKey });
    }
  };

  // console.log(activeTabKey , tabKeyUrl)

  return (
    <div className="footer-repo-head-boxs sticky-lg-bottom sticky-md-bottom sticky-bottom">
      <div className="product-price-repo-store">
        <p>
          Total <span>₹ {totalAmount || 0}</span>
        </p>
        <span className="item-qty">{totalItems || 0} Item(s)</span>
      </div>
      <div className="product-btn-box-store">
        <button
        className="repo-cont-btn"
          onClick={() => {
            if (WebLink === "/place-order") {
              if (activeTabKey === "medicines") {
                handleOrderMedicine();
              } else if (activeTabKey === "diagnostics") {
                handleDiagnosticOrder();
              }
            } else {
              handleCartLink();
            }
          }}
          disabled={AddressId === null}
        >
          {WebLink === "/place-order" ? "Place Order" : "CONTINUE"}
        </button>
      </div>
    </div>
  );
};

export default Medicine_footer;