import React, { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { IoMdArrowDropdown, IoIosSearch } from "react-icons/io";
import { useDispatch } from "react-redux";
import NoDataFoundComp from "../../components/NoDataFound";
import { FaAngleRight } from "react-icons/fa6";
import { fetchDiagnosticTestList } from "../../Redux/actions/GetApiCall";
import { debounce } from "lodash";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import MediLoader from "../Medicine/animation/loader_anime";
import Medicine_Header from "../Medicine/Components/Medicine_Header";

import { ArrowBack, appCartIcon, appHeartIcon } from "../../images";

const SearchTestMed = () => {
  const navigate = useNavigate();
  const [CartData, setCartData] = useState(
    JSON.parse(localStorage.getItem("CartData")) || []
  );
  const [searchQuery, setSearchQuery] = useState("");


  const handleNavigate = () => {
    navigate("/cart/diagnostictest");
  };

  const dispatch = useDispatch();
  const {
    data: SearchData,
    error: SearchError,
    loader: SearchLoader,
  } = useSelector((state) => state?.search_diagnostic_test);

  const debounceFetchdiagnostic = useCallback(
    debounce((value) => {
      if (value.trim() !== "") {
        dispatch(fetchDiagnosticTestList(value));
      }
    }, 1000), // 1s debounce delay
    [dispatch]
  );

  useEffect(() => {
    debounceFetchdiagnostic(searchQuery);
    return () => {
      debounceFetchdiagnostic.cancel();
    };
  }, [searchQuery, debounceFetchdiagnostic]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
  };

  const getHighlightedText = (text, highlight) => {
    const parts = text.split(new RegExp(`(${highlight})`, "gi"));
    return parts.map((part, index) =>
      part.toLowerCase() === highlight.toLowerCase() ? (
        <strong key={index} style={{ color: "black" }}>
          {part}
        </strong>
      ) : (
        part
      )
    );
  };

  const handleNavigateDescription = (type, id) => {
    const path =
      type === "test"
        ? `/test-description/${id}`
        : `/package-description/${id}`;
    navigate(path);
  };

  return (
    <>
      <div className="home-review-banner">
        <Medicine_Header Title="Search Lab Test" />
        <div className="app-searh-section">
          <div className="inputWithIcon">
            <input
              type="text"
              placeholder="Search"
              value={searchQuery}
              //   onFocus={handleSearchInputClick}
              onChange={(e) => handleInputChange(e)}
              //   onBlur={handleSearchInputBlur}
            />
            <button onClick="">
              <IoIosSearch />
            </button>
          </div>
        </div>
        <div className="search-test-main-wrapper mx-auto">
          <div className="suggestion-main-cover-wapper">
            <div className="suggestion-list-cover">
              {SearchLoader ? (
                <MediLoader />
              ) : SearchData?.data?.length > 0 ? (
                SearchData?.data?.map((item) => (
                  <div
                    className="search-result-data-box-cover"
                    onClick={() =>
                      handleNavigateDescription(item.type, item.id)
                    }
                  >
                    <div className="search-data-box-cont">
                      <p>{getHighlightedText(item?.name, searchQuery)}</p>
                    </div>
                    <div className="search-left-arrow-box">
                      <FaAngleRight />
                    </div>
                  </div>
                ))
              ) : (
                <NoDataFoundComp />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchTestMed;
