import { React, useContext, useEffect, useRef, useState } from "react";
import ProfileHeader from "../Components/Header";
import {
  ArrowBack,
  appCart,
  appNotification,
  headerLogo,
  appWalletprofile,
  appShapeProfile,
  appMedicineProfile,
  NoWallet,
} from "../../../images";
import Accordion from 'react-bootstrap/Accordion';
import { NavLink } from "react-router-dom";
import Loader from '../../../Animation/Loader';
import NoDataFoundComp from "../../../components/NoDataFound";
import { REQUESTOPTIONS, MY_ACCOUNT_API, PACKAGES_API } from "../../../Constants";
import { WalletTransactionList } from "../../../data";
import { FaChevronLeft } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";
const MyWalletUtilization = () => {
  const [myPackageData, setmyPackageData] = useState(false);
  const [isPackageDataloading, setPackageDataLoading] = useState(true);
  const [myWalletTransactionData, setmyWalletTransactionData] = useState([]);
  const [packagesData, setPackagesData] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [filteredData, setFilteredData] = useState(myWalletTransactionData);
  const [savedData, setSavedData] = useState([]);
  const [showSavedData, setShowSavedData] = useState(false);
  const [walletData, setWalletData] = useState([])

  const PackagesData = async () => {
    try {
      const fetchpackage = await fetch(`${PACKAGES_API}`, REQUESTOPTIONS);
      if (!fetchpackage.ok) {
        console.error(
          "API error:",
          fetchpackage.status,
          fetchpackage.statusText
        );
        const errorData = await fetchpackage.json().catch(() => null);
        console.error("Error details:", errorData);
        throw new Error("Network response was not ok");
      }

      const packagesDataResponse = await fetchpackage.json();
      setPackagesData(packagesDataResponse?.user_package)
    } catch (error) {
      console.error(error)
    }
  }
  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    const selectedPackageData = packagesData.find(data => data.id.toString() === selectedValue);
    setSelectedPackage(selectedValue);
    setSearchInput('');
    setShowSavedData(false)
    setSavedData([])
  };
  useEffect(() => {
    PackagesData();
  }, [])

  useEffect(() => {
    // Pre-select the first option initially
    if (packagesData && packagesData.length > 0) {
      setSelectedPackage(packagesData[0].id);
    }
  }, [packagesData]);

  useEffect(() => {
    const FetchPackageData = async () => {
      setPackageDataLoading(true);
      try {
        const response = await fetch(
          `${MY_ACCOUNT_API}/${selectedPackage}`, REQUESTOPTIONS);
        const res = await response.json();
        if (res.status) {
          setmyPackageData(res?.data?.my_package_data[0]);
          setmyWalletTransactionData(
            res?.data?.wallet_data?.wallet_transaction
          );
          setWalletData(res?.data?.wallet_data);

          setPackageDataLoading(false);
        } else {
          setPackageDataLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    };

    FetchPackageData();
  }, [selectedPackage]);


  useEffect(() => {
    setFilteredData(myWalletTransactionData);
  }, [myWalletTransactionData]);

  const dataToDisplay = filteredData ? filteredData : myWalletTransactionData;


  const scrollRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleItemClick = (index) => {
    setActiveIndex(index);

    const selectedItem = WalletTransactionList[index];

    let filtered;

    if (selectedItem.value === "All") {
      filtered = myWalletTransactionData;
    } else {
      filtered = myWalletTransactionData.filter(walletData =>
        walletData.module_name.toLowerCase().includes(selectedItem.value.toLowerCase())
      );
    }
    setFilteredData(filtered);
  };



  const handleScrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        left: -200,
        behavior: 'smooth'
      });
    }
  };

  const handleScrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        left: 200,
        behavior: 'smooth'
      });
    }
  };

  return (
    <>
      <div className="home-review-banner">
        <div className="home-app-header">

          <div className="header-logo">
            <img src={headerLogo} width="100%" alt="header-logo" />
            <div className="user-name">
              <p>My Wallet Utilization</p>
            </div>
          </div>
          <div className="icons-box">
            <div className="notification-icon">
              <NavLink>
                <img src={appNotification} width="100%" alt="notification" />
              </NavLink>
            </div>
            <div className="cart-icon">
              <NavLink to="/emptycart">
                <img src={appCart} width="100%" alt="review" />
              </NavLink>
            </div>
          </div>
        </div>
        {isPackageDataloading ? (
          <Loader />
        ) : (
          <>
            <div className="my-wallet-utilization-main-wrapper-cover">
              <>
                <div className="wallet-points-box">

                  <div className="ope-select-data-box wallet-satate">
                    <p>Please Select the Package</p>
                    <select value={selectedPackage} onChange={handleSelectChange}>
                      {packagesData?.map((data, index) => (
                        <option key={index} value={data?.id}>{data?.package_name}</option>
                      ))}
                    </select>
                  </div>
                </div>
                    <div className="wallet-points-box-cover">
                      <div className="avail-wallet-data">
                        <div className="wallet-point-heading">
                          <p>Available Points</p>
                          <h6>{walletData?.pending_amount}</h6>
                        </div>
                        <div className="wallet-point-image">
                          <img src={appWalletprofile} width="100%" alt="review" />
                        </div>
                      </div>
                      <div className="total-dev-repo-point">
                        <div className="wallet-data-boxs-can">
                          <h5>{walletData?.wallet_amount}</h5>
                          <p>Total <br /> Points</p>
                        </div>
                        <div className="wallet-data-boxs-can">
                          <h5>{`${walletData?.wallet_amount - walletData?.pending_amount}`}</h5>

                          <p>Used <br /> Points</p>
                        </div>
                        <div className="wallet-data-boxs-can">
                          <h5>{walletData?.refunded_amount}</h5>
                          <p>Refunded <br /> Points</p>
                        </div>
                      </div>
                    </div>
                {myWalletTransactionData && myWalletTransactionData.length > 0 ? (
                  <>
                    <div className="wallet-boxs-bep-repo-so">
                      <div className="wallet-filter-box-repo-data" ref={scrollRef}>
                        {WalletTransactionList.map((item, index) => (
                          <div
                            className={`wallet-filter-box-repo-data-cover ${index === activeIndex ? 'active' : ''}`}
                            key={index}
                            onClick={() => handleItemClick(index)}
                          >
                            <div className="wallet-filter-box-repo-data-cover-inner">
                              <p>{item?.value}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="button-repo-wallet">
                        <button onClick={handleScrollLeft}><FaChevronLeft /></button>
                        <button onClick={handleScrollRight}><FaChevronRight /></button>
                      </div>
                    </div>


                    <div className="wallet-categories-section">
                      {dataToDisplay.length === 0 ? (
                        <div className="no-data-avail">
                          <p>No results found</p>
                        </div>
                      ) : (
                        <div className="wallet-categories-main-box-wrapper">
                          <div className="trans-text-box">
                            <h5>Transistion : </h5>
                          </div>
                          {dataToDisplay.map((walletData) => (
                            <div className="wallet-categories-box-wrapper" key={walletData.id}>
                              <div className="wallet-categories-content-box">
                                <div className="pack-accord-btn">
                                  <div className="wallet-categories-content-box-cover">
                                    <p>
                                      Category: <span className="category-text">{walletData?.module_name !== "" ? walletData?.module_name : ""}</span>
                                    </p>
                                    <p>
                                      Points Used: <span className="point">{walletData?.points}</span>
                                    </p>
                                    <div className="points-text-border"></div>
                                    <p>
                                      Date of Transaction: <span>{walletData?.crdt_dt}</span>
                                    </p>
                                  </div>
                                </div>

                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>

                  </>
                ) : (
                  <div className="wallet-categories-section-image">
                    <img src={NoWallet} alt="" />
                  </div>
                )}
              </>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default MyWalletUtilization;
