import { createApiAction } from "./apiActions";

const API_BASE_URL = "https://wellness.medibhai.co.in/api";
const AppToken = localStorage.getItem("APP_TOKEN");

const endpoints = {
  UPLOAD_PRESCRIPTION: `${API_BASE_URL}/medicine/save-selected-prescription`,
  DELETE_PRESCRIPTION: `${API_BASE_URL}/medicine/delete-uploaded-prescription`,
  MEDICINE_CART_ADD: `${API_BASE_URL}/medicine/medicine-cart-store`,
  MEDICINE_CART_DELETE: `${API_BASE_URL}/medicine/remove-cart-item`,
  CALL_DOCTOR: `${API_BASE_URL}/medicine/call-doctor-request`,
  SAVE_MEDICINE_ADDRESS: `${API_BASE_URL}/medicine/store-user-address`,
  DELETE_MEDICINE_ADDRESS: `${API_BASE_URL}/medicine/delete-user-address`,
  SELECTED_MEDICINE_ADDRESS: `${API_BASE_URL}/medicine/save-selected-address`,
  ORDER_MEDICINE: `${API_BASE_URL}/medicine/order-medicine`,
  CAPTURE_ORDER_MEDICINE: `${API_BASE_URL}/medicine/capture-payment-details`,
  CANCEL_ORDER_MEDICINE: `${API_BASE_URL}/medicine/cancel-medicine-order`,
  REORDER_ORDER_MEDICINE: `${API_BASE_URL}/medicine/medicine-reorder-api`,


    //DIAGNOSTICS -----------------
    DIAGNOSTIC_CART_ADD : `${API_BASE_URL}/diagnostic/diagnostic-cart`,
    DIAGNOSTIC_REMOVE_CART : `${API_BASE_URL}/diagnostic/delete-cart-item`,
    ADD_PATIENT_MEMBERS : `${API_BASE_URL}/diagnostic/create-member`,
    DELETE_PATIENT_MEMBERS : `${API_BASE_URL}/diagnostic/delete-members`,
    CALL_DOCTOR: `${API_BASE_URL}/call-doctor-request`,
    SAVE_PATIENT_MEMBERS : `${API_BASE_URL}/diagnostic/save-selected-member`,
    DIAGNOSTIC_PLACE_ORDER_DETAILS : `${API_BASE_URL}/diagnostic/place-order-details`,
    SAVE_TIME_SLOT : `${API_BASE_URL}/diagnostic/save-timeslot`,
    ORDER_DIAGNOSTIC : `${API_BASE_URL}/diagnostic/order-diagnostic`,
    SELECTED_DIAGNOSTIC_ADDRESS : `${API_BASE_URL}/diagnostic/save-address`,
    CAPTURE_DIAGNOSTIC_PAYMENT_DETAILS : `${API_BASE_URL}/diagnostic/capture-payment-details`,

    //AUTH --------------
    AUTH_PACKAGE_REGISTRATION : `${API_BASE_URL}/package/generate-web-package-registration-otp`,
    VERIFY_PACKAGE_OTP : `${API_BASE_URL}/package/verify-package-registration-otp`,
    CAPTURE_USER_PACKAGE : `${API_BASE_URL}/package/capture-user-package-registration`,
    GENERATE_OTP : `${API_BASE_URL}/generate-web-otp`,
    VERIFY_LOGIN_OTP : `${API_BASE_URL}/verify-web-otp`,
};

const createJsonRequestOptions = (method, body, options = {}) => ({
  method,
  headers: {
    "Content-Type": "application/json",
    Authorization: AppToken,
  },
  body: JSON.stringify(body),
  ...options,
});

const createFormRequestOptions = (method, formData, options = {}) => ({
  method,
  headers: {
    Authorization: AppToken,
  },
  body: formData,
  ...options,
});

export const uploadPrescription = (prescriptionData) =>
  createApiAction("UPLOAD_PRESCRIPTION", (options) =>
    fetch(
      endpoints.UPLOAD_PRESCRIPTION,
      createFormRequestOptions("POST", prescriptionData, options)
    )
  );

export const deletePrescription = (prescriptionData) =>
  createApiAction("DELETE_PRESCRIPTION", (options) =>
    fetch(
      endpoints.DELETE_PRESCRIPTION,
      createJsonRequestOptions("POST", prescriptionData, options)
    )
  );
  
  export const CancelOrder = (data) =>
  createApiAction("CANCEL_ORDER_MEDICINE", (options) =>
    fetch(
      endpoints.CANCEL_ORDER_MEDICINE,
      createJsonRequestOptions("POST", data, options)
    )
  ); 
  
  export const ReorderApi = (data) =>
  createApiAction("REORDER_ORDER_MEDICINE", (options) =>
    fetch(
      endpoints.REORDER_ORDER_MEDICINE,
      createJsonRequestOptions("POST", data, options)
    )
  );

export const medicineCartAdd = (cartData) =>
  createApiAction("MEDICINE_CART_ADD", (options) =>
    fetch(
      endpoints.MEDICINE_CART_ADD,
      createJsonRequestOptions("POST", cartData, options)
    )
  );

export const medicineCartDelete = (cartData) =>
  createApiAction("MEDICINE_CART_DELETE", (options) =>
    fetch(
      endpoints.MEDICINE_CART_DELETE,
      createJsonRequestOptions("POST", cartData, options)
    )
  );

export const callDoctor = (cartData) =>
  createApiAction("CALL_DOCTOR", (options) =>
    fetch(
      endpoints.CALL_DOCTOR,
      createJsonRequestOptions("POST", cartData, options)
    )
  );

export const saveMedicine = (addressData) =>
  createApiAction("SAVE_MEDICINE_ADDRESS", (options) =>
    fetch(
      endpoints.SAVE_MEDICINE_ADDRESS,
      createJsonRequestOptions("POST", addressData, options)
    )
  );
export const orderMedicine = (orderData) =>
  createApiAction("ORDER_MEDICINE", (options) =>
    fetch(
      endpoints.ORDER_MEDICINE,
      createJsonRequestOptions("POST", orderData, options)
    )
  );
export const captureOrderMedicine = (orderData) =>
  createApiAction("CAPTURE_ORDER_MEDICINE", (options) =>
    fetch(
      endpoints.CAPTURE_ORDER_MEDICINE,
      createJsonRequestOptions("POST", orderData, options)
    )
  );

export const deleteMedicineAddress = (addressId) =>
  createApiAction("DELETE_MEDICINE_ADDRESS", (options) =>
    fetch(
      endpoints.DELETE_MEDICINE_ADDRESS,
      createJsonRequestOptions("POST", addressId, options)
    )
  );

export const selectedMedicineAddress = (addressData) =>
  createApiAction("SELECTED_MEDICINE_ADDRESS", (options) =>
    fetch(
      endpoints.SELECTED_MEDICINE_ADDRESS,
      createJsonRequestOptions("POST", addressData, options)
    )
  );




//DIAGNOSTICS --------------------------------

export const diagnosticCartAdd = (cartData) => createApiAction(
    'DIAGNOSTIC_CART_ADD',
    (options) => fetch(endpoints.DIAGNOSTIC_CART_ADD, createJsonRequestOptions('POST', cartData, options))
);

export const diagnosticRemoveCart = (cartData) =>
  createApiAction("DIAGNOSTIC_REMOVE_CART", (options) =>
    fetch(
      endpoints.DIAGNOSTIC_REMOVE_CART,
      createJsonRequestOptions("POST", cartData, options)
    )
  );

export const addPatientMembers = (patientData) =>
  createApiAction("ADD_PATIENT_MEMBERS", (options) =>
    fetch(
      endpoints.ADD_PATIENT_MEMBERS,
      createJsonRequestOptions("POST", patientData, options)
    )
  );

export const deletePatient = (patient_id) => createApiAction(
    'DELETE_PATIENT_MEMBERS',
    (options) => fetch(endpoints.DELETE_PATIENT_MEMBERS, createJsonRequestOptions('POST', patient_id, options))
);

export const savePatientMembers = (patientData) => createApiAction(
    'SAVE_PATIENT_MEMBERS',
    (options) => fetch(endpoints.SAVE_PATIENT_MEMBERS, createJsonRequestOptions('POST', patientData, options))
);

export const fetchDiagnosticPlaceOrderDetails = (placeOrderData) => createApiAction(
    'DIAGNOSTIC_PLACE_ORDER_DETAILS',
    (options) => fetch(endpoints.DIAGNOSTIC_PLACE_ORDER_DETAILS, createJsonRequestOptions('POST', placeOrderData, options))
);

export const orderDiagnostic = (order_diagnostic_data) => createApiAction(
  'ORDER_DIAGNOSTIC',
  (options) => fetch(endpoints.ORDER_DIAGNOSTIC, createJsonRequestOptions('POST', order_diagnostic_data, options))
);

export const package_registration = (signUpData) => createApiAction(
    'AUTH_PACKAGE_REGISTRATION',
    (options) => fetch(endpoints.AUTH_PACKAGE_REGISTRATION, createJsonRequestOptions('POST', signUpData, options))
);

export const verify_package_otp = (signUpData) => createApiAction(
    'VERIFY_PACKAGE_OTP',
    (options) => fetch(endpoints.VERIFY_PACKAGE_OTP, createJsonRequestOptions('POST', signUpData, options))
);


export const capture_user_package = (signUpData) => createApiAction(
    'CAPTURE_USER_PACKAGE',
    (options) => fetch(endpoints.CAPTURE_USER_PACKAGE, createJsonRequestOptions('POST', signUpData, options))
);

export const generate_otp = (loginData) => createApiAction(
    'GENERATE_OTP',
    (options) => fetch(endpoints.GENERATE_OTP, createJsonRequestOptions('POST', loginData, options))
);

export const verify_login_otp = (loginData) => createApiAction(
    'VERIFY_LOGIN_OTP',
    (options) => fetch(endpoints.VERIFY_LOGIN_OTP, createJsonRequestOptions('POST', loginData, options))
);

export const save_time_slot = (timeSlotData) => createApiAction(
  'SAVE_TIME_SLOT',
  (options) => fetch(endpoints.SAVE_TIME_SLOT, createJsonRequestOptions('POST', timeSlotData, options))
);

export const capture_diagnostic_payment_details = (capture_data) => createApiAction(
  'CAPTURE_DIAGNOSTIC_PAYMENT_DETAILS',
  (options) => fetch(endpoints.CAPTURE_DIAGNOSTIC_PAYMENT_DETAILS, createJsonRequestOptions('POST', capture_data, options))
);


export const selectedDiagnosticAddress = (addressData) =>
  createApiAction("SELECTED_DIAGNOSTIC_ADDRESS", (options) =>
    fetch(
      endpoints.SELECTED_DIAGNOSTIC_ADDRESS,
      createJsonRequestOptions("POST", addressData, options)
    )
  );
