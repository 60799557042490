import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { medicineCartAdd, medicineCartDelete } from '../../../Redux/actions/PostApiCall';
import { fetchMedicineCartList, fetchMedicinelist, fetchMedicineRecent, fetchSingleMedicinelist } from '../../../Redux/actions/GetApiCall';
import { useLocation } from 'react-router-dom';

const useCartFunction = (inputPara, setLoaderStatus, setCartListData) => {
    const [cart, setCart] = useState([]);
    const pincode = localStorage.getItem("pincode");
    const [loadingItems, setLoadingItems] = useState(new Set());
    const dispatch = useDispatch();
    const [deletedItems, setDeletedItems] = useState(new Set());
    const { data: mediData } = useSelector(state => state.medicine_recent_list);
    const cartStatus = useSelector(state => state.medicine_cart_add.status);
    const DeleteStatus = useSelector(state => state.medicine_cart_delete.status);
    const Similar = useSelector(state => state.medicine_cart_delete.similar);
    const CartData = useSelector(state => state.medicine_cart_add.data);
    const [availability, setAvailability] = useState({});
    const { data: MedicineData } = useSelector(state => state.medicine_cart_list);
    const { data: SingleMedicineData } = useSelector(state => state.single_medicine_list);
    const { data: MedicineListRepo } = useSelector(state => state.medicine_list);
    const location = useLocation();
    const data = location.state || {};

    useEffect(() => {
        dispatch(fetchMedicineCartList())
    }, [dispatch]);

    useEffect(() => {
        if (MedicineData && setCartListData !== undefined) {
            setCartListData(MedicineData);
        }
    }, [MedicineData])

    useEffect(() => {
        if (CartData && setCartListData !== undefined) {
            setCartListData(CartData);
        }
    }, [CartData]);


    useEffect(() => {
        if (cartStatus === 'success' && CartData) {
            const updatedCart = CartData.data.reduce((acc, cartItem) => {
                const itemAvailability = cartItem.is_available;
                setAvailability(prev => ({ ...prev, [cartItem.sku_id]: itemAvailability }));

                if (itemAvailability) {
                    const itemIndex = acc.findIndex(item => item.sku_id === cartItem.sku_id);
                    if (itemIndex !== -1) {
                        acc[itemIndex].quantity = cartItem.quantity || acc[itemIndex].quantity;
                    } else {
                        acc.push({ ...cartItem, quantity: cartItem.min_quantity });
                    }
                } else {
                    acc = acc.filter(item => item.sku_id !== cartItem.sku_id);
                }

                return acc;
            }, [...cart]);

            setCart(updatedCart);

            // Clear loader for all added items
            if (cartStatus !== 'loading') {
                setLoadingItems(prev => {
                    const newSet = new Set(prev);
                    CartData.data.forEach(item => newSet.delete(item.sku_id));
                    return newSet;
                });
            }
        }
    }, [cartStatus, CartData]);


    const updateCartInServer = async (updatedCart) => {
        const cartAddData = {
            pincode: pincode,
            cart: updatedCart,
        };
        await dispatch(medicineCartAdd(cartAddData));
    };

    const MedicineCartList = MedicineData?.available_stock;
    const MedicineDatas = mediData?.data;
    const SingleMedicineDatas = SingleMedicineData?.data;
    const SimilarMedicineDatas = MedicineListRepo?.data;

    useEffect(() => {
        const pathname = window.location.href;

        let itemsToProcess = [];

        if (pathname.includes('cart')) {
            itemsToProcess = Array.isArray(MedicineCartList) ? MedicineCartList : [];
        }
        else if (pathname.includes('place-order')) {
            itemsToProcess = Array.isArray(MedicineCartList) ? MedicineCartList : [];
        }

        else if (pathname.includes('med-list')) {
            itemsToProcess = Array.isArray(MedicineDatas) ? MedicineDatas : [];
        } else {
            itemsToProcess = Array.isArray(SimilarMedicineDatas) ? SimilarMedicineDatas :
                SingleMedicineDatas ? [SingleMedicineDatas] : [];
        }

        const newCartItems = [];

        itemsToProcess.forEach(item => {
            if (item?.is_available === 1 && item?.in_cart === 1 && !deletedItems.has(item.sku_id)) {
                const updateCart = {
                    sku_id: item.sku_id,
                    quantity: item.quantity,
                    min_quantity: item.min_quantity,
                    max_quantity: item.max_quantity,
                };

                const isItemInCart = cart.some(cartItem => cartItem.sku_id === item.sku_id);
                if (!isItemInCart) {
                    newCartItems.push(updateCart);
                }
            }
        });

        if (newCartItems.length > 0) {
            setCart(prevCart => {
                return [...prevCart, ...newCartItems];
            });
        }
    }, [MedicineDatas, cart, deletedItems, MedicineCartList, SingleMedicineDatas, SimilarMedicineDatas]);


    useEffect(() => {
        if (DeleteStatus === "success") {
            // Filter out deleted items from cart
            setCart(prevCart => prevCart.filter(item => !loadingItems.has(item.sku_id)));

            // Add the deleted item to the deletedItems set
            setDeletedItems(prev => new Set(prev).add([...loadingItems][0]));

            // Remove the loader for the deleted item
            setLoadingItems(prev => {
                const newSet = new Set(prev);
                [...loadingItems].forEach(id => newSet.delete(id));
                return newSet;
            });

            const currentUrl = window.location.href;
            if (currentUrl.includes("cart")) {
                dispatch(fetchMedicineCartList());
            } else if (currentUrl.includes("place-order")) {
                dispatch(fetchMedicineCartList());
            } else if (currentUrl.includes("medicine-description")) {
                dispatch(fetchSingleMedicinelist(data?.data?.sku_id, data?.data?.type));
                dispatch(fetchMedicinelist(data?.data?.name));
            } else if (currentUrl.includes("medicines")) {
                dispatch(fetchMedicineRecent());
                dispatch(fetchMedicineCartList());
            } else {
                dispatch(fetchMedicinelist(data?.data?.name || inputPara));
            }
        }
    }, [DeleteStatus]);


    const handleCartAdd = async (item) => {
        setLoadingItems(prev => new Set(prev).add(item.sku_id)); // Add the item to loading state

        const updatedCart = cart.map(cartItem =>
            cartItem.sku_id === item.sku_id
                ? {
                    ...cartItem,
                    quantity: Math.min(cartItem.quantity + item.min_quantity, item.max_quantity),
                }
                : cartItem
        );

        if (!updatedCart.some(cartItem => cartItem.sku_id === item.sku_id)) {
            updatedCart.push({
                sku_id: item.sku_id,
                quantity: item.min_quantity,
                min_quantity: item.min_quantity,
                max_quantity: item.max_quantity,
            });
        }

        await updateCartInServer(updatedCart);
        await dispatch(fetchMedicineCartList());

        setLoadingItems(prev => {
            const newSet = new Set(prev);
            newSet.delete(item.sku_id); // Remove the item from loading state once done
            return newSet;
        });
    };


    const handleIncrement = async (sku_id) => {
        setLoadingItems(prev => new Set(prev).add(sku_id)); // Add to loading state

        const updatedCart = cart.map(cartItem =>
            cartItem.sku_id === sku_id && cartItem.quantity < cartItem.max_quantity
                ? { ...cartItem, quantity: cartItem.quantity + 1 }
                : cartItem
        );

        await updateCartInServer(updatedCart);
        setLoadingItems(prev => {
            const newSet = new Set(prev);
            newSet.delete(sku_id); // Remove from loading state once done
            return newSet;
        });
    };

    const handleDecrement = async (sku_id) => {
        setLoadingItems(prev => new Set(prev).add(sku_id)); // Add to loading state

        const updatedCart = cart.map(cartItem =>
            cartItem.sku_id === sku_id && cartItem.quantity > cartItem.min_quantity
                ? { ...cartItem, quantity: cartItem.quantity - 1 }
                : cartItem
        );

        await updateCartInServer(updatedCart);
        setLoadingItems(prev => {
            const newSet = new Set(prev);
            newSet.delete(sku_id); // Remove from loading state once done
            return newSet;
        });
    };


    const handleDelete = async (id) => {
        setLoadingItems(prev => new Set(prev).add(id)); // Add to loading state
        // setLoaderStatus(true)
        const CartId = { sku_id: id };
        await dispatch(medicineCartDelete(CartId));
        setLoadingItems(prev => {
            const newSet = new Set(prev);
            newSet.delete(id); // Remove from loading state once done
            return newSet;
        });
    };


    return {
        cart,
        loadingItems,
        handleCartAdd,
        handleIncrement,
        handleDecrement,
        handleDelete,
        cartStatus,
        DeleteStatus,
        availability,
        CartData,
        Similar
    };
};

export default useCartFunction;
