import React from 'react'
import { Need_Help_Image } from '../Images'

const Need_Help = () => {
    return (
        <div className="need-help-repo-textt">
            <div className="image-data-repo">
                <img src={Need_Help_Image} alt="help-image" />
            </div>
            <div className="text-help-box">
                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. </p>
                <button>Click Here</button>
            </div>
        </div>
    )
}

export default Need_Help