import { thunk } from "redux-thunk"; // Correct import for redux-thunk
import {
  callDoctor,
  deleteAddress,
  deletePrescriptionReducer,
  medicineAddressReducer,
  medicineCartData,
  medicineCartDelete,
  medicineCartDetailsReducer,
  medicineListReducer,
  medicineRecentListReducer,
  medicineReducer,
  offerListReducer,
  packageListReducer,
  pincodeReducer,
  prescriptionListReducer,
  diagnosticCartAddReducer,
  diagnosticRemoveCartReducer,
  searchDiagnosticTest,
  saveAddress,
  selectedAddress,
  singleMedicineReducer,
  uploadPrescriptionReducer,
  diagnosticCartDetailsReducer,
  patientmemberlistReducer,
  addPatientMembersReducers,
  deletePatientReducer,
  savePatientMembersReducers,
  getTimeslotReducers,
  getDiagnosticTestListReducers,
  diagnosticOrderDetailsReducers,
  getPackageListReducers,
  orderMedicineReducer,
  orderlistReducer,
  package_registration_reducers,
  package_verify_reducers,
  capture_user_package_reducers,
  generate_otp_reducers,
  verify_otp_reducers,
  captureOrderMedicineReducer,
  saveSlotTimeReducers,
  orderDiagnosticReducers,
  selectedDiganosticAddressReducers,
  captureDiagnosticPaymentDetailsReducers,
  categoriesListReducers,
  orderHistoryReducer,
  orderHistory,
  trackOrderReducer,
  cancelOrderMedicineReducer,
  ReOrderMedicineReducer,
  test_descriptions_reducers , getpackageDescription_reducers
} from "../MultipleReducer/multipleReducer";
import { applyMiddleware, combineReducers, createStore } from "redux";

// Combine all reducers into the root reducer
const rootReducer = combineReducers({
  // GET APIs Reducers
  medicine: medicineReducer,
  medicine_list: medicineListReducer,
  medicine_recent_list: medicineRecentListReducer,
  prescription_list: prescriptionListReducer,
  medicine_cart_list: medicineCartDetailsReducer,
  single_medicine_list: singleMedicineReducer,
  offer_list: offerListReducer,
  package_list: packageListReducer,
  medicine_address: medicineAddressReducer,
  medicine_pincode: pincodeReducer,
  medicine_order_list: orderlistReducer,
  order_history: orderHistory,
  track_order: trackOrderReducer,

  // POST APIs Reducers
  upload_Prescription: uploadPrescriptionReducer,
  delete_Prescription: deletePrescriptionReducer,
  medicine_cart_add: medicineCartData,
  medicine_cart_delete: medicineCartDelete,
  call_doctor: callDoctor,
  save_address: saveAddress,
  delete_address: deleteAddress,
  selected_address: selectedAddress,
  order_medicine: orderMedicineReducer,
  capture_order_medicine: captureOrderMedicineReducer,
  cancel_order_medicine: cancelOrderMedicineReducer,
  reorder_medicine: ReOrderMedicineReducer,
  

  // DIAGNOSTICS
  search_diagnostic_test: searchDiagnosticTest,
  patient_members_list: patientmemberlistReducer,

  // POST APIs Reducers for Diagnostics
  diagnostic_cart_add: diagnosticCartAddReducer,
  diagnostic_remove_cart: diagnosticRemoveCartReducer,
  diagnostic_cart_details: diagnosticCartDetailsReducer,
  add_patient_members: addPatientMembersReducers,
  delete_patient_members: deletePatientReducer,
  save_patient_members: savePatientMembersReducers,
  get_time_slots: getTimeslotReducers,
  diagnostic_test_list_response: getDiagnosticTestListReducers,
  diagnostic_package_list_response: getPackageListReducers,
  diagnostic_place_order_details_response: diagnosticOrderDetailsReducers,
  getpackageDescription_response: getpackageDescription_reducers,

  // Package and OTP Reducers
  package_registration_response: package_registration_reducers,
  package_verify_response: package_verify_reducers,
  capture_user_package_response: capture_user_package_reducers,
  generate_otp_response: generate_otp_reducers,
  verify_otp_response: verify_otp_reducers,

  // Additional POST APIs Reducers
  save_time_slot_response: saveSlotTimeReducers,
  orderDiagnostic_response: orderDiagnosticReducers,
  save_diagnostic_address_response: selectedDiganosticAddressReducers,
  capture_diagnostic_payment_details_response:
    captureDiagnosticPaymentDetailsReducers,
  categories_test_list_response: categoriesListReducers,
  getTestDescription_response: test_descriptions_reducers,
});

// Create the Redux store with the root reducer and thunk middleware
const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
