import React, { useEffect , useState } from "react";
import ProfileHeader from "../Components/Header";
import Loader from '../../../Animation/Loader';
import { REQUESTOPTIONS , TERMS_CONDITION_API } from "../../../Constants";

const TermsCondition = () => {
    const [terms_htmlContent , setTerms_htmlContent] = useState([])
    const [isContentLoading , setisContentLoading] = useState(true)

  useEffect(() => {
    setisContentLoading(true)
    const FetchTermsHtmlContent = async () => {
      try {
        const response = await fetch(
          `${TERMS_CONDITION_API}`,
          REQUESTOPTIONS
        );
        const res = await response.json();
        // console.log(res)
        if (res) {
            setTerms_htmlContent(res.html_content)
            setisContentLoading(false)
        } else {
          console.log(res.message);
        }
      } catch (error) {
        console.log(error);
      }
    };

    FetchTermsHtmlContent();
  }, []);

  return (
    <>
      <div className="home-review-banner">
        <ProfileHeader Heading="Terms & Conditions" />
        <div className="terms-condition-main-wrapper-cover">
        {
            terms_htmlContent ? (
        <div dangerouslySetInnerHTML={{ __html: terms_htmlContent }} />
            ) : <Loader/>
        }
        </div>
      </div>
    </>
  );
};

export default TermsCondition;
