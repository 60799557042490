export const createApiAction = (actionType, apiCall, options = {}) => {
  return async (dispatch) => {
    dispatch({ type: `${actionType}_REQUEST` });
    try {
      // Call the API and pass the options (which can include request headers)
      const response = await apiCall(options);
      const ResponseToken = response.headers.get("Authorization");

      if (ResponseToken && ResponseToken !== null) {
        localStorage.setItem("APP_TOKEN", ResponseToken);
        window.location.reload();
        return;
      }

      if (!response.ok) {
        // If response status is not OK (e.g., not 200), throw an error
        const errorData = await response.json();
        throw new Error(errorData.message || "Something went wrong");
      }

      const data = await response.json();
      dispatch({ type: `${actionType}_SUCCESS`, payload: data });

      // Dispatch IDLE after a short delay to allow the UI to update
      setTimeout(() => {
        dispatch({ type: `${actionType}_IDLE` });
      }, 500);
    } catch (error) {
      dispatch({ type: `${actionType}_FAILURE`, payload: error.message });
    }
  };
};
