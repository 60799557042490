import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import {
  ArrowBack,
  appCartIcon,
  appHeartIcon,
  appPXImg,
} from "../../images/index";
import LocationSection from "../LocationSection/LocationSection";
import ViewCart from "./ViewCart";
import { ArrowBackButton } from "../../utils/AllFunction";
import Loader from "../../Animation/Loader";
import { MEDICINE_CART_MANAGER_LIST, MEDICINE_UPDATE, SEARCH_MEDICINE_API } from "../../Constants";
import toast from "react-hot-toast";
import NoDataFoundComp from "../../components/NoDataFound";
import Mediloader from "../../Animation/Mediloader";

const ProductDescription = () => {
  const [similarmedicine, setSimilarMedicine] = useState(null);
  const [responseData, setResponseData] = useState([]);
  const [loadingData, setLoadingData] = useState({});
  const [storePriceData, setStorePriceData] = useState([]);
  const [loadingHomeData, setLoadingHomeData] = useState(true);
  const [selectedProduct, setSelectProduct] = useState(
    JSON.parse(localStorage.getItem("MedicineDetails")) || {}
  );
  const croppedImageUrls = selectedProduct.cropped_image_urls || [];
  let parsedmedicineData;
  const token = localStorage.getItem("ACCESS_TOKEN");
  const [pincodeData, setPincodeData] = useState(
    JSON.parse(localStorage.getItem("pincode")) || ""
  );
  const navigate = useNavigate();

  useEffect(() => {
    const storedIndexData = localStorage.getItem("medicineindexdata");
    if (storedIndexData) {
      parsedmedicineData = JSON.parse(storedIndexData);
    }
    const formdata = new FormData();
    formdata.append("medicine_name", parsedmedicineData.text);
    formdata.append("city_name", parsedmedicineData.city);

    const medicineData = async () => {
      try {
        const homeDataRes = await fetch(
          SEARCH_MEDICINE_API,
          {
            headers: {
              Authorization: `${token}`,
            },
            method: "POST",
            body: formdata,
          }
        );

        if (!homeDataRes.ok) {
          console.error(
            "API error:",
            homeDataRes.status,
            homeDataRes.statusText
          );
          const errorData = await homeDataRes.json().catch(() => null);
          console.error("Error details:", errorData);
          throw new Error("Network response was not ok");
        }

        const medicineData = await homeDataRes.json();
        setSimilarMedicine(Object.values(medicineData?.results));
      } catch (error) {
        console.error("Error fetching home data:", error);
      } finally {
        setLoadingHomeData(false);
      }
    };
    medicineData();
  }, [parsedmedicineData, token]);


  const AddProductData = async (medicineData, selectedProduct) => {
    try {
      const requestBodyData =
      {
        pincode: pincodeData,
        cart: [
          {
            onemg_sku_id: medicineData?.sku_id || selectedProduct?.sku_id,
            quantity: medicineData?.min_quantity || selectedProduct?.min_quantity,
            min_quantity: medicineData?.min_quantity || selectedProduct?.min_quantity,
            max_quantity: medicineData?.max_quantity || selectedProduct?.max_quantity
          }
        ]
      }

      const updateData = await fetch(`${MEDICINE_UPDATE}`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${token}`
        },
        body: JSON.stringify(requestBodyData)

      })


      if (updateData.ok) {
        const manageDataAgain = await fetch(`${MEDICINE_CART_MANAGER_LIST}${pincodeData}`, {
          headers: {
            Authorization: `${token}`,
          },
          method: "GET",
        })

        const resAgain = await manageDataAgain.json();
        setResponseData(resAgain?.skus_res)
        const outOfStockRes = resAgain?.out_of_stock_res;
        if (outOfStockRes && Array.isArray(outOfStockRes)) {
          const ids = outOfStockRes.map(item => item.id);
          if (ids.includes(medicineData.sku_id)) {
            toast.error("Medicine Out of Stock");
          }
        }
      }
      else {
        console.error("Faild to fecth", updateData.statusText)
      }
    } catch (error) {
      console.log(error)
    }

  }


  useEffect(() => {
    MangerListDataRepo();
  }, []);


  const MangerListDataRepo = async () => {
    try {
      const ManagerListRes = await fetch(`${MEDICINE_CART_MANAGER_LIST}${pincodeData}`, {
        headers: {
          Authorization: `${token}`,
        },
        method: "GET",
      });

      const ListDataRes = await ManagerListRes.json();

      setResponseData(ListDataRes?.skus_res || []);

      const updatedPrices = ListDataRes?.skus_res.map(response => response.price);
      const sum = updatedPrices.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
      const formattedSum = Math.floor(sum * 100) / 100;

      setStorePriceData(formattedSum);
      setLoadingHomeData(false);
    } catch (error) {
      console.log(error);
      setLoadingHomeData(false);
    }
  }


  const handleCart = async (selectedProduct, medicineData) => {
    try {
      setLoadingData(prevStates => ({
        ...prevStates,
        [selectedProduct.sku_id || medicineData.sku_id]: true
      }));


      // Fetch the latest quantity for the added product
      const managerListRes = await fetch(`${MEDICINE_CART_MANAGER_LIST}${pincodeData}`, {
        headers: {
          Authorization: `${token}`,
        },
        method: "GET",
      });
      const listDataRes = await managerListRes.json();
      await AddProductData(selectedProduct || medicineData);
      MangerListDataRepo();

      setResponseData(prevData => [...prevData, selectedProduct || medicineData]);

      setLoadingData(prevStates => ({
        ...prevStates,
        [selectedProduct.sku_id || medicineData.sku_id]: false
      }));
      // toast.success("Medicine Added")
    } catch (error) {
      console.log(error);
      setLoadingData(prevStates => ({
        ...prevStates,
        [selectedProduct.sku_id || medicineData.sku_id]: false
      }));
    }
  }

  // To Increment
  const handleIncrement = async (filteredItem) => {
    try {
      setLoadingData(prevStates => ({
        ...prevStates,
        [filteredItem.id]: true
      }));
      const updatedQuantity = Math.max(filteredItem.qty + 1);

      // Update the cart locally
      const updatedCart = responseData.map(item => {
        if (item.id === filteredItem.id) {
          return {
            ...item,
            qty: updatedQuantity
          };
        }
        return item;
      });

      // Update the state with the updated cart
      setResponseData(updatedCart);

      // Construct the request body data
      const requestBodyData = {
        pincode: pincodeData,
        cart: [
          {
            onemg_sku_id: filteredItem.id,
            quantity: updatedQuantity,
            min_quantity: filteredItem.min_quantity,
            max_quantity: filteredItem.max_quantity
          }
        ]
      };

      // Call cart_manager_update API to update the cart
      const updateData = await fetch(`${MEDICINE_UPDATE}`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${token}`
        },
        body: JSON.stringify(requestBodyData)
      });

      if (updateData.ok) {
        // If the update is successful, fetch the updated cart data
        const manageDataAgain = await fetch(`${MEDICINE_CART_MANAGER_LIST}${pincodeData}`, {
          headers: {
            Authorization: `${token}`,
          },
          method: "GET",
        });

        const resAgain = await manageDataAgain.json();
        console.log(resAgain?.skus_res);

        const updatedPrices = resAgain?.skus_res.map(response => response.price);
        const sum = updatedPrices.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

        const formattedSum = Math.floor(sum * 100) / 100;

        setStorePriceData(formattedSum);
        
        // Set loading to false after receiving response
        setLoadingData(prevStates => ({
          ...prevStates,
          [filteredItem.id]: false
        }));
      } else {
        console.error("Failed to fetch", updateData.statusText);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // To Decrement
  const handleDecrement = async (filteredItem) => {
    try {
      setLoadingData(prevStates => ({
        ...prevStates,
        [filteredItem.id]: true
      }));
      // Calculate updated quantity ensuring it respects minimum quantity
      let updatedQuantity = Math.max(filteredItem.qty - 1, 0);

      // If the updated quantity is less than the minimum quantity, set it to 0
      if (updatedQuantity < filteredItem.min_quantity) {
        updatedQuantity = 0;
      }

      // Update the cart locally
      const updatedCart = responseData.map(item => {
        if (item.id === filteredItem.id) {
          return {
            ...item,
            qty: updatedQuantity
          };
        }
        return item;
      });

      // Update the state with the updated cart
      setResponseData(updatedCart);

      // Construct the request body data
      const requestBodyData = {
        pincode: pincodeData,
        cart: [
          {
            onemg_sku_id: filteredItem.id,
            quantity: updatedQuantity,
            min_quantity: filteredItem.min_quantity,
            max_quantity: filteredItem.max_quantity
          }
        ]
      };
      // Call cart_manager_update API to update the cart
      const updateData = await fetch(`${MEDICINE_UPDATE}`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${token}`
        },
        body: JSON.stringify(requestBodyData)
      });

      if (updateData.ok) {
        const manageDataAgain = await fetch(`${MEDICINE_CART_MANAGER_LIST}${pincodeData}`, {
          headers: {
            Authorization: `${token}`,
          },
          method: "GET",
        });

        const resAgain = await manageDataAgain.json();

        const updatedPrices = resAgain?.skus_res.map(response => response.price);
        const sum = updatedPrices.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

        const formattedSum = Math.floor(sum * 100) / 100;

        setStorePriceData(formattedSum);
        // Update UI after successful API response
        setResponseData(resAgain?.skus_res);

        // Set loading to false after receiving response
        setLoadingData(prevStates => ({
          ...prevStates,
          [filteredItem.id]: false
        }));
      } else {
        console.error("Failed to fetch", updateData.statusText);
      }
    } catch (error) {
      console.log(error);
    }
  };


  const SelectedMedicineDetails = (sku_id) => {
    const selectedMedicine = similarmedicine.find(
      (medicine) => medicine.sku_id === sku_id
    );
    if (selectedMedicine) {
      localStorage.setItem("MedicineDetails", JSON.stringify(selectedMedicine));
      window.location.reload();
      navigate("/productdescription");
    } else {
      console.log(`Medicine with ID ${sku_id} not found in medicinindexdata.`);
    }
  };


  return (
    <>
      <div>
        <div className="home-review-banner">
          <div className="app-header-wraper">
            {/* Back Button */}

            {/* Title and Location Section */}
            <div className="header-top-section">
              <div className="header-top-section-category-section">
                <h3>Product Description</h3>
                <LocationSection />
              </div>
              {/* Cart and WishList Icons */}
              <div className="header-top-section-add-to-cart-section">
                <NavLink to="/cart">
                  <img src={appCartIcon} width="100%" alt="Cart-icon" />
                  {responseData.length > 0 && (
                    <span className="Header-count-number prod-count-num">
                      {responseData && responseData && responseData.length}
                    </span>
                  )}
                </NavLink>
              </div>
              <div className="header-top-section-add-to-cart-section-hert">
                <img src={appHeartIcon} width="100%" alt="heart-icon" />
              </div>
            </div>
          </div>
          {/* Image Slider Section */}
          <Swiper
            spaceBetween={10}
            slidesPerView={1}
            loop={true}
            modules={[Autoplay, Pagination]}
            pagination={{ clickable: true }}
            autoplay={{ delay: 2500, disableoninteraction: false }}
            className="product-discription"
          >
            <div className="swiper-wrapper">
              {croppedImageUrls.map((imageUrl, imgIndex) => (
                <SwiperSlide key={imgIndex}>
                  <div
                    className="product-discription-cover"
                    style={{ backgroundImage: `url(${imageUrl})` }}
                  ></div>
                </SwiperSlide>
              ))}
            </div>
          </Swiper>
          {/* Medicine Detail Section */}
          <div className="medicine-detail-section">
            <div className="medicine-detail-section-prescription-required">
              {selectedProduct.rx_required === false && (
                <>
                  <img src={appPXImg} width="100%" alt="px" />
                  <span>Prescription Required</span>
                </>
              )}
            </div>
            <div className="medicine-detail">
              <p
                dangerouslySetInnerHTML={{
                  __html: selectedProduct?.name?.replace(
                    /(<? *script)/gi,
                    "illegalscript"
                  ),
                }}
              ></p>
            </div>
            <div className="medicine-detail-section-price">
              <div className="row">
                <div className="col-lg-6">
                  <div className="medicine-detail-section-price-left-side">
                    <p>Min Qty:{selectedProduct.min_quantity}</p>
                    <div className="desc-prod-price">
                      <h3>₹ {selectedProduct.discounted_price}</h3>
                      <span> ₹ {selectedProduct.price}</span>
                    </div>
                  </div>
                  <div className="medicine-detail-section-price-left-side-discount">
                    <p>
                      Get {selectedProduct.discount_percent !== null && (
                        <>{selectedProduct.discount_percent}</>
                      )}
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="tranding-medicine-container">
                    {responseData.length === 0 || !responseData.some(item => item.id === selectedProduct.sku_id) ? (
                      <button
                        className='tranding-medicine-add-cart product-desc-repo-add-button'
                        onClick={() => handleCart(selectedProduct)}
                        disabled={loadingData[selectedProduct.sku_id]}
                      >
                        {loadingData[selectedProduct.sku_id] ? 'Adding...' : 'Add To Cart'}
                      </button>
                    ) : (
                      responseData
                        .filter(item => item.id === selectedProduct.sku_id)
                        .map(filteredItem => (
                          <div className={`tranding-medicine-add-cart-add product-desc-repo-add-button ${loadingData[filteredItem.id] ? 'loading' : ''}`} key={filteredItem.id}>
                            {loadingData[filteredItem.id] && <p><Mediloader /></p>}
                            {!loadingData[filteredItem.id] && filteredItem.qty > 0 && (
                              <>
                                <div className="image-add-icon" onClick={() => handleDecrement(filteredItem)}>
                                  <p>-</p>
                                </div>
                                <span>{filteredItem.qty}</span>
                                <div className="image-add-icon" onClick={() => handleIncrement(filteredItem)}>
                                  <p>+</p>
                                </div>
                              </>
                            )}
                          </div>
                        ))
                    )}
                  </div>
                </div>
              </div>
            </div>
            <hr className="medicine-detail-section-line" />
          </div>


          {/* Similar Medicines Section */}
          <div className="medical-similar-section">
            <div className="similar-heading">
              <h5>Similar Medicines</h5>
            </div>
            <Swiper
              spaceBetween={20}
              slidesPerView={2}
              loop={true}
              modules={[Autoplay, Pagination]}
            >
              <div className="swiper-wrapper">
                <SwiperSlide className="swiper-image-slider">
                  <>
                    {loadingHomeData && loadingHomeData ? (
                      <p>
                        <Loader />
                      </p>
                    ) : similarmedicine && similarmedicine.length > 0 ? (
                      <div className="tranding-medicine-container product-container-repo">
                        <Swiper
                          spaceBetween={20}
                          slidesPerView={2}
                          loop={true}
                          modules={[Autoplay, Pagination]}
                        >
                          <div className="swiper-wrapper">
                            {Array.isArray(similarmedicine) &&
                              similarmedicine.length > 0 &&
                              similarmedicine
                                .slice(0, 9)
                                .map((medicineData) => (
                                  <SwiperSlide
                                    key={medicineData.id}
                                    style={{
                                      background: "none",
                                      borderRadius: "20px",
                                    }}
                                  >
                                    <div className="col-lg-12 product-item-repo-card">
                                      {/* Existing content */}
                                      <div className="tranding-medicine-box similar-box-repo-desc">
                                        <div
                                          className="select-product-data"
                                          onClick={() =>
                                            SelectedMedicineDetails(
                                              medicineData.sku_id
                                            )
                                          }
                                        >
                                          <div className="tranding-medicine-image">
                                            <div className="row">
                                              <div className="col-lg-4 col-md-4 col-4">
                                                <div className="medicine-rx-image">
                                                  {medicineData.rx_required === false ? (
                                                    <></>
                                                  ) : (
                                                    <img
                                                      src={appPXImg}
                                                      alt={medicineData.name}
                                                    />
                                                  )}
                                                </div>
                                              </div>
                                              <div className="col-lg-4 col-md-4 col-4">
                                                <div className="medicine-tranding-main-image">
                                                  <img
                                                    src={medicineData.image}
                                                    width="100%"
                                                    alt={
                                                      medicineData.name.length >
                                                        8
                                                        ? `${medicineData.name.slice(
                                                          0,
                                                          8
                                                        )}...`
                                                        : medicineData.name
                                                    }
                                                  />
                                                </div>
                                              </div>
                                              <div className="col-lg-4 col-md-4 col-4">
                                                {medicineData.discount_percent ? (
                                                  <div className="medicine-discount">
                                                    <p>
                                                      {
                                                        medicineData.discount_percent
                                                      }
                                                    </p>
                                                  </div>
                                                ) : null}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="tranding-medicine-detail trend-medi-para">
                                            <p
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  medicineData.name?.replace(
                                                    /(<? *script)/gi,
                                                    "illegalscript"
                                                  ),
                                              }}
                                            ></p>
                                          </div>
                                          <div className="tranding-medicine-price medi-repice-repo-text">
                                            <p>Min Qty:{medicineData.min_quantity}</p>
                                            ₹ {medicineData.discounted_price}
                                            <span>₹ {medicineData.price}</span>
                                          </div>
                                        </div>
                                        <div className="tranding-medicine-container">
                                          {responseData.length === 0 || !responseData.some(item => item.id === medicineData.sku_id) ? (
                                            <button
                                              className='tranding-medicine-add-cart'
                                              onClick={() => handleCart(medicineData)}
                                              disabled={loadingData[medicineData.sku_id]}
                                            >
                                              {loadingData[medicineData.sku_id] ? 'Adding...' : 'ADD'}
                                            </button>
                                          ) : (
                                            responseData
                                              .filter(item => item.id === medicineData.sku_id)
                                              .map(filteredItem => (
                                                <div className={`tranding-medicine-add-cart-add ${loadingData[filteredItem.id] ? 'loading' : ''}`} key={filteredItem.id}>
                                                  {loadingData[filteredItem.id] && <p><Mediloader /></p>}
                                                  {!loadingData[filteredItem.id] && filteredItem.qty > 0 && (
                                                    <>
                                                      <div className="image-add-icon" onClick={() => handleDecrement(filteredItem)}>
                                                        <p>-</p>
                                                      </div>
                                                      <span>{filteredItem.qty}</span>
                                                      <div className="image-add-icon" onClick={() => handleIncrement(filteredItem)}>
                                                        <p>+</p>
                                                      </div>
                                                    </>
                                                  )}
                                                </div>
                                              ))
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </SwiperSlide>
                                ))}
                          </div>
                        </Swiper>
                      </div>
                    ) : (
                      <div className="not-data-found">
                        <NoDataFoundComp />
                      </div>
                    )}
                  </>
                </SwiperSlide>
              </div>
            </Swiper>




          </div>
          {/* View Cart Section */}
          {responseData && responseData.length > 0 && (
            <ViewCart
              medicineCartData={responseData}
              productPrice={storePriceData}
              link="cart"
            />
          )}
        </div>

      </div>
    </>
  );
};

export default ProductDescription;
