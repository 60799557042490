import React, { useEffect } from 'react'
import Medicine_Header from '../Components/Medicine_Header'
import useLocationState from '../hooks/useLocationState'
import '../Medicine_Style/address.css'
import Address from '../Components/Address'
import { useNavigate } from 'react-router-dom'

const Medicines_Address = () => {
  const Title = "Address Details";
  const data = useLocationState();
  console.log(data)

  return (
    <div className="home-review-banner">
      <Medicine_Header Title={Title} />
      <div className="address-wrapper-box">
        <p className='up-text'>Add or update your address details</p>
        {Object.keys(data).length > 0 ? <Address Data={data} /> : <Address />}
      </div>
    </div>
  )
}

export default Medicines_Address
