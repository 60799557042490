import React, { useEffect, useState } from "react";
import {
  ArrowBack,
  Order_Icon,
  Reminder_Icon,
  Trasaction_Icon,
  appHeartIcon,
} from "../../images";
import { ArrowBackButton } from "../../utils/AllFunction";
import { NavLink } from "react-router-dom";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import { NOTIFICATION_API, REQUESTOPTIONS } from "../../Constants";
import Loader from "../../Animation/Loader";

const Notification = () => {

  const [allNotificationData, setAllNotificationData] = useState([])
  const [orderNotificationData, setOrderNotificationData] = useState([])
  const [loading, setLoading] = useState(false)

  const fetchNotification = async () => {
    setLoading(true)
    try {
      const response = await fetch(`${NOTIFICATION_API}`, REQUESTOPTIONS);
      const data = await response.json();
      setAllNotificationData(data?.all_notification)
      setOrderNotificationData(data?.order_notification)
      setOrderNotificationData(data?.order_notification)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    fetchNotification()
  }, [])
  return (
    <>
      <div className="home-review-banner">
        <div className="app-header-wraper">

          <div className="header-top-section">
            <div className="header-top-section-category-section">
              <h3>Place Order</h3>
            </div>
            <div className="header-top-section-add-to-cart-section-hert">
              <img src={appHeartIcon} width="100%" alt="heart-icon" />
            </div>
          </div>
        </div>

        <div className="medicine-cover">
          <div className="container medi-cover-repo">
            <div className="tab-main-cover-wrapper mb-5">
              <Tab.Container id="left-tabs-example" defaultActiveKey='first'>
                <Row>
                  <Col lg={12} className="mb-2 tab-cover-repo box-notifi">
                    <div className="cart-header-tabs d-flex justify-content-center gap-3">
                      <Nav
                        variant="pills"
                        className="flex-column tab-box-main-repo"
                      >
                        <Nav.Item>
                          <Nav.Link
                            eventKey="first"
                          // onSelect={() => setKey("first")}
                          // onClick={() => handleClickTab("first")}
                          >
                            <div className="notification-box">
                              <div className="noti-box-heading">
                                <p>All Updates</p>
                              </div>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <Nav
                        variant="pills"
                        className="flex-column tab-box-main-repo"
                      >
                        <Nav.Item>
                          <Nav.Link
                            eventKey="second"
                          // onSelect={() => setKey("second")}
                          // onClick={() => handleClickTab("second")}
                          >
                            <div className="notification-box">
                              <div className="noti-box-heading">
                                <p>Order Updates</p>
                              </div>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </div>
                  </Col>
                  {loading ? (
                    <Loader />
                  ) : (
                    <Col lg={12} className="placebox">
                      <div className="tab-content-main-cover">
                        <Tab.Content>
                          <Tab.Pane eventKey="first">
                            <div className="all-updates-box">
                              {allNotificationData.map((item, index) => (
                                <div className="today-box-content" key={index}>
                                  <div className="heading-noti">
                                    <p className="text-center">{item?.day}</p>
                                    {item.notifications_list !== null && item.notifications_list.map((item, index) => (
                                      <div className="content-box" key={index} style={{
                                        border: `1px solid #${item?.color}`,
                                        boxShadow: `0 0px 0px 0 #${item?.color}, 0 1px 1px 0 #${item?.color}`
                                      }}>
                                        <div className="order-box">
                                          <div className="image-data-cop">
                                            <div className="image-box" style={{ background: `#${item?.color}` }}>
                                              <img src={item.icon} alt="" />
                                            </div>
                                            <div className="heading">
                                              <h6 style={{ color: `#${item?.color}` }}>
                                                {item.caption}
                                              </h6>
                                              <p>{item.title}</p>
                                            </div>
                                          </div>
                                          <div className="content-para">
                                            <p>{item.created_at}</p>
                                          </div>
                                        </div>
                                        <div className="order-content-data-box">
                                          <div className="cop-image-repo">
                                            <img src={item.image} alt="" />
                                          </div>
                                          <div className="cop-image-text">
                                            <a href={item?.redirect_url}>
                                              <p>{item?.redirect_url}</p>
                                            </a>
                                          </div>
                                          <div className="cop-desc-text">
                                            <p>{item?.description}</p>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>

                                </div>
                              ))}
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="second">
                            <div className="all-updates-box">
                              {orderNotificationData.map((item, index) => (
                                <div className="today-box-content" key={index}>
                                  <div className="heading-noti">
                                    <p className="text-center">{item?.day}</p>
                                    {item.notifications_list !== null && item.notifications_list.map((item, index) => (
                                      <div className="content-box" key={index} style={{
                                        border: `1px solid #${item?.color}`,
                                        boxShadow: `0 0px 0px 0 #${item?.color}, 0 1px 1px 0 #${item?.color}`
                                      }}>
                                        <div className="order-box">
                                          <div className="image-data-cop">
                                            <div className="image-box" style={{ background: `#${item?.color}` }}>
                                              <img src={item.icon} alt="" />
                                            </div>
                                            <div className="heading">
                                              <h6 style={{ color: `#${item?.color}` }}>
                                                {item.caption}
                                              </h6>
                                              <p>{item.title}</p>
                                            </div>
                                          </div>
                                          <div className="content-para">
                                            <p>{item.created_at}</p>
                                          </div>
                                        </div>
                                        <div className="order-content-data-box">
                                          <div className="cop-image-repo">
                                            <img src={item.image} alt="" />
                                          </div>
                                          <div className="cop-image-text">
                                            <a href={item?.redirect_url}>
                                              <p>{item?.redirect_url}</p>
                                            </a>
                                          </div>
                                          <div className="cop-desc-text">
                                            <p>{item?.description}</p>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>

                                </div>
                              ))}
                            </div>
                          </Tab.Pane>
                        </Tab.Content>
                      </div>
                    </Col>
                  )}
                </Row>
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Notification;
