import React from "react";
import useHandleCart from "../hooks/useHandleCart";
import CartLoader from "../../Medicine/animation/CartLoader";

const DescriptionButtons = ({test , descriptionId , packdescriptionId}) => {

  const { handleCartAdd, handleRemoveCart , loadingItems   , myApiLoader} =
    useHandleCart("test");

  return (
    <>

      <div className="pack-detail-buttons">
        <div className="container">
          <div className="row">
            <div className="col-6 packe-det-repo">
            {loadingItems.has(test?.id) && myApiLoader  ? (
                <CartLoader />
              ) : test?.in_cart === 1 ? (
                <button
                  class="remove-cart-btn"
                  onClick={() => handleRemoveCart({...test , descriptionId : descriptionId})}
                >
                  Remove
                </button>
                
              ) : (
                <div className="pack-detail-add-to-cart">
                  <button id="add-to-cart" className="add-to-cart" onClick={() => handleCartAdd({ ...test, type: "test" ,descriptionId : descriptionId })}>
                    <p>ADD TO CART</p>
                  </button>
                 </div>
              )}
            </div>
            <div className="col-6 packe-det-repo d-flex align-items-center">
              <div className="pack-detail-buy-now">
                <button className="mt-0">
                  <p>BUY NOW</p>
                </button>
              </div>
            </div>
          </div>
       
        </div>
      </div>
    </>
  );
};

export default DescriptionButtons;
