// SuccessFull.js

import React from 'react';
import Lottie from "lottie-react";
import PaymentSuccess from "../AnimationJson/payment_success.json"
import Error from "../AnimationJson/Error.json"
import Modal from 'react-bootstrap/Modal';

const SuccessFull = (props) => {
    const { show, successMessage , paymentAnimation } = props;

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='payment-modal-main-wrapper'
        >
            <Modal.Body>
                <Lottie animationData={paymentAnimation ? PaymentSuccess : Error} className='success-data-animation payment-data-animation' />
                {successMessage && <p className='success-msg text-center'>{successMessage}</p>}
            </Modal.Body>
        </Modal>
    )
}

export default SuccessFull;
