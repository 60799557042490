import React, { useEffect, useState } from "react";
import { headerLogo } from "../../images";
import useQuery from "../../CustomHooks/useQuery";
import Lottie from "lottie-react";
import YogasessionJson from "../../AnimationJson/yogasession.json"
import Modal from 'react-bootstrap/Modal';

const YogaSession = () => {

  const title = useQuery("title");
  const [modalShow, setModalShow] = React.useState(false);
  const [successmodal, setSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() =>{
    setSuccessModal(true);
    setModalShow(true);
    setTimeout(() =>{
        setModalShow(false);
        window.history.back();
    },3000)
  },[])

  return (
    <>
      <div className="home-review-banner">
        <div className="home-app-header">
          <div className="header-logo">
            <img src={headerLogo} width="100%" alt="header-logo" />
            <div className="user-name">
              <p className="ml-3">{title}</p>
            </div>
          </div>
        </div>


        {successmodal && (
          <ComingSoon
            show={modalShow}
            successMessage={successMessage}
            animationtype={true}
          />
        )}
      </div>
    </>
  );
};

// SuccessFull.js

export default YogaSession;

const ComingSoon = (props) => {
    const { show, successMessage , animationtype } = props;

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                {<p className='success-msg'>Coming Soon</p>}
                <Lottie animationData={YogasessionJson} className='success-data-animation' />
            </Modal.Body>
        </Modal>
    )
}
