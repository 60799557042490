import React, { useEffect, useState } from "react";
import { ArrowBack, appHeartIcon } from "../../images";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { ArrowBackButton } from "../../utils/AllFunction";
import { useForm, Controller } from "react-hook-form";
import SuccessFull from "../../Animation/SuccessFull";
import LocationSection from "../LocationSection/LocationSection";
import { ADD_ADDRESS, MY_ACCOUNT_API, USER_ADDRESS_API } from "../../Constants";
import toast from "react-hot-toast";

const MedicineAddress = () => {
    const [loading, setLoading] = useState(false);

    const onSubmit = (data) => {
        console.log(data); // You can handle form submission here
    };
    const [error, setError] = useState("");
    const [successmodal, setSuccessModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [modalShow, setModalShow] = React.useState(false);
    const token = localStorage.getItem("ACCESS_TOKEN");
    const [addressData, setAddressData] = useState([]);
    const [isDataLoading, setDataLoading] = useState(true);
    const [userInfo, setUserInfo] = useState([]);
    const [pincodeData, setPincodeData] = useState(null);
    const [pincode, setPincode] = useState("");
    const [selectedAddress, setSelectedAddress] = useState({});
    const [selectedType, setSelectedType] = useState([])
    const navigate = useNavigate();
    const requestOptions = {
        method: "GET",
        headers: {
            Authorization: `${token}`,
        },
    };
    // console.log(userDataInfo);

    const { index } = useParams();

    useEffect(() => {
        const FetchAddressDetailsList = async (index) => {
            setDataLoading(true);
            try {
                const apiUrl = USER_ADDRESS_API;
                const response = await fetch(apiUrl, requestOptions);

                const res = await response.json();
                if (res.status) {
                    setAddressData(res.data);
                    setDataLoading(false);
                    setSelectedAddress(res.data[index]);
                    setSelectedType(res.data[index]?.type)
                } else {
                    // console.log(res);
                }
            } catch (error) {
                console.error(error);
                setDataLoading(false);
            }
        };

        FetchAddressDetailsList(index);

    }, [index]);

    useEffect(() => {
        const userData = async () => {
            const UserRes = await fetch(`${MY_ACCOUNT_API}`, requestOptions)
            const userResData = await UserRes.json();
            setUserInfo(userResData.data?.profile_details);
            localStorage.setItem("userinfo", JSON.stringify(userResData.data?.profile_details))
        }
        userData();
    }, [])

    useEffect(() => {
        const fetchPincodeData = async () => {
            try {
                const formData = new FormData();
                formData.append("pincode", pincode);

                const apiUrl = "/pincode_state_city";
                const response = await fetch(`${process.env.REACT_APP_API_URL}${apiUrl}`, {
                    headers: {
                        Authorization: `${token}`,
                    },
                    method: "POST",
                    body: formData,
                });

                if (!response.ok) {
                    console.error("API error:", response.status, response.statusText);
                    const errorData = await response.json().catch(() => null);
                    console.error("Error details:", errorData);
                    throw new Error("Network response was not ok");
                }

                const pincodeData = await response.json();
                setPincodeData(pincodeData?.data);
            } catch (error) {
                console.error("Api Unsuccessfull", error);
            }
        };

        if (pincode.length === 6) {
            fetchPincodeData();
        }
    }, [pincode]);

    const handleKeyPress = (e) => {
        const charCode = e.which || e.keyCode;
        if (charCode < 48 || charCode > 57) {
            e.preventDefault();
        }
    };

    const handlePincodeChange = (e) => {
        const enteredPincode = e.target.value;
        setPincode(enteredPincode);
    };

    const onSubmitForm = async (data) => {
        if (!selectedType) {
            setError("Please select an address type");
            return;
        }
        const formData = new FormData();
        formData.append("post_type", selectedAddress && selectedAddress?.id ? "update" : "add");
        formData.append("address_id", selectedAddress?.id || "");
        formData.append("type", selectedType);
        formData.append("address", data.address);
        formData.append("tower_plot_no", 114);
        formData.append("society_name", "sanjay nagar");
        formData.append("pincode", pincode || data.pincode);
        formData.append("city", pincodeData?.city || data.city);
        formData.append("state", pincodeData?.state || data.state);
        formData.append("name", data.name);
        formData.append("email", data.email);
        formData.append("mobile", data.mobile);
        formData.append("landmark", data.landmark);


        try {
            setLoading(true);
            const AddAddressData = await fetch(
                `${ADD_ADDRESS}`,
                {
                    method: "POST",
                    headers: {
                        Authorization: `${token}`,
                    },
                    body: formData,
                }
            );

            const responseData = await AddAddressData.json();

            if (!AddAddressData.ok) {
                console.error(
                    "API error:",
                    AddAddressData.status,
                    AddAddressData.statusText
                );
                const AddressData = await AddAddressData.json().catch(() => null);
                console.error("Error details:", AddressData);
                throw new Error("Network response was not ok");
            }

            if (responseData.status === 200) {
                setSuccessModal(true);
                setSuccessMessage(responseData.message);
                setModalShow(true);
                setTimeout(() => {
                    setModalShow(false);
                    setSuccessModal(false);
                    navigate('/lab-addressdetails');
                }, 3000);
            } else {
                alert("Unsuccessful submission");
            }
        } catch (error) {
            console.error(error);
            toast.error("Submisubmission Failed")
        } finally {
            setLoading(false);
        }
    };

    const handleType = (e) => {
        setSelectedType(e.target.value)
        setError("");
    }

    const handleSubmitRadio = () => {
        if (!selectedType) {
            setError("Please select an address type");
            return;
        }

    };
    const { handleSubmit, control, formState, setValue } = useForm();
    useEffect(() => {
        setValue("name", selectedAddress?.name || userInfo?.fullname || "");
        setValue("address_id", selectedAddress?.address_id || "");
        setValue("mobile", selectedAddress?.mobile || userInfo?.mobile || "");
        setValue("email", selectedAddress?.email || userInfo?.email || "");
        setValue("address", selectedAddress?.address || "");
        setValue("landmark", selectedAddress?.landmark || "");
        setValue("pincode", selectedAddress?.pincode || pincode);
        setValue("city", selectedAddress?.city || pincode?.city);
        setValue("state", selectedAddress?.state || pincode?.state);
    }, [selectedAddress, setValue, userInfo]);

    // const handleAddressTypeChange = (value) => {
    //     setAddressType(value);
    //     setError("");
    // };
    return (
        <div>
            <div className="home-review-banner">
                <div className="app-header-wraper">
                   
                    <div className="header-top-section">
                        <div className="header-top-section-category-section">
                            <h3>Add Address</h3>
                        </div>
                        <div className="header-top-section-add-to-cart-section-hert">
                            <img src={appHeartIcon} width="100%" alt="heart-icon" />
                        </div>
                    </div>
                    <div className="location-section">
                        {/* <LocationSection /> */}
                    </div>
                </div>

                <div className="addres-form-wrapper">
                    <form onSubmit={handleSubmit(onSubmitForm)}>
                        <div className="addres-type">
                            <p>Address Type</p>
                            <div className="address-option">
                                <div className="main-address-option">
                                    <div className="option-box">
                                        <input
                                            type="radio"
                                            id="Home"
                                            value="Home"
                                            checked={selectedType === "Home"}
                                            onClick={handleType}
                                        />
                                        <label htmlFor="home">Home</label>
                                    </div>
                                    <div className="option-box">
                                        <input
                                            type="radio"
                                            id="Office"
                                            value="Office"
                                            checked={selectedType === "Office"}
                                            onClick={handleType}
                                        />
                                        <label htmlFor="office">Office</label>
                                    </div>
                                    <div className="option-box">
                                        <input
                                            type="radio"
                                            id="Other"
                                            value="Other"
                                            checked={selectedType === "Other"}
                                            onClick={handleType}
                                        />
                                        <label htmlFor="other">Other</label>
                                    </div>
                                </div>
                                {error && <p className="error-message">{error}</p>}
                            </div>

                            <div className="form-box-cover">
                                <Controller
                                    name="address_id"
                                    control={control}
                                    render={({ field }) => (
                                        <>
                                            <input {...field} type="hidden" placeholder="Enter Name" />
                                        </>
                                    )}
                                />
                                <Controller
                                    name="name"
                                    control={control}
                                    rules={{ required: "Please enter your name" }}
                                    render={({ field }) => (
                                        <>
                                            <input {...field} type="text" placeholder="Enter Name" />
                                            {formState.errors.name && (
                                                <p className="error-message">
                                                    {formState.errors.name.message}
                                                </p>
                                            )}
                                        </>
                                    )}
                                />

                                <Controller
                                    name="mobile"
                                    control={control}
                                    rules={{ required: "Please enter your number" }}
                                    render={({ field }) => (
                                        <>
                                            <input
                                                {...field}
                                                type="text"
                                                maxLength={10}
                                                placeholder="Enter Mobile Number"
                                                onKeyPress={handleKeyPress}
                                            // value={userDataInfo?.mobile}
                                            />
                                            {formState.errors.mobile && (
                                                <p className="error-message">
                                                    {formState.errors.mobile.message}
                                                </p>
                                            )}
                                        </>
                                    )}
                                />

                                <Controller
                                    name="email"
                                    control={control}
                                    rules={{ required: "Please enter your email" }}
                                    render={({ field }) => (
                                        <>
                                            <input
                                                {...field}
                                                type="email"
                                                placeholder="Enter Email Address"
                                            // value={userDataInfo?.email}
                                            />
                                            {formState.errors.email && (
                                                <p className="error-message">
                                                    {formState.errors.email.message}
                                                </p>
                                            )}
                                        </>
                                    )}
                                />

                                <Controller
                                    name="address"
                                    control={control}
                                    rules={{ required: "Please enter your address" }}
                                    render={({ field }) => (
                                        <>
                                            <input
                                                {...field}
                                                type="text"
                                                placeholder="Enter Address"
                                            />
                                            {formState.errors.address && (
                                                <p className="error-message">
                                                    {formState.errors.address.message}
                                                </p>
                                            )}
                                        </>
                                    )}
                                />

                                <Controller
                                    name="landmark"
                                    control={control}
                                    rules={{ required: "Please enter your Landmark" }}
                                    render={({ field }) => (
                                        <>
                                            <input
                                                {...field}
                                                type="text"
                                                placeholder="Enter Landmark"
                                            />
                                            {formState.errors.landmark && (
                                                <p className="error-message">
                                                    {formState.errors.landmark.message}
                                                </p>
                                            )}
                                        </>
                                    )}
                                />

                                <Controller
                                    name="pincode"
                                    control={control}
                                    // rules={{ required: "Please enter your pincode" }}
                                    render={({ field }) => (
                                        <>
                                            <input
                                                {...field}
                                                type="text"
                                                maxLength={6}
                                                value={pincode || selectedAddress?.pincode}
                                                placeholder="Enter Pincode"
                                                onChange={handlePincodeChange}
                                                onKeyPress={handleKeyPress}
                                                onPaste={(e) => {
                                                    e.preventDefault();
                                                }}
                                            />
                                            {formState.errors.pincode && (
                                                <p className="error-message">
                                                    {formState.errors.pincode.message}
                                                </p>
                                            )}
                                        </>
                                    )}
                                />

                                <Controller
                                    name="city"
                                    control={control}
                                    // rules={{ required: "Please enter your city" }}
                                    render={({ field }) => (
                                        <>
                                            <input
                                                {...field}
                                                type="text"
                                                placeholder="Enter City"
                                                value={pincodeData?.city || selectedAddress?.city}
                                            />
                                            {formState.errors.city && (
                                                <p className="error-message">
                                                    {formState.errors.city.message}
                                                </p>
                                            )}
                                        </>
                                    )}
                                />

                                <Controller
                                    name="state"
                                    control={control}
                                    // rules={{ required: "Please enter your state" }}
                                    render={({ field }) => (
                                        <>
                                            <input
                                                {...field}
                                                type="text"
                                                placeholder="Enter State"
                                                value={pincodeData?.state || selectedAddress?.state}
                                            />
                                            {formState.errors.state && (
                                                <p className="error-message">
                                                    {formState.errors.state.message}
                                                </p>
                                            )}
                                        </>
                                    )}
                                />

                                <div className="form-button">
                                    <button
                                        type="submit"
                                        onClick={handleSubmitRadio}
                                        disabled={loading}
                                    >
                                        {loading ? "Please Wait..." : "SAVE ADDRESS"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                {successmodal && (
                    <SuccessFull show={modalShow} successMessage={successMessage} animationtype={successmodal} />
                )}
            </div>
        </div>
    );
};

export default MedicineAddress;
