import React from "react";
import { appDiabetesIcon } from "../../../images";

const SymptomsCard = ({ data ,imgPath, handleBooking }) => {
  return (
    <div className="symptoms-card mb-3" onClick={() => handleBooking()}>
      <div className="symptoms-img-box">
        <img src={`${imgPath}${data.department_image}`} alt="spec-icon"></img>
      </div>
      <p>{data?.department_name}</p>
    </div>
  );
};

export default SymptomsCard;
