import React, { useState, useContext, useEffect } from "react";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import { ArrowBackButton, handleNavigateSearch } from "../../utils/AllFunction";
import { IoMdArrowDropdown, IoIosSearch } from "react-icons/io";
import { fetchCategoriesTestList } from "../../Redux/actions/GetApiCall";
import { useDispatch , useSelector } from "react-redux";
import AppHeader from "../Medicine/Components/Medicine_Header"
import TestCard from "./components/TestCard";
import { fetchDiagnosticCartDetails } from "../../Redux/actions/GetApiCall";
import Medicine_footer from "../Medicine/Components/Medicine_footer";

const TestbyCategories = () => {
  const categories_test_list_response = useSelector((state) => state?.categories_test_list_response)
  const key = "diagnostics"
  const cartDetailsResponse = useSelector(
    (state) => state.diagnostic_cart_details
  );
  const [testCategories, setTestCategories] = useState([]);
  
  const dispatch = useDispatch()
  const {category_id} = useParams();

  const navigate = useNavigate();

  useEffect(() =>{
    dispatch(fetchCategoriesTestList());
  },[])

  useEffect(() => {
    if (categories_test_list_response?.data?.data) {
      const categoryData = categories_test_list_response?.data?.data.find((cat_data) => cat_data.id == category_id );
      setTestCategories(categoryData)
    }
  }, [categories_test_list_response, category_id]);
  

  useEffect(() => {
    dispatch(fetchDiagnosticCartDetails());
  }, [dispatch]);

  return (
    <>
      <div className="home-review-banner">
      {/* className={`${testCategories.length >= 2 ? "add-mx-height" : ""} lab-book-categories`} */}
        <div  >
          <AppHeader Title={testCategories?.name} labCartCount={cartDetailsResponse?.data?.response?.cart_count}  />
          <div className="app-searh-section">
            <div className="inputWithIcon">
              <input type="text" placeholder="Search" onClick={() => handleNavigateSearch(navigate)} />
              <button>
                <IoIosSearch />
              </button>
            </div>
          </div>
          <div className="Test-categories-content-wrapper-box">
            <div className="container">
              <div className="row d-flex mb-4">
                {testCategories?.tests?.length > 0 && (
                  testCategories?.tests.map((test, elem) => (
                    <div className="col-6">
                      <TestCard test={test} callCategories={true} />
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
          {cartDetailsResponse &&
            (cartDetailsResponse?.data?.response?.tests?.length > 0 ||
              cartDetailsResponse?.data?.response?.packages?.length > 0) && (
              <Medicine_footer
                labCartData={cartDetailsResponse?.data.response}
                tabkey={key}
              />
            )}

        </div>

      </div>
    </>
  );
};

export default TestbyCategories;
