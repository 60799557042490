import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { HOME_DASHBOARD_SCREEN_API, REQUESTOPTIONS, HOME_INDEX_API } from "../../Constants";
import useQuery from "../../CustomHooks/useQuery"


const LoadPackage = (props) => {
  const navigate = useNavigate();

  const token = localStorage.getItem("ACCESS_TOKEN");
  const user_package_id = useQuery('user_package_id');
  const package_id = useQuery('package_id');

  const getPackageDetails = async () => {
    try {
      const packageDataRes = await fetch(
        `${process.env.REACT_APP_API_URL}/api/home_index/getPackageDetails?package_id=${package_id}&user_package_id=${user_package_id}`,
        {
          headers: {
            Authorization: `${props.token ?? token}`,
          },
        }
      );

      if (!packageDataRes.ok) {
        const errorData = await packageDataRes.json().catch(() => null);
        console.error("Error details:", errorData);
        throw new Error("Network response was not ok");
      }

      const res = await packageDataRes.json();
      let package_data = res?.data?.package_data
      if (res?.data?.package_data === null && package_data?.package_name === undefined) {
        window.history.go(-2);
      } else {
        navigate(`/packagedetailbox/${package_id}/${user_package_id}/${package_data?.package_name}/${package_data?.valid_from}/${package_data?.valid_to}/${package_data?.short_discription}`);
      }
      


    } catch (error) {
      console.error("Error fetching home data:", error);
    }
  };

  useEffect(() => {
    // console.log(user_package_id , package_id , packagesData)
    getPackageDetails();
  }, [])



  return (
    <>
    </>
  )
}

export default LoadPackage