const createApiReducer = (actionType) => {
    const initialState = {
        loader: false,
        data: null,
        error: null,
        status: 'idle',
        message: null,
        similar: true,
    };
    return (state = initialState, action) => {
        switch (action.type) {
            case `${actionType}_REQUEST`:
                return {
                    ...state,
                    loader: true,
                    status: 'loading',
                };
            case `${actionType}_SUCCESS`:
                return {
                    ...state,
                    loader: false,
                    data: action.payload,
                    similar: false,
                    message: action.payload.message,
                    status: 'success',
                };
            case `${actionType}_FAILURE`:
                return {
                    ...state,
                    loader: false,
                    error: action.payload,
                    status: 'failure',
                };
            case `${actionType}_IDLE`:
                return {
                    ...state,
                    status: 'idle',
                };
            default:
                return state;
        }
    };
};

export default createApiReducer;
