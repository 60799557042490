import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../Auth/auth.css";
import {
  appLeftBanner,
  appLoginRightBanner,
  appSignInIcon,
  appPhoneIcon,
} from "../../images";
import { DEFAULT_CAPTCHA_KEY } from "../../Constants";
import OTPInput, { ResendOTP } from "otp-input-react";

import ReCAPTCHA from "react-google-recaptcha";
import useRazorpay from "react-razorpay";
import { Toaster, toast } from "react-hot-toast";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { validateNumberInput } from "../../utils/AllFunction";
import PresciptionLoader from "../Medicine/animation/prescription_loader";
import {
  package_registration,
  verify_package_otp,
  capture_user_package,
} from "../../Redux/actions/PostApiCall";
import { HiArrowSmRight } from "react-icons/hi";

const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const recaptchaRef = useRef(null);
  const [toggleForm, setToggleForm] = useState(null);
  const [otp, setOtp] = useState("");
  const [showModal, setShowModal] = useState(false);
  const package_registration_response = useSelector(
    (state) => state?.package_registration_response
  );

  const [Razorpay] = useRazorpay();

  const package_verify_response = useSelector(
    (state) => state?.package_verify_response
  );

  const capture_user_package_response = useSelector(
    (state) => state?.capture_user_package_response
  );

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    reset,
    formState: { errors },
  } = useForm();

  const captchaToken = watch("g-recaptcha-response");
  const mobile_number = watch("mobile_number");
  const packageFormData = watch();

  const onSubmit = async (data) => {
    await dispatch(package_registration(data));
    const values = getValues();
    delete values["g-recaptcha-response"];
    setValue("g-recaptcha-response", undefined);
    recaptchaRef.current.reset();
  };

  useEffect(() => {
    const accessToken = localStorage.getItem("APP_TOKEN");
    if (accessToken) {
      navigate("/home");
    }
  }, []);

  useEffect(() => {
    if (package_registration_response.data?.success) {
      setToggleForm(true);
    } else if (package_registration_response?.status === "failure") {
      toast.error(package_registration_response?.error);
    }
  }, [package_registration_response]);

  const onCaptchaChange = (value) => {
    setValue("g-recaptcha-response", value);
  };

  const handleEditClick = () => {
    setToggleForm(null);
    setValue("g-recaptcha-response", null);
  };

  const handleResend = () => {
    dispatch(package_registration(packageFormData));
  };

  const handleVerifyOtpClick = () => {
    dispatch(verify_package_otp(packageFormData));
  };

  useEffect(() => {
    setValue("otp", otp);
  }, [otp]);

  useEffect(() => {
    if (capture_user_package_response?.status === "success") {
      toast.success(capture_user_package_response?.data?.message);
      setTimeout(() => {
        navigate("/");
      }, 1000);
    } else if (capture_user_package_response?.status === "failure") {
      toast.error(capture_user_package_response?.data?.message);
    }
  }, [capture_user_package_response]);

  useEffect(() => {
    if (package_verify_response?.status === "success") {
      const options = {
        ...package_verify_response.data.razorpay_details,
        handler: function (response) {
          const values = getValues();

          delete values["otp"];
          setValue("otp", undefined);

          dispatch(
            capture_user_package({
              ...values,
              source: parseInt(values.source, 10),
              transaction_id: response.razorpay_payment_id,
              order_id: response?.order_id,
            })
          );
          setShowModal(true);

          reset();
        },
      };

      const rzp1 = new Razorpay(options);
      rzp1.open();
    }
  }, [package_verify_response]);

  return (
    <div className="home-review-banner">
      <div className="login-form-left-banner">
        <img src={appLoginRightBanner} alt="" />
      </div>
      <div className="Login-form">
        <div className="login-form-section-repo">
          {toggleForm === null && (
            <div className="login-form-icon">
              <img src={appSignInIcon} alt="" />
              <p>Sign up with promo code </p>
            </div>
          )}

          <br />
          <form onSubmit={handleSubmit(onSubmit)}>
            {toggleForm === null && (
              <>
                <div className="auth-form-input-box">
                  <label htmlFor="package_code">Package Code:</label>
                  <input
                    id="package_code"
                    placeholder="Enter package code"
                    {...register("package_code", {
                      required: "Package code is required",
                    })}
                  />
                  {errors.package_code && (
                    <span className="auth-error-msg">
                      {errors.package_code.message}
                    </span>
                  )}
                </div>
                <div className="auth-form-input-box">
                  <label htmlFor="name">Name:</label>
                  <input
                    id="name"
                    placeholder="Enter name"
                    {...register("name", { required: "Name is required" })}
                  />
                  <input
                    id="name"
                    type="hidden"
                    value={0}
                    {...register("source")}
                  />
                  {errors.name && (
                    <span className="auth-error-msg">
                      {errors.name.message}
                    </span>
                  )}
                </div>
                <div className="auth-form-input-box">
                  <label htmlFor="mobile_number">Mobile Number:</label>
                  <input
                    id="mobile_number"
                    maxLength={10}
                    placeholder="Enter mobile no"
                    onInput={(e) => validateNumberInput(e)}
                    {...register("mobile_number", {
                      required: "Mobile number is required",
                      pattern: {
                        value: /^[0-9]{10}$/,
                        message: "Mobile number must be 10 digits",
                      },
                    })}
                  />
                  {errors.mobile_number && (
                    <span className="auth-error-msg">
                      {errors.mobile_number.message}
                    </span>
                  )}
                </div>
                <div className="auth-form-input-box">
                  <label htmlFor="email_id">Email ID:</label>
                  <input
                    id="email_id"
                    placeholder="Enter email"
                    {...register("email_id", {
                      required: "Email is required",
                      pattern: {
                        value:
                          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                        message: "Invalid email address",
                      },
                    })}
                  />
                  {errors.email_id && (
                    <span className="auth-error-msg">
                      {errors.email_id.message}
                    </span>
                  )}
                </div>
                <div className="recaptch-cover-wrapper mt-2 mb-2">
                  <ReCAPTCHA
                    sitekey={DEFAULT_CAPTCHA_KEY}
                    onChange={onCaptchaChange}
                    ref={recaptchaRef}
                  />
                  <input
                    type="hidden"
                    {...register("g-recaptcha-response", {
                      required: "reCAPTCHA is required",
                    })}
                  />
                  {errors["g-recaptcha-response"] && (
                    <span className="auth-error-msg">
                      {errors["g-recaptcha-response"].message}
                    </span>
                  )}
                </div>
                <div className="auth-form-input-box">
                  <input
                    id="source"
                    type="hidden"
                    value="0"
                    {...register("source", { required: "Source is required" })}
                  />
                </div>
                <button className="get-repo-btn" disabled={!captchaToken}>
                  {package_registration_response.loader
                    ? "Please Wait..."
                    : "Get OTP"}
                </button>

                <Link to={"/"} className="form-for-sign-up">
                  <button>
                    Login
                    <HiArrowSmRight />
                  </button>
                </Link>
              </>
            )}
            {toggleForm && (
              <div className="otp-form-section">
                <div className="login-form-icon">
                  <img src={appPhoneIcon} alt="" />
                  <p>OTP VERIFICATION</p>
                </div>
                <div className="otep-detail-text">
                  <p>
                    Please enter verification code (OTP) sent <br /> to +91{" "}
                    {mobile_number} (
                    <span>
                      <button
                        className="d-inline"
                        onClick={() => handleEditClick(mobile_number)}
                      >
                        Edit
                      </button>
                    </span>
                    )
                  </p>
                </div>
                <div className="otp-section-boxs">
                  <div className="Otp-box-from">
                    <OTPInput
                      value={otp}
                      onChange={setOtp}
                      autoFocus
                      OTPLength={6}
                      otpType="number"
                      disabled={false}
                      className="otp-input-repo"
                    />
                  </div>
                  <div className="resend-otp">
                    <ResendOTP
                      className="get-otp-data"
                      onResendClick={() => {
                        handleResend();
                      }}
                    />
                  </div>
                  <br />
                  <button
                    type="button"
                    className="verify-otp-repo-btn"
                    onClick={handleVerifyOtpClick}
                    // disabled={loadingData}
                  >
                    {package_verify_response?.loader
                      ? "Verifying...."
                      : "VERIFY"}
                  </button>
                </div>
              </div>
            )}
          </form>

          {showModal && <PresciptionLoader showModal={showModal} />}

          <br />
        </div>
      </div>
      <div className="login-form-right-banner">
        <img src={appLeftBanner} alt="" />
      </div>
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          className: "custom-toast",
          duration: 2000,
          style: {
            background: "#fff",
            color: "#363636",
            fontSize: "14px",
            width: "300px",
            top: "55px !important",
          },
        }}
      />
    </div>
  );
};

export default SignUp;
