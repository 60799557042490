import React from 'react'
// import { ArrowBack, appCart, appCartIcon, appHeartIcon, appNotification, appYogaFormImg, headerLogo } from '../../images'
import { ArrowBackButton } from '../../utils/AllFunction'
import { useParams } from 'react-router-dom'
import WellnessHeader from './WellnessHeader'
import FormWellnessPages from './FormWellnessPages'
import { appYogaFormImg } from '../../images'

const Yoga = () => {
    return (
        <div>
            <div className="home-review-banner">
                <WellnessHeader data="Yoga" />
                <FormWellnessPages data="Yoga" ImgData={appYogaFormImg} bgColor="#E7F3FE 0% 0% no-repeat padding-box" btnColor="#1F88FE 0% 0% no-repeat padding-box" borderColor="1px solid #1F88FE"/>
            </div>
        </div>
    )
}

export default Yoga