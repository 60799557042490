// SuccessFull.js

import React from 'react';
import Lottie from "lottie-react";
import NoDoctorSvg from "../AnimationJson/No_Doctor.json";


const No_Doctor_Found = () => {
    
    return (
        <Lottie animationData={NoDoctorSvg} className='no-doctor-found' />
    )
}

export default No_Doctor_Found;
