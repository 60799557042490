import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import LoaderAnime from "../../Medicine/animation/loader_anime";

const ConfirmModal = ({ handleClose, onSubmit, formShow, heading}) => {
    const handleYesClick1 = () => {
        onSubmit();
        setTimeout(() => {
            handleClose();
        }, 1000);
    };

    return (
        <Modal
            show={formShow}
            onHide={handleClose}
            centered
            backdrop="static"
            keyboard={false}
            className='confirm-data'
        >
            <>
                <Modal.Header closeButton className='head-repo-card'>
                    <Modal.Title className='head-cop-text'>Confirm</Modal.Title>
                </Modal.Header>
                <Modal.Body className='data-p-tag'>
                    <p>{heading ? heading : "Are you sure you want to proceed ?"}</p>
                </Modal.Body>
                <Modal.Footer className='foot-repo-card'>
                    <Button className='button-yes-repo' onClick={handleYesClick1}>Yes</Button>
                    <Button variant='secondary' onClick={handleClose}>
                        No
                    </Button>
                </Modal.Footer>
            </>
        </Modal>
    );
};

export default ConfirmModal;
