import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Need_Help_Image } from '../Images'
import '../Medicine_Style/cart.css'
import Cart_Card from '../Components/Cart_Card'
import EmptyCart from '../Components/EmptyCart'
import Need_Help from '../Components/Need_Help'

const Medicine_Cart = ({ setCartData }) => {
    const [cartListData, setCartListData] = useState([]);
    const dataCartMedi = useSelector(state => state.medicine_cart_add.data);
    console.log(dataCartMedi)
    const [cartRepo, setCartRepo] = useState([]);
    const { data: MedicineData, loader: cartLoader } = useSelector(state => state.medicine_cart_list);
    console.log(MedicineData)

    useEffect(() => {
        if (cartListData) {
            setCartData(cartListData)
        }
    }, [cartListData, setCartData])

    useEffect(() => {
        if (dataCartMedi !==null) {
            setCartRepo(dataCartMedi)
        }else{
            setCartRepo(MedicineData)
        }
    }, [dataCartMedi,MedicineData])

    console.log(dataCartMedi)


    return (
        <>

            <Cart_Card setCartListData={setCartListData} MedicineData={MedicineData} cartListData={cartListData} cartLoader={cartLoader} />


            {(cartListData?.total_item > 0 || cartListData?.total_items > 0) ? (
                <>
                    <div className="payment-repo-cover-box">
                        <div className="payment-text-repo">
                            <p>Payment Summary</p>
                        </div>

                        <div className="payment-summary-box-repo">
                            {cartRepo?.vas_charges?.details?.map((item, index) => (
                                <div className="price-payment-sum-repo" key={index}>
                                    <p>{item?.display_text}</p>
                                    <p>₹  {item?.amount?.toLocaleString('en-IN')}</p>
                                </div>
                            ))}
                        </div>
                        <div className="fin-text-repo">
                            <div className="price-payment-sum-repo total-pay-text">
                                <p>Total Payable</p>
                                <p>₹ {cartRepo?.payable_amount?.toLocaleString('en-IN')}</p>
                            </div>
                            <div className="price-payment-sum-repo total-save-text">
                                <p>Total Savings</p>
                                <p>₹ {cartRepo?.total_saving?.toLocaleString('en-IN')}</p>
                            </div>

                        </div>
                    </div>

                    <Need_Help />
                </>
            ) : (
                <EmptyCart />
            )}
        </>
    )
}

export default Medicine_Cart