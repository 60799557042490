import React, { useState, useEffect } from "react";
import { Tab, Row, Col, Nav } from "react-bootstrap";
import DiagnosticOrder from "./components/DiagnosticOrder";
import { LabTestIcon, appTabletMed } from "../../images";
import Medicine_Header from "../Medicine/Components/Medicine_Header";
import Medicine_footer from "../Medicine/Components/Medicine_footer";
import useLocationState from "../Medicine/hooks/useLocationState";
import { useSelector, useDispatch } from "react-redux";
import { fetchDiagnosticCartDetails } from "../../Redux/actions/GetApiCall";
import { orderDiagnostic } from "../../Redux/actions/PostApiCall";
// import LoaderAnime from "../Medicine/animation/prescription_loader";
import CartLoader from "../Medicine/animation/prescription_loader"
import Medicine_order from "../Medicine/Pages/Medicine_order";
import { orderMedicine } from "../../Redux/actions/PostApiCall";

const MainOrder = () => {
  const Title = "Placed Order";
  const dispatch = useDispatch();
  const mediCart = 1;
  const tabKey = useLocationState();
  const { data: MedicineOrderData } = useSelector(state => state.medicine_order_list);
  const [cartData, setCartData] = useState([]);
  const [key, setKey] = useState(tabKey);
  const cartDetailsResponse = useSelector(
    (state) => state.diagnostic_cart_details
  );
  const [showModal, setShowModal] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState('');
  const [walletId, setWalletId] = useState("");

  const placeOrderDetailsData = useSelector(
    (state) => state.diagnostic_place_order_details_response
  );
  


  // Medicine Order Function
  const handleOrderMedicine = async () => {
    setShowModal(true)
    const orderData = {
      medicine_order_id: MedicineOrderData?.data?.medicine_order_id,
      package_id: selectedPackage,
      wallet_id: walletId,
      source: 0
    }
    await dispatch(orderMedicine(orderData))
    
  }

  // -----------------------DIAGNOSTICS-----------------------------
  const [selectedDiagnosticPackageData, setSelectedDiagnosticPackageData] = useState("");

  const [placeOrderDiagnosticApiData, setPlaceOrderDiagnosticApiData] = useState({
    package_id: null,
    wallet_id: null,
    source: 0
  });

  useEffect(() => {
    if (cartDetailsResponse?.status === "loading") {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [cartDetailsResponse]);

  useEffect(() => {
    dispatch(fetchDiagnosticCartDetails());
  }, [dispatch]);

  const handleDiagnosticOrder = async () => {
    await dispatch(orderDiagnostic(placeOrderDiagnosticApiData));
  }


  return (
    <div className="home-review-banner">
      <Medicine_Header Title={Title} cartData={cartData} labCartCount={cartDetailsResponse?.data?.response?.cart_count} />
      <div className="app-main-cart-cover-wrapper">
        <div className="tab-main-cover-wrapper">
          <Tab.Container id="left-tabs-example" defaultActiveKey={key}>
            <Row>
              <Col lg={12} className=" tab-cover tab-cover2">
                <div className="cart-header-tabs d-flex justify-content-center">
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="medicines"
                        onClick={() => setKey("medicines")}
                        className={
                          key === "medicines" ? "active-link" : "not-active"
                        }
                      >
                        <div className="medicine-box lab-box">
                          <div className="medi-image">
                            <img
                              src={appTabletMed}
                              width="100%"
                              alt="Tablet-capsule"
                            />
                          </div>
                          <div className="box-heading">
                            <p>Medicines</p>
                          </div>
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="diagnostics"
                        onClick={() => setKey("diagnostics")}
                        className={
                          key === "diagnostics" ? "active-link" : "not-active"
                        }
                      >
                        <div className="lab-box">
                          <div className="medi-image">
                            <img
                              src={LabTestIcon}
                              width="100%"
                              alt="Lab-Test"
                            />
                          </div>
                          <div className="box-heading">
                            <p>Lab Tests</p>
                          </div>
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
              </Col>
              <Col lg={12}>
                <div className="tab-content-main-cover">
                  <Tab.Content>
                    <Tab.Pane eventKey="medicines">
                      <Medicine_order tabkey={key} setCartData={setCartData} walletId={walletId} selectedPackage={selectedPackage} setWalletId={setWalletId} setSelectedPackage={setSelectedPackage} showModal={showModal} setShowModal={setShowModal} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="diagnostics">
                      {cartDetailsResponse?.status === "loading" && (
                        <CartLoader showModal={showModal} />
                      )}
                      {cartDetailsResponse && cartDetailsResponse?.data && (
                        <DiagnosticOrder
                          showModal={showModal}
                          setShowModal={setShowModal}
                          selectedDiagnosticPackageData={selectedDiagnosticPackageData}
                          setSelectedDiagnosticPackageData={setSelectedDiagnosticPackageData}
                          setPlaceOrderDiagnosticApiData={setPlaceOrderDiagnosticApiData}
                          placeOrderDiagnosticApiData={placeOrderDiagnosticApiData}
                          labCartData={
                            cartDetailsResponse &&
                            cartDetailsResponse?.data?.response
                          }
                        />
                      )}
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </div>
      {((key === "medicines" && cartData && (cartData?.total_item > 0 || cartData?.total_items > 0)) ||
        (key === "diagnostics" &&
          placeOrderDetailsData?.data)) && (
          <Medicine_footer
            cartData={cartData}
            labCartData={placeOrderDetailsData?.data?.response}
            tabkey={key}
            mediCart={mediCart}
            handleDiagnosticOrder={handleDiagnosticOrder}
            handleOrderMedicine={handleOrderMedicine}
          />
        )}
    </div>
  );
};

export default MainOrder;
