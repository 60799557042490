import React from 'react'
import '../Styles/waitloader.css'
const WaitLoader = () => {
    return (
        <div className='spin-data'>
            <div className="dot-spinner">
                <div className="dot-spinner__dot" />
                <div className="dot-spinner__dot" />
                <div className="dot-spinner__dot" />
                <div className="dot-spinner__dot" />
                <div className="dot-spinner__dot" />
                <div className="dot-spinner__dot" />
                <div className="dot-spinner__dot" />
                <div className="dot-spinner__dot" />
            </div>
            <p>Please Wait...</p>
        </div>
    )
}

export default WaitLoader;