import React from 'react'
import { NavLink } from 'react-router-dom'
import { ArrowBackButton } from '../../utils/AllFunction'
import { ArrowBack, appCart, appNotification, headerLogo } from '../../images'

const WellnessHeader = ({data}) => {
    return (
        <>
           
                <div className="home-app-header">
                    <div className="header-logo">
                        <img src={headerLogo} width="100%" alt="header-logo" />
                        <div className="user-name">
                            <p>{data}</p>
                        </div>
                    </div>
                    <div className="icons-box">
                        <div className="notification-icon">
                            <a href="#">
                                <img src={appNotification} width="100%" alt="notification" />
                            </a>
                        </div>
                        <div className="cart-icon">
                            <NavLink to='/emptycart'>
                                <img src={appCart} width="100%" alt="review" />
                            </NavLink>
                        </div>
                    </div>
                </div>
        </>
    )
}

export default WellnessHeader