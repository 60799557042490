import React from 'react'
import { NavLink } from 'react-bootstrap'
import { ArrowBackButton } from '../../utils/AllFunction'
import { ArrowBack, headerLogo } from '../../images';
import Form from 'react-bootstrap/Form';

const SuperTopUp = (aaa) => {
  return (
    <>
          <div className="home-review-banner">
                <div className="home-app-header nutrition-repo-box">
                    
                    <div className="header-logo">
                        <img src={headerLogo} width="100%" alt="header-logo" />
                        <div className="user-name">
                            <p>Super Top Up Insurance</p>
                        </div>
                    </div>
                </div>

                <div className="ambulance-cover-section">
                    <div className="super-watch-header-image">
                        <img src="<?php echo RESPONSIVE_IMAGES_URL; ?>app-nutrition-image-pack.png" width="100%" alt="review" />
                        <p>Enter Patient Details</p>
                    </div>
                    <div className="super-top-heading">
                        <p>Teleconsultation Will be Conduct for this person</p>
                    </div>
                    <div className="ambulance-form-box">
                        <label>Enter Name</label>
                        <input type="text" placeholder="Enter Name" />
                        <label>Select Date</label>
                        <input type="date" placeholder="Enter Date" />
                        <label>Enter Age</label>
                        <input type="text" placeholder="Enter Age" />
                        <label>Select Gender</label>
                        <div className="gender-select-box">
                            <Form.Check
                                type="radio"
                                label="Male"
                                name="gender"
                                id="male-radio"
                            />
                            <Form.Check
                                type="radio"
                                label="Female"
                                name="gender"
                                id="female-radio"
                            />
                            <Form.Check
                                type="radio"
                                label="Other"
                                name="gender"
                                id="other-radio"
                            />
                        </div>
                        <input type="text" placeholder="Enter Mobile Number" />
                        <input type="text" placeholder="Enter Email Address" />
                        <button id="submitButton">Submit</button>
                    </div>
                </div>

            </div>
    </>
  )
}

export default SuperTopUp