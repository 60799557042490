import React from "react";
import { NavLink } from "react-router-dom";
import CartLoader from "../../Medicine/animation/CartLoader";
import {
  appIncludeTestIcon,
  appPackageIcon,
  appHomeCollectionIcon,
} from "../../../images";
import useHandleCart from "../hooks/useHandleCart";

const HealthPackageCard = ({ data }) => {

  const { handleCartAdd ,myApiLoader ,  handleRemoveCart ,loadingItems , cartResponseStatus , cartDetailsResponseStatus , packageListResponseStatus } = useHandleCart("package");


  return (
    <>
      <div className="health-pack-main-box">
        <NavLink to={`/package-description/${data.id}`}>
          <div className="health-pack-image-box">
            <img src={appPackageIcon} width="100%" alt="package" />
            <p>{data.name}</p>
          </div>
          <hr />
          <div className="health-pack-price">
            <h6>MB Price ₹ {data.medibhai_price}</h6>
            <p>₹ {data.mrp}</p>
            <span>{data.discount_rate}% OFF</span>
          </div>
          <div className="health-pack-content-box">
            <img src={appIncludeTestIcon} width="100%" alt="include-test" />
            <p>{data.test_count} Included Tests</p>
          </div>
          <div className="health-pack-content-box">
            <img src={appHomeCollectionIcon} width="100%" alt="home" />
            <p className="sample-repo">Home Sample Collection</p>
          </div>
        </NavLink>
        {loadingItems.has(data?.id) &&  myApiLoader ? (
          <CartLoader />
        ) : data?.in_cart == 1 ? (
          <button
            class="remove-cart-btn"
            onClick={() => handleRemoveCart({...data , type : "package"})}
          >
            Remove
          </button>
        ) : (
          <div className="common-add-cart">
            <button onClick={() => handleCartAdd({ ...data, type: "package" })}>
              <p>ADD TO CART</p>
            </button>
          </div>
        )}

      </div>
    </>
  );
};

export default HealthPackageCard;
