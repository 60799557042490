import React from 'react';
import { Cart_Icon, Header_Background } from '../Images';
import LocationSection from '../../LocationSection/LocationSection';
import '../Medicine_Style/header.css';
import Medicine_Location from './Medicine_Location';
import { useNavigate } from 'react-router-dom';
import { FaChevronLeft } from "react-icons/fa";

const Medicine_Header = ({ Title, state, cartData, labCartCount }) => {
    const navigate = useNavigate();
    const WebLink = window.location.pathname;

    const cartTotal = cartData?.total_items || cartData?.total_item;
    const handleBack = () => {
        if (WebLink === "/medicines-address") {
            window.history.back();  // Correct way to navigate back
        } else {
            navigate(`/${state.Link}`);  // Navigate to a specific link
        }
    };

    // console.log(Title)

    const handleCartLink = () => {
        const tabkeyUrl = "medicines"
        const tabkeyData = {
            cartdata: cartData,
            tabkey: tabkeyUrl
        }
        navigate('/cart', { state: tabkeyData });
    };

    return (
        <div className="medicine-header sticky-lg-top sticky-md-top sticky-top">
            <div className="images-box" style={{ backgroundImage: `url(${Header_Background})` }}>
                <div className="header-box-repo-data">
                    {Title ? (
                        <div className="data-box-repo-store">
                            {(state?.Title === 'Search Medicines' || WebLink === "/medicines-address") && (
                                <button onClick={handleBack}><FaChevronLeft /></button>
                            )}
                            <div className="header-title">
                                <h6>{Title}</h6>
                            </div>
                        </div>
                    ) : (
                        <Medicine_Location />
                    )}

                    {!(state?.Title === 'Search Medicines' || (WebLink === "/medicines-address" || WebLink === "/address-list") || Title === "Call a doctor" || WebLink === "/patients") && (
                        <div className="cart-icon-repo" onClick={handleCartLink}>
                            <img src={Cart_Icon} alt="cart-icon" />
                            {(labCartCount || cartTotal) && (
                                <p className="count-cart-text">
                                    {cartTotal && labCartCount ? cartTotal + +labCartCount : cartTotal || (labCartCount || 0)}
                                </p>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Medicine_Header;
