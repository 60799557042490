import React from "react";
import { NavLink, useParams } from "react-router-dom";
import { ArrowBackButton } from "../../utils/AllFunction";
import { ArrowBack, appCartIcon, appHeartIcon } from "../../images";
import RadiologySection from "../LocationSection/RadiologySection";
import { RadioData } from "../../Constants/RadioHeaderData";

const HeaderRadio = () => {
  const { id } = useParams();
  const RadioArray = RadioData();
  const categoryName = id && RadioArray[id] ? RadioArray[id].name : "Radiology";
  return (
    <>
      <div className="app-header-wraper">
       
        <div className="header-top-section">
          <div className="header-top-section-category-section">
            <h3>
             {categoryName}
            </h3>
            <RadiologySection />
          </div>
          <div className="header-top-section-add-to-cart-section">
            <NavLink to="/cart">
              <img src={appCartIcon} width="100%" alt="Cart-icon" />
              {/* <span className="Header-count-number">
                  {medicineCartData ? medicineCartData.length : 0}
                </span> */}
            </NavLink>
          </div>
          <div className="header-top-section-add-to-cart-section-hert">
            <img src={appHeartIcon} width="100%" alt="heart-icon" />
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderRadio;
