import React from 'react'
import { ServerErrorImg } from '../images';


const ErrorServer = ({Message}) => {
  return (
    <div className='error-main-wrapper'>
        <div className='server-error-img-box'>
            <img src={ServerErrorImg} alt='no-data'></img>
        </div>
        <h6 class="text-center">{Message}</h6>
    </div>
  )
}

export default ErrorServer