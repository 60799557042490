import React, { useState, useEffect } from "react";
import { Toaster, toast } from "react-hot-toast";
import AppHeader from "../Medicine/Components/Medicine_Header";
import {
  fetchTimeSlot,
  fetchDiagnosticCartDetails,
} from "../../Redux/actions/GetApiCall";
import { save_time_slot } from "../../Redux/actions/PostApiCall";
import { useDispatch, useSelector } from "react-redux";
import { timeHome } from "../../images";
import Loader from "../../Animation/Loader";
import { useNavigate } from "react-router";
import { formatYY_MM_DD } from "../../utils/AllFunction";
import AppLoader from "../Medicine/animation/prescription_loader"

const DateSlotTime = () => {
  const dispatch = useDispatch();
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [currentMonth, setCurrentMonth] = useState("");
  const timeSlotResponse = useSelector((state) => state.get_time_slots);
  const cartDetailsResponse = useSelector(
    (state) => state.diagnostic_cart_details
  );
  const save_time_slot_response = useSelector(
    (state) => state.save_time_slot_response
  );
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchTimeSlot());
    dispatch(fetchDiagnosticCartDetails());
  }, [dispatch]);

  useEffect(() => {
    const today = new Date();
    const month = today.toLocaleDateString("en-US", {
      month: "long",
      year: "numeric",
    });
    setCurrentMonth(month);
    const currentDate = new Date(timeSlotResponse?.data?.response.date[0]);
    setSelectedDate(formatYY_MM_DD(currentDate));
  }, [timeSlotResponse]);

  const handleTimeSlot = (currentDate, index) => {
    const newDate = formatYY_MM_DD(currentDate)
    setSelectedDate(newDate)
    setActiveIndex(index);
  };

  const handleTimeSlotChange = (event) => {
    setSelectedTimeSlot(event.target.value);
  };

  const handleNavigate = () => {

    const slotData = {
      date : selectedDate ,
      time : selectedTimeSlot
    }

    if (selectedTimeSlot) {
      dispatch(save_time_slot(slotData));
    } else {
      toast.error("please select time slot ");
    }
  };

  useEffect(() =>{
    if (save_time_slot_response?.data?.success) {
      navigate("/place-order", { state: "diagnostics" });
    }
  },[save_time_slot_response])

  return (
    <>
      <div className="home-review-banner">
        <AppHeader
          Title="Select Date and Time"
          labCartCount={cartDetailsResponse?.data?.response?.cart_count}
        />
        <AppLoader showModal={save_time_slot_response?.loader}/>
        <div className="date-time-slot-main-cover-wrapper">
          <div className="patient-main-box">
            <img src={timeHome} width="100%" alt="review" />
            <p>Select date for Home sample collection</p>
          </div>
          <div className="para-content-p">
            <p>Some test/packages may require fasting*</p>
          </div>
          <div className="date-calender-box">
            <div className="date-heading-box">
              <p>Select date : {currentMonth}</p>
            </div>
            {timeSlotResponse?.loader ? (
              <Loader />
            ) : (
              <>
                <div className="date-slot-main-wrapper">
                  {timeSlotResponse?.data?.response?.date?.length > 0 &&
                    timeSlotResponse?.data?.response.date?.map(
                      (time_data, index) => {
                        const currentDate = new Date(time_data);
                        const day = currentDate.toLocaleDateString("en-US", {
                          weekday: "short",
                        });
                        const date = currentDate.getDate();
                        const month = currentDate.toLocaleDateString("en-US", {
                          month: "short",
                        });

                        return (
                          <div className="date-box" key={time_data}>
                            <div className="per-date-box">
                              <button
                                id="date"
                                className={`element ${
                                  index === activeIndex ? "activeDatebox" : ""
                                }`}
                                onClick={() =>
                                  handleTimeSlot(currentDate, index)
                                }
                              >
                                <p>{day}</p>
                                <hr className="element1" />
                                <span>{date}</span>
                                <br />
                                <span>{month}</span>
                              </button>
                            </div>
                          </div>
                        );
                      }
                    )}
                </div>
                <div className="time-section">
                  <div className="time-heading">
                    <p>Select Time</p>

                    <div className="patient-input-form">
                      <select
                        name="time"
                        id="time"
                        className="relation"
                        onChange={handleTimeSlotChange}
                      >
                        <option value="">Select Time</option>
                        {timeSlotResponse?.data?.response?.time_slot.length >
                          0 &&
                          timeSlotResponse?.data?.response?.time_slot.map(
                            (timeSlot, index) => (
                              <option key={index} value={timeSlot}>
                                {timeSlot}
                              </option>
                            )
                          )}
                      </select>
                      {timeSlotResponse &&
                        timeSlotResponse?.data?.response?.time_slot?.length ===
                          0 && (
                          <p
                            style={{
                              color: "red",
                              fontSize: "13px",
                              marginLeft: "3px",
                            }}
                          >
                            No time slots available
                          </p>
                        )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {cartDetailsResponse?.data && (
          <div className="go-to-cart-main-page-wrapper">
            <div className="cart-count-box-cover">
              <div className="cart-count-text">
                <p>
                  <span>{cartDetailsResponse?.data?.response?.cart_count}</span>{" "}
                  item(s) Added To Your Cart
                </p>
              </div>
              <div className="go-cart-btn">
                <button
                  className="patient-btn"
                  onClick={() => handleNavigate()}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: "",
          duration: 3000,
          style: {
            background: "#fff",
            color: "#363636",
          },
        }}
      />
    </>
  );
};

export default DateSlotTime;
