import React from 'react'
import { appDiabetesIcon } from "../../../images";

const SpecialityProduct = ({ data, imgPath, handleBooking }) => {

  
  return (
    <>
      <div className='speciality-product justify-content-between align-items-center mb-2' onClick={() => handleBooking()}>
        <div className='speciality-product-details'>
          <h6>{data?.department_name}</h6>
          <p className='speciality-product-price mb-0'>{data?.is_free == 1 && (<span className='cut-price'>₹{data?.cosult_charge}</span>)} ₹ {data?.is_free == 0 ? data?.cosult_charge : 0}</p>
        </div>
        <div className='speciality-prod-img-box'>
          <img src={`${imgPath}${data.department_image}`} alt='spec-icon'></img>
        </div>
      </div>
    </>
  )
}

export default SpecialityProduct