import React from "react";
import { appFasting, appTestTube, appFastingTime } from "../../../images";

const WhyBookwithUs = () => {
  return (
    <>
      <div className="pack-why-book">
        <div className="pack-why-book-heading">
          <hr />
          <h6>Why book with us ?</h6>
          <hr />
        </div>
        <div className="pack-why-book-cover">
          <div className="pack-why-book-desc">
            <div className="pack-why-book-image">
              <img src={appFasting} width="100%" alt="review" />
            </div>
            <div className="pack-why-book-content">
              <p>Safe sample collection from comfort of your home</p>
            </div>
          </div>
          <div className="pack-why-book-desc">
            <div className="pack-why-book-image">
              <img src={appTestTube} width="100%" alt="review" />
            </div>
            <div className="pack-why-book-content">
              <p>Wide range of tests available</p>
            </div>
          </div>
          <div className="pack-why-book-desc">
            <div className="pack-why-book-image">
              <img src={appFastingTime} width="100%" alt="review" />
            </div>
            <div className="pack-why-book-content">
              <p>Doctor advise with Health tests</p>
            </div>
          </div>
        </div>
        <hr />
      </div>
    </>
  );
};

export default WhyBookwithUs;
