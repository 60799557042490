import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { NavLink } from "react-router-dom";

const TestbyCategorieSection = ({categoryListData}) => {
  return (
    <div className="test-by-category-main-cover-wrapper">
      <div className="lab-book-test-box">
        <Swiper
          spaceBetween={10}
          slidesPerView={3}
          loop="true"
          modules={[Autoplay]}
          pagination={{ clickable: true }}
          // autoplay={{ delay: 2500, disableoninteraction: false, }}
        >
          <div className="swiper-wrapper">
            {categoryListData?.length > 0 && (
              categoryListData?.map((List) => {
                return (
                  <SwiperSlide key={List?.id}>
                    <NavLink
                      to={`/test-by-categories/${List?.id}`}
                      className="categories-test-link"
                    >
                      <div className="lab-img-box">
                        <img
                          src={`${List.cart_icon}`}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src =
                              "https://medibhai.co.in/assets/uploads/cart_icon/lifestyle-disease-related-test.svg";
                          }}
                        />
                        <p>{List?.name}</p>
                      </div>
                    </NavLink>
                  </SwiperSlide>
                );
              })
            )}
          </div>
        </Swiper>
      </div>
    </div>
  );
};

export default TestbyCategorieSection;
