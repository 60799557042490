import React, { useCallback, useEffect, useState } from 'react'
import Medicine_Header from '../Components/Medicine_Header'
import useLocationState from '../hooks/useLocationState'
import { no_preview, pdf_icon } from '../Images';
import '../Medicine_Style/search_result.css'
import { FaAngleRight } from "react-icons/fa6";
import { useDispatch, useSelector } from 'react-redux';
import { fetchMedicinelist } from '../../../Redux/actions/GetApiCall';
import { debounce } from 'lodash';
import MediLoader from '../animation/MediLoader';
import { useNavigate } from 'react-router-dom';

const Medicine_Search_Result = () => {
    const state = useLocationState();
    const dispatch = useDispatch();
    const [inputValue, setInputValue] = useState('');
    const [searchResult, setSearchResult] = useState([]);
    const { data: SearchData, error: SearchError, loader: SearchLoader } = useSelector(state => state?.medicine_list);
    const navigate = useNavigate();

    const debounceFetchMedicine = useCallback(
        debounce((value) => {
            if (value.trim() !== '') {
                dispatch(fetchMedicinelist(value));
            } else {
                setSearchResult([]);
            }
        }, 1000), // 1s debounce delay
        [dispatch]
    );

    useEffect(() => {
        debounceFetchMedicine(inputValue);
        return () => {
            debounceFetchMedicine.cancel();
        };
    }, [inputValue, debounceFetchMedicine]);

    useEffect(() => {
        setSearchResult(SearchData)
    }, [SearchData])

    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputValue(value);
        if (value.trim() === '') {
            setSearchResult([]);
        }
    };

    const height = "75%"

    const getHighlightedText = (text, highlight) => {
        const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
        return parts.map((part, index) =>
            part.toLowerCase() === highlight.toLowerCase() ? <strong key={index} style={{ color: 'black' }}>{part}</strong> : part
        );
    };

    const handleSearch = (name) => {
        setInputValue('')
        console.log(name)
        const data = [{
            Title: "Medicine List",
            inputData: name
        }]

        navigate('/health-corner', { state: data })
    }

    return (
        <div className="home-review-banner">
            <Medicine_Header Title={state?.Title} state={state} />
            <div className='search-data-input-box'>
                <input type="text" placeholder='Search your medicine...' onChange={(e) => handleInputChange(e)} />
            </div>

            {
                SearchLoader ? (
                    <MediLoader height={height} />
                ) : (
                    <div className="data-repo-cover-medi-search">
                        {searchResult && searchResult?.data?.map((item) => (
                            <div className="search-result-data-box-cover" key={item?.sku_id} onClick={() => handleSearch(item?.name)}>
                                <div className="search-data-box-cont">
                                    <img src={item?.banner_url ? item?.banner_url : no_preview}
                                        onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = no_preview;
                                        }}
                                        alt="product-icon" />
                                    <p className='ellipsis-text'>{getHighlightedText(item?.name, inputValue)}</p>
                                </div>
                                <div className="search-left-arrow-box">
                                    <FaAngleRight />
                                </div>
                            </div>
                        ))}
                    </div>
                )
            }
        </div>
    )
}

export default Medicine_Search_Result