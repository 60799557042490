import React, { useEffect, useState } from 'react'
import { no_preview, px_Image } from '../../Medicine/Images';

const Shipment_Card = ({ MedicineData}) => {
    return (
        <>
            <div className="cart-wrapper-repo-cover-box ship-cart-data">
                {MedicineData?.map((item, index) => {
                    return (
                        <div key={item?.sku_id}>
                            <div className="product-wrapper-box-conver ship-order-data">
                                <div className="image-product-cov">
                                    {item?.prescription_required === 1 && (
                                        <div className="product-px-cover-repo">
                                            <img src={px_Image} alt="px-image" />
                                        </div>
                                    )}
                                    <img src={item?.banner_url ? item?.banner_url : no_preview}
                                        onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = no_preview;
                                        }}
                                        alt="product-icon" />
                                </div>
                                <div className="product-data-desc-box">
                                    <div className="product-repo-data-cont">
                                        <div className="product-name shipment-name">
                                            <p className='ellipsis-text-cart ship-ellipse'>{item?.name}</p>
                                        </div>
                                        <div className="repo-prod-data-cvoer">
                                            <div className="price-qty-cove">
                                                <div className="product-price-text shipment-price-text">
                                                    <p>
                                                        {(item?.discounted_price !== null && item?.discounted_price !== 0)
                                                            ? (String(item.discounted_price).startsWith('₹') ? item.discounted_price : `₹${item.discounted_price}`)
                                                            : (item?.price !== 0 && item?.price !== null)
                                                                ? (String(item.price).startsWith('₹') ? item.price : `₹${item.price}`)
                                                                : "No price available"}
                                                    </p>

                                                    <span>
                                                        {(item?.discounted_price !== null && item?.discounted_price !== 0) &&
                                                            (String(item.price).startsWith('₹') ? item.price : `₹${item.price}`)}
                                                    </span>
                                                </div>
                                                <div className="product-qty">
                                                    <p>Min. Qty : {item?.min_quantity}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {index < MedicineData?.available_stock?.length - 1 && <hr className='product-hr' />}
                        </div>
                    )
                })}
            </div>
        </>
    )
}

export default Shipment_Card