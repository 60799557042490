import React, { useEffect, useState } from 'react'
import { headerLogo } from '../images'
import { Col, Nav, Row, Tab } from 'react-bootstrap'
import { EXPLORE_API } from '../Constants';

const Explore = () => {
    const token = localStorage.getItem("ACCESS_TOKEN");
    const [exploreData, setExploreData] = useState([]);
    const [tcData, setTcData] = useState([]);

    const fetchExplore = async () => {
        try {
            const exploreData = await fetch(`${EXPLORE_API}`, {
                headers: {
                    Authorization: `${token}`,
                },
                method: "GET",
            })

            const exploreResponse = await exploreData.json()
            setExploreData(exploreResponse.faq)
            setTcData(exploreResponse.tnc)
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        fetchExplore();
    }, [])

    return (
        <div className="home-review-banner">
            <div className="home-app-header">

                <div className="header-logo">
                    <img src={headerLogo} width="100%" alt="header-logo" />
                    <div className="user-name explore">
                        <p>Explore</p>
                    </div>
                </div>
            </div>

            <div className="explore-repo-content-data-box">
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Row>
                        <Col sm={12}>
                            <Nav variant="pills" className='tabs-data-col-repo'>
                                <Nav.Item className='navs-repo-tabs-con'>
                                    <Nav.Link eventKey="first">FAQ</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className='navs-repo-tabs-con'>
                                    <Nav.Link eventKey="second">T&C</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                        <Col sm={12}>
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <div className="repo-content-data-box-faq">
                                        {exploreData?.map((item, index) => (
                                            <div className="faq-data-box-repo" key={index}>
                                                <div className="question-box">
                                                    <h6>Q.{item?.question}</h6>
                                                    <p>A. {item?.answer}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <div className="term-content-data-box">
                                        <div dangerouslySetInnerHTML={{ __html: tcData }} />
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </div>
        </div>
    )
}

export default Explore